<template>
    <div>
        <div class="c-image-slider mb-4">
                <transition-group name="gallery-fade">
                    <div v-if="transition_fade" v-for="(image, index) in visible_slides" :key="'first'+index" @click="index == 0 ? active_image_index-- : (index !== 1 ? active_image_index++ : null)" class="c-image-slider__slide shadow-md" :style="{ backgroundImage: 'url('+image+')' }"></div>

                    <div v-for="(image, index) in previous_visible_slides" :key="'second'+index" class="c-image-slider__slide  hidden md:block" :style="{ backgroundImage: 'url('+image+')' }"></div>
                </transition-group>

        </div>
        <ul class="c-dot-pagination">
            <li v-for="(image, index) in images" :key="index" @click="active_image_index = correctArrayPointer(0, index, images)" :class="{ 'active' : index == correctArrayPointer(0, active_image_index, images) }" class="c-dot-pagination__item" role="button"></li>
        </ul>
    </div>
</template>

<script>
export default {

    data() {
        return {
            active_image_index: 0,
            transition_fade: true,
            previous_visible_slides: null
        }
    },

    props: {
        images: {
            type: Array,
            required: true
        },
    },

    mounted() {
    },

    watch: {
        active_image_index() {
            if (this.active_image_index < 0) {
                this.active_image_index = this.images.length-1;
            }

            this.transition_fade = false;
            setTimeout(() => {
                this.transition_fade = true
            }, 30);
        },

        visible_slides(new_val, old_val) {
            this.previous_visible_slides = this.previous_visible_slides ? old_val : new_val;
        }
    },

    computed: {
        visible_slides() {
            return [
                this.images[this.correctArrayPointer(0,this.active_image_index, this.images)],
                this.images[this.correctArrayPointer(1,this.active_image_index, this.images)],
                this.images[this.correctArrayPointer(2,this.active_image_index, this.images)]
            ];
        },
    },

    methods: {

        correctArrayPointer(slot, index, target) {
            return (slot + index) % target.length;
        },

    }
}
</script>

<style lang="scss" scoped>
.c-image-slider {
    @apply relative mb-4;
    padding-top: 60%;

    @screen md {
        @apply mb-6;
        padding-top: 35%;
    }

    &__slide {
        @apply absolute left-0 w-full bg-cover bg-no-repeat bg-center;
        top: 50%;
        transform: translateY(-50%);
        height: calc(100% - 4rem);

        @screen md {
            @apply w-6/12;
        }

        &:nth-of-type(2), &:nth-of-type(5) {
            @apply h-full;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
        }

        &:nth-of-type(3), &:nth-of-type(6) {
            @apply right-0;
            left: inherit;
        }

        &:first-of-type {
            z-index: 7;
        }

        &:nth-of-type(2) {
            z-index: 10;
        }

        &:nth-of-type(3) {
            z-index: 9;
        }
    }
}

.c-dot-pagination {
    @apply flex flex-wrap justify-center mb-0 mx-2;

    &__item {
        @apply bg-grey-350 rounded-full mx-1 cursor-pointer;
        height: 15px;
        width: 15px;

        &.active {
            @apply bg-copper-400;
        }
    }
}

</style>
