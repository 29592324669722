<template>
    <div>
        <!-- Title & view toggle -->
        <div class="flex justify-between py-2 px-8 w-screen bg-white border-b border-grey-1000 z-10 sticky top-0">
        <!-- Filters  -->
        <div class="flex-grow flex-shrink">
            <div>
                <a @click="show_filters=true" role="button" class="text-grey-1000 uppercase font-semibold text-base">
                    <i class="far fa-sliders-h mr-1"></i>
                    <span>Filter</span>
                </a>
            </div>
        </div>
        <!-- / Filters -->

        <!-- Hidden if searching for at-home appointments -->
        <div v-if="!search_filter.mobile" class="md:flex flex-shrink-0 md:justify-end">
            <list-map-toggle v-model="view_type"></list-map-toggle>
        </div>


        </div>

        <component :is="search_filter.mobile ? components['list'] : components[view_type]"
            :results="results"
            :show-skeleton="show_skeleton"
            :isLoggedIn="is_logged_in"
            :search_filter="search_filter"
            v-on:toggle-favourite="toggleFavourite"
            :results_message="results_message"
            :nearby="nearby_businesses" 
        />
        <div class="flex justify-center" v-if="show_load_more">
            <a role="button" @click="queryAvailability(meta.current_page + 1, true)" class="text-center btn btn--light-grey">Load more</a>
        </div>


        <transition name="popup-slide">
            <filter-drawer v-if="show_filters"
                v-model="search_filter"

                @close="show_filters=false"
            />
        </transition>
    </div>
</template>

<script>
import { AVAILABILITY_SEARCH, TOGGLE_FAVOURITE } from '@js/endpoints';
import { mapState, mapActions } from 'vuex';
import qs from 'qs';
import moment from 'moment';
export default {
    props: ['type', 'lng', 'lat', 'service_id', 'industry_id', 'name', 'dates', 'times', 'mobile'],
    data() {
        return {
            results: null,
            meta: null,
            components: {
                map: () => import(/* webpackChunkName: "components/MapType" */ "@js/components/search/MapType.vue"),
                list: () => import(/* webpackChunkName: "components/ListType" */ "@js/components/search/ListType.vue"),
            },
            show_skeleton: false,
            is_logged_in: false,
            view_type: this.type,
            search_filter: {
                location: {
                    lng: this.lng,
                    lat: this.lat,
                    name: this.name
                },
                service_id: this.service_id ? parseInt(this.service_id) : null,
                industry_id: this.industry_id ? parseInt(this.industry_id) : null,
                dates: this.dates,
                times: this.times,
                mobile: this.mobile ? 1 : 0
            },
            last_filter: {
                location: {
                    lng: this.lng,
                    lat: this.lat,
                    name: this.name
                },
                service_id: this.service_id ? parseInt(this.service_id) : null,
                industry_id: this.industry_id ? parseInt(this.industry_id) : null,
                dates: this.dates,
                times: this.times
            },
            show_filters: false,
            nearby_businesses: [],


        }
    },


    computed: {
        ...mapState({
            industries_list: 'industries',
            services_list: 'services',
            search_adjustments: 'search_adjustments',
            is_booking: 'is_booking',
        }),

        show_load_more() {
            return this.meta && this.meta.current_page < this.meta.last_page ? true : false;
        },

        fields() {
            return this.search_filter
        },

        store_fields() {
            return this.$store.state.search_filter.fields;
        },

        sorted_results() {
            return (this.results || []).sort((a, b)=> {
                let a_start = moment(a.availability_start);
                let b_start = moment(b.availability_start);
                if (a_start.isBefore(b_start)) return -1;
                else if (a_start.isAfter(b_start)) return 1;
                else return 0;
            })
        },
        results_message() {
            if (this.sorted_results && this.sorted_results.length < 1) {
                return "That search didn't find any results, try these instead";
            } else return null;
        },
        
    },

    methods: {
        ...mapActions(['checkIfAuthenticated', 'showMobileFilters']),

        queryAvailability(page = 1, concat = false) {
            this.show_skeleton = true;
            if (this.is_booking || this.$store.state.availability_drawer.visibility ||this.$store.state.service_drawer.visibility || this.$store.state.booker.visibility)  return;
            axios.get(`${AVAILABILITY_SEARCH}?page=${page}`, { params: this.fields, paramsSerializer: params => { return qs.stringify(params)} }).then(res => {
                if (res.data.meta.search_adjustments) {
                    this.$store.commit('mutateSearchAdjustments', res.data.meta.search_adjustments);
                } 
                if (12-res.data.data.length > 0) {
                    this.nearby_businesses = res.data.meta.nearby_businesses.filter(business => !res.data.data.find(a => a.business.id === business.id )).slice(0,12-res.data.data.length);

                } else {
                    this.nearby_businesses = [];
                }
                // else this.search_adjustments = {};
                this.results = !this.results || !concat ? res.data.data : this.results.concat(res.data.data);
                this.meta = res.data.meta;
                this.show_skeleton = false;
            }).finally(() => {
                this.show_skeleton = false;
            });
        },

        async fakeSkeleton() {
            this.show_skeleton = true;
            setTimeout(() => {
                this.show_skeleton = false;
                return Promise.resolve(true);
            }, 800);
        },

        toggleFavourite(business_id) {
            axios.post(TOGGLE_FAVOURITE, {
                business_id
            }).then(res => {
                this.results = this.results.map(item => {
                    if(item.business.id == business_id) {
                        item.favourite = !item.favourite;
                    }
                    return item;
                });

                this.nearby_businesses = this.nearby_businesses.map(business => {
                    if (business.id  === business_id) business.favourite = !business.favourite;

                    return business;
                });
            });
        }
    },

    mounted() {
        this.queryAvailability();
        this.checkIfAuthenticated().then(value => {
            this.is_logged_in = value ? true : false;
        });
    },

    watch: {
        'search_filter': {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if ( !this.last_filter.updated ||
                    newVal.location.lat != this.last_filter.location.lat ||
                    newVal.location.lng != this.last_filter.location.lng ||
                    newVal.service_id != this.last_filter.service_id ||
                    newVal.industry_id != this.last_filter.industry_id ||
                    newVal.dates.length != this.last_filter.dates.length ||
                    newVal.times.length != this.last_filter.times.length ||
                    newVal.mobile != this.last_filter.mobile
                ) {
                    this.$store.commit('setSearchFilters', newVal);
                    this.queryAvailability(1, false);
                    this.last_filter = {...newVal, updated: true}
                } 
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
