<template>
    <div style="min-height:50vh;">                        
        <transition name="fade">
            <div v-if="featuredPosts.length" class="mb-16">
            <a
                    v-for="(post, index) in featuredPosts" :key="index"
                    :href="blogBaseUrl+'/'+post.post_name"
                    :class="post.post_theme_colour" 
                    class="c-featured-post block text-white">
                    <div 
                        :style="{ background: 'center / cover no-repeat url('+(!post.post_featured_image ? '/images/blog-fallback.svg' : post.post_featured_image )+')' }"
                        class="lg:h-full lg:w-6/12 lg:absolute lg:top-0 lg:left-0 px-8" style="min-height:275px;"
                        role="image"
                    >
                    </div>
                    <div class="container mx-auto">
                        <div class="lg:flex lg:justify-end lg:items-center -mx-8">
                            <div class="w-full md:w-10/12 lg:w-6/12 px-8 mx-auto lg:mx-0">
                                <div class="w-full xl:w-10/12 lg:px-8 flex flex-col items-center text-center py-8 mx-auto">
                                    <ul class="flex flex-wrap items-center justify-center -mx-2 mb-2">
                                        <li 
                                            v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                            class="uppercase font-semibold px-2"
                                        >{{ category.cat_name }}</li>
                                    </ul>
                                    <h1 v-html="post.post_title" class="text-white" style="margin-bottom:0;"></h1>
                                    <span class="block text-base font-normal italic mb-6">by {{ post.post_author.fname}} {{ post.post_author.sname}}, {{ post.post_author.job_role}}</span>
                                    <span class="block text-base font-normal">{{ moment(post.post_date).format('Do MMMM YYYY') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </transition>

        <div class="container mx-auto">
            <transition name="fade">
                <div
                    v-if="nonFeaturedPosts.length" 
                    class="c-blog-grid"
                    data-blog-masonry-grid
                >
                    <div 
                        v-for="(post, index) in nonFeaturedPosts" :key="index" 
                        data-blog-masonry-item 
                        class="c-blog-grid__item">
                        <a 
                            :href="blogBaseUrl+'/'+post.post_name" 
                            data-blog-masonry-item-content
                            class="shadow-lg-allsides block h-full">
                            <div   
                                v-if="post.post_featured_image" 
                                :style="{ background: 'center / cover no-repeat url('+post.post_featured_image+'), #f2f2f2' }" 
                                style="padding-top:65%;"
                            >
                            </div>
                            <div class="p-6 text-center">
                                <ul class="flex flex-wrap items-center justify-center -mx-2 mb-2">
                                    <li 
                                        v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                        class="uppercase text-copper-400 font-semibold px-2"
                                    >{{ category.cat_name }}</li>
                                </ul>
                                <p 
                                    v-html="post.post_title"
                                    class="text-xl font-bold font-poynter text-black mb-0"
                                ></p>
                                <span class="block text-base text-grey-400 font-normal italic mb-4">by {{ post.post_author.fname}} {{ post.post_author.sname}}, {{ post.post_author.job_role}}</span>
                                <span class="block text-base text-black font-normal">{{ moment(post.post_date).format('Do MMMM YYYY') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </transition>

            <div
                v-if="postsPerPage*page < numberOfPosts"
                class="mt-16 flex justify-center">
                <span 
                    @click="page = page + 1" 
                    role="button" 
                    class="btn btn--outline btn--copper w-full md:w-auto"
                >
                    Load more
                </span>
            </div>          

            <div v-if="noResults">
                <h3 class="text-center mt-16">Sorry, there are currently no blogs that match your search.<br>Try another category or check back soon!</h3>
            </div> 
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { FETCH_BLOGS } from '@js/endpoints';

    export default {
        data() {
            return {
                page: 1,
                postsPerPage: 9,
                numberOfPosts: 0,
                allPosts: [],
                featuredPosts: [],
                nonFeaturedPosts: [],
                noResults: false,
                moment,
            }
        },

        props: {
            requestedCategory: {
                type: String,
                required: false
            },

            blogBaseUrl: {
                type: String,
                required: true
            },
        },

        mounted() {
            this.compilePosts(this.requestedCategory, this.page),
            
            window.addEventListener("resize", this.resizeDebounce)
        },

        watch: {
            page: function() {
                this.compilePosts(this.requestedCategory, this.page);
            },

            allPosts: function() {
                this.featuredPosts = this.allPosts.filter(post_featured => post_featured.post_featured == true);
                this.nonFeaturedPosts = this.allPosts.filter(post_featured => post_featured.post_featured == null || post_featured.post_featured == false);
                
                this.$nextTick(() => {
                    this.resizeGridItems();
                })
            }
        },

        methods: {
            compilePosts(category, page) {
                axios.get(FETCH_BLOGS(category, page, this.postsPerPage)).then(res => {
                    this.numberOfPosts = res.data.number_of_posts;

                    res.data.posts.forEach(post => {
                        this.allPosts.push(post);
                    });

                    this.$nextTick(() => {
                        (!this.allPosts.length ? this.noResults = true : null);
                    })

                });
            },

            resizeGridItems() {
                document.querySelectorAll('[data-blog-masonry-item]').forEach(function(item) {
                    var grid = document.querySelector('[data-blog-masonry-grid]');
                    var rowHeight = 1;
                    var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
                    var rowSpan = Math.ceil((item.querySelector('[data-blog-masonry-item-content').offsetHeight+rowGap)/(rowHeight+rowGap));
                    item.style.gridRowEnd = "span "+rowSpan;
                });
            },

            resizeDebounce() {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.timeout = setTimeout(() => {
                    setTimeout(() => {
                        this.resizeGridItems()
                    }, 0);
                }, 250);
            }
        }
    }
</script>

<style lang="scss" scoped>
.c-featured-post {
    transition: 0.4s ease;
    
    @screen md {
        min-height: 35vh;
    }

    @screen lg {
        @apply relative flex items-center;
        min-height: 60vh;
    }

    @screen 3xl {
        min-height: 45vh;
    }


    &.bg-copper-400 {
        &:hover {
            background-color: darken(#b16d00, 10%);
        }
    }

    &.bg-teal-400 { 
        &:hover {
            background-color: darken(#bbded3, 10%);
        }
    }
}

.c-blog-grid {
    display: grid;
    align-items: flex-start;
    grid-gap: 24px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: auto;

    @screen md {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @screen lg {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        -ms-grid-columns: repeat(3, minmax(0, 1fr));
    }
    
    //Browsers that don't fully support grid (not including ie11)
    @supports not (grid-template-columns: repeat(3, minmax(0, 1fr)) ) {
        @apply flex flex-wrap items-stretch -mx-1 -mt-2;

        &__item {
            @apply w-full mt-2 px-1;

            @screen md {
                @apply w-6/12;
            }

            @screen lg {
                @apply w-4/12;
            }
        }
    }

    &__item {
        transition: 0.4s ease;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0 0 10px 6px rgba(169, 169, 169, 0.3);
            z-index: 1;
        }
    }
}
</style>