var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            _vm.interacting = false
          },
          expression: "() => { interacting = false }"
        }
      ],
      staticClass: "relative"
    },
    [
      _c(
        "div",
        {
          staticClass: "c-filter-option",
          class: _vm.times.length ? " c-filter-option--active" : null,
          on: {
            click: function($event) {
              _vm.interacting = !_vm.interacting
            }
          }
        },
        [
          _c(
            "span",
            { staticClass: "c-filter-option__text", attrs: { role: "button" } },
            [_vm._v(_vm._s(_vm.selected_label))]
          ),
          _vm._v(" "),
          _vm.times.length
            ? _c(
                "span",
                {
                  staticClass: "c-filter-option__clear",
                  attrs: {
                    role: "button",
                    "aria-label": "Remove this filter option"
                  },
                  on: { click: _vm.clearSelected }
                },
                [
                  _c("i", {
                    staticClass: "fal fa-times hidden md:inline-block"
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "inline-block md:hidden uppercase text-sm font-semibold"
                    },
                    [_vm._v("Edit")]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.interacting,
              expression: "interacting"
            }
          ],
          staticClass: "c-filter-option__drop-down"
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.options, function(option, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "c-form__stylised-checkbox c-form__stylised-checkbox--round"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.times,
                          expression: "times"
                        }
                      ],
                      attrs: { type: "checkbox", id: option.label + index },
                      domProps: {
                        value: option.value,
                        checked: Array.isArray(_vm.times)
                          ? _vm._i(_vm.times, option.value) > -1
                          : _vm.times
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.times,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = option.value,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.times = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.times = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.times = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: option.label + index } }, [
                      _vm._v(_vm._s(option.label))
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "text-sm text-center block",
                  class: _vm.times.length
                    ? " text-grey-1000 underline hover:no-underline"
                    : null,
                  attrs: { role: "button" },
                  on: { click: _vm.clearSelected }
                },
                [_vm._v("Clear All")]
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }