var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col col-reverse" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.current_value,
          expression: "current_value"
        }
      ],
      ref: "textarea",
      staticClass: "textarea--with-wordcount",
      class: _vm.prop_class || "c-input c-input--textarea",
      attrs: { name: _vm.name, maxlength: _vm.max_length },
      domProps: { value: _vm.current_value },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.current_value = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "block self-end mt-2",
        class: { "text-red-400": _vm.current_length == 2000 }
      },
      [
        _vm._v("\n        " + _vm._s(_vm.current_length) + " "),
        _vm.max_length ? [_vm._v("/ " + _vm._s(_vm.max_length))] : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }