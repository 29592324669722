var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-image-slider mb-4" },
      [
        _c(
          "transition-group",
          { attrs: { name: "gallery-fade" } },
          [
            _vm._l(_vm.visible_slides, function(image, index) {
              return _vm.transition_fade
                ? _c("div", {
                    key: "first" + index,
                    staticClass: "c-image-slider__slide shadow-md",
                    style: { backgroundImage: "url(" + image + ")" },
                    on: {
                      click: function($event) {
                        index == 0
                          ? _vm.active_image_index--
                          : index !== 1
                          ? _vm.active_image_index++
                          : null
                      }
                    }
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.previous_visible_slides, function(image, index) {
              return _c("div", {
                key: "second" + index,
                staticClass: "c-image-slider__slide  hidden md:block",
                style: { backgroundImage: "url(" + image + ")" }
              })
            })
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "c-dot-pagination" },
      _vm._l(_vm.images, function(image, index) {
        return _c("li", {
          key: index,
          staticClass: "c-dot-pagination__item",
          class: {
            active:
              index ==
              _vm.correctArrayPointer(0, _vm.active_image_index, _vm.images)
          },
          attrs: { role: "button" },
          on: {
            click: function($event) {
              _vm.active_image_index = _vm.correctArrayPointer(
                0,
                index,
                _vm.images
              )
            }
          }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }