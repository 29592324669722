var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-popup-drawer" }, [
    _c("div", { staticClass: "c-popup-drawer__inner" }, [
      _c(
        "button",
        {
          staticClass: "c-drawer-close",
          attrs: { "aria-label": "Close" },
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "c-drawer-close__icon",
              staticStyle: { top: "-2px" }
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "c-drawer-close__tooltip" }, [
            _vm._v("Cancel?")
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "text-copper-400" }, [
          _vm._v(
            "\n        Availability - " +
              _vm._s(
                _vm.availability[0] ? _vm.availability[0].business_name : null
              ) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        !_vm.availability.length
          ? _c("p", [_vm._v("Loading availability...")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "c-stylised-table w-full text-grey-1000" },
          _vm._l(_vm.availability, function(slot, index) {
            return _c("tr", { key: index, staticClass: "flex flex-wrap" }, [
              _c("td", { staticClass: "w-full" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(
                        slot.availability_start,
                        slot.availability_end
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text-copper-400 italic" }, [
                  _vm._v("\n              with\n              "),
                  _c(
                    "a",
                    {
                      staticClass: "underline hover:no-underline",
                      attrs: { href: slot.service_provider.link }
                    },
                    [_vm._v(_vm._s(slot.service_provider.name))]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass:
                    "text-grey-1000 items-center flex font-poynter font-bold text-lg text-right"
                },
                [
                  _c("span", { staticClass: "flex flex-row" }, [
                    _c("span", { staticClass: "italic text-102 mr-1" }, [
                      _vm._v("From ")
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(slot.cost))])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("td", { staticStyle: { "padding-bottom": "6.5px" } }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn--teal truncate whitespace-nowrap",
                    on: {
                      click: function($event) {
                        return _vm.bookClicked(slot)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.services.length > 1 ? "See Services" : "Book"
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }