var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-form__radio-as-toggle" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        attrs: { type: "radio", name: "view-type", id: "list", value: "list" },
        domProps: { checked: _vm._q(_vm.selected, "list") },
        on: {
          change: function($event) {
            _vm.selected = "list"
          }
        }
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "list" } }, [_vm._v("List")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        attrs: { type: "radio", name: "view-type", id: "map", value: "map" },
        domProps: { checked: _vm._q(_vm.selected, "map") },
        on: {
          change: function($event) {
            _vm.selected = "map"
          }
        }
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "map" } }, [_vm._v("Map")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "md:hidden" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        staticClass: "hidden",
        attrs: {
          type: "checkbox",
          id: "mobile-view-toggle",
          "true-value": "list",
          "false-value": "map"
        },
        domProps: {
          checked: Array.isArray(_vm.selected)
            ? _vm._i(_vm.selected, null) > -1
            : _vm._q(_vm.selected, "list")
        },
        on: {
          change: function($event) {
            var $$a = _vm.selected,
              $$el = $event.target,
              $$c = $$el.checked ? "list" : "map"
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.selected = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.selected = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass:
            "text-grey-1000 uppercase font-semibold text-base cursor-pointer",
          attrs: { for: "mobile-view-toggle" }
        },
        [_vm._v(_vm._s(_vm.selected == "map" ? "List" : "Map") + " View")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }