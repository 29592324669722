<template>
    <div>
        <div class="c-image-uploader__image cursor-pointer"
            :class="{
                'c-image-uploader__image--processing' : processing
            }"
            :style="background"
        >
            <div class="button-wrapper flex flex-row" :class="{'justify-between': preview, 'justify-center': !preview}">
                <div class="upld-btn-wrapper flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white"
                    :style="circleStyle" v-if="!disabled"
                    v-show="!preview"
                >
                    <input type="file"
                        :disabled="disabled"
                        v-show="!preview"
                        accept="image/*"
                        @change="generatePreview($event);
                        preventNavigateAway($event)"
                        ref="upload_field"
                        :name="name"
                        class=" hidden-img-input w-full h-full opacity-0 bottom-0 left-0  absolute cursor-pointer"
                    />
                    <i class="profile-img-btn fas fa-pencil-alt text-sm" v-if="!preview"></i>
                </div>
                <div @click="clearField" v-if="preview && !saving" class="flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white red" :style="circleStyle" :class="{'pointer-events-none': !preview}">
                    <i class="profile-img-btn fal fa-trash-alt text-sm cursor-pointer"  v-if="preview"></i>
                </div>

                <div  @click="savePicture" v-if="preview && !saving" class="flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white green" :style="circleStyle" :class="{'pointer-events-none': !preview}">
                    <i class="profile-img-btn fal fa-save text-sm cursor-pointer"  ></i>
                </div>
            </div>
        </div>
        <!-- <a role="button" v-if="preview" @click="clearField" class="text-center">Clear</a> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            preview: null,
            file: null,
            done: false,
            saving: false,
        }
    },

    computed: {
        background() {
            return this.preview ? `background-image: url('${this.preview}')` : (this.existing ? `background-image: url('${this.existing}')` : null);
        },
        circleStyle() {
            return {
                width : '40px',
                height: '40px',
                bottom: '0px',
                zIndex: 10
                // transform: 'translatex(-50%)'
            }
        }
    },

    props: {
        maxFileSize: {
            type: Number,
            default: 18 //MB
        },

        name: {
            type: String,
            required: true
        },

        existing: {
            type: String,
            required: false,
            default: null
        },

        processing: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            default: false
        }
    },

    methods: {
        generatePreview(e) {
            let filesize_valid = this.validFileSize(e.target.files[0]);
            if (filesize_valid) {
                this.preview = URL.createObjectURL(e.target.files[0]);
                this.file = e.target.files[0];
            } else {
                alert('File too big!');
                this.clearField();
            }
        },

        clearField() {
            this.$refs.upload_field.value = '';
            this.preview = null;
        },

        validFileSize(file) {
            return file.size > this.maxFileSize * 1024 * 1024 ? false : true;
        },

        preventNavigateAway(e) {
            window.onbeforeunload = event => {
                event.preventDefault();
                event.returnValue = '';
            };
        },

        savePicture() {
            if (this.saving) return;
            let form_data = new FormData();
            this.saving = true;
            form_data.append('profile_img', this.file, this.file.fileName );
            axios.post('/my-account/profile', form_data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`
                }
            }).then(response => {
                this.saving = false;
                window.onbeforeunload = () => {console.log("ABOUT TO UNLOAD");}
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch(error => {
                alert("There was a problem processing the file");
                this.saving = false;
            })
        }
    }
}
</script>
<style lang="scss" scoped>

    .button-wrapper {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 40px;
    }

    .red {
        background-color: desaturate(darken(red, 10%), 10%);
    }

    .green {
        background-color: desaturate(darken(green, 10%), 10%);
    }

    .upld-btn-wrapper {
        position: relative;
    }

    .hidden-img-input {
        width: 40px;
        height: 40px;
    }
</style>
