var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ],
      staticStyle: { padding: "0" }
    },
    [
      _c("div", { staticClass: "c-blog-slider" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full lg:w-7/12 p-4 md:p-8 lg:p-10 xl:p-12 relative c-blog-slider__image-holder"
          },
          [
            _vm._l(_vm.blog_single_gallery.slider.slides, function(
              slide,
              index
            ) {
              return _c("div", { key: index, staticClass: "lg:h-full" }, [
                _c("div", {
                  staticClass: "c-blog-slider__slide",
                  class: {
                    "c-blog-slider__slide--active": _vm.currentSlide == index
                  },
                  style: {
                    background:
                      "center / cover no-repeat url(" + slide.image.url + ")"
                  }
                })
              ])
            }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "flex justify-center relative mb-0",
                staticStyle: { "z-index": "1" }
              },
              _vm._l(_vm.blog_single_gallery.slider.slides, function(
                slide,
                index
              ) {
                return _c("li", {
                  key: index,
                  staticClass:
                    "h-4 w-4 rounded-full m-1 bg-white hover:bg-copper-400 cursor-pointer shadow-lg-allsides",
                  class: { "bg-copper-400": _vm.currentSlide == index },
                  staticStyle: { transition: "0.4s ease" },
                  on: {
                    click: function($event) {
                      _vm.currentSlide = index
                    }
                  }
                })
              }),
              0
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "w-full lg:w-5/12 p-4 md:p-8 lg:p-10 xl:p-12",
            staticStyle: { "max-height": "100%" }
          },
          [
            _c("div", { staticClass: "c-blog-slider__content" }, [
              _c(
                "div",
                [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.blog_single_gallery.slider.title))
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.blog_single_gallery.slider.slides, function(
                    slide,
                    index
                  ) {
                    return _c("div", { key: index }, [
                      _vm.currentSlide == index
                        ? _c("div", [_c("p", [_vm._v(_vm._s(slide.caption))])])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-blog-slider__nav" }, [
              _vm.currentSlide > 0
                ? _c(
                    "span",
                    {
                      staticClass:
                        "absolute left-0 bottom-0 underline hover:no-underline cursor-pointer",
                      attrs: { role: "button", "aria-label": "Previous slide" },
                      on: {
                        click: function($event) {
                          _vm.currentSlide = _vm.currentSlide - 1
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentSlide + 1 <
              _vm.blog_single_gallery.slider.slides.length
                ? _c(
                    "span",
                    {
                      staticClass:
                        "absolute right-0 bottom-0 underline hover:no-underline cursor-pointer",
                      attrs: { role: "button", "aria-label": "Next slide" },
                      on: {
                        click: function($event) {
                          _vm.currentSlide = _vm.currentSlide + 1
                        }
                      }
                    },
                    [_vm._v("Next")]
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }