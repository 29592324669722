var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.match_found && !Object.keys(_vm.appointment).length,
          expression: "match_found && !Object.keys(appointment).length"
        }
      ],
      staticClass: "suggestion-wrapper"
    },
    [
      _c(
        "a",
        {
          class: _vm.className,
          attrs: { role: "button" },
          on: {
            click: function($event) {
              _vm.initAvailabilityDrawer(_vm.businessId)
              _vm.mutateQuickLookDrawerVisibility(false)
            }
          }
        },
        [
          _vm._v(
            "\n        Book " +
              _vm._s(_vm.service ? _vm.service.name : null) +
              "\n        "
          ),
          _c(
            "span",
            {
              staticClass: "clear-btn",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.clearSuggestion($event)
                }
              }
            },
            [_c("i", { staticClass: "fal fa-times" })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }