var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.results_message
      ? _c("span", { staticClass: "mb-4 font-semibold mx-4 inline-block" }, [
          _vm._v(_vm._s(_vm.results_message))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-wrap md:-mx-3 -mt-6" }, [
      _c(
        "div",
        { ref: "scroll_area", staticClass: "md:px-3 w-6/12 mt-6" },
        [
          !_vm.showSkeleton
            ? [
                _vm._l(_vm.results, function(availability, index) {
                  return _c("search-result-map", {
                    key: "search-result-map-avail-" + availability.id,
                    ref: "result-business-" + availability.business_id,
                    refInFor: true,
                    attrs: {
                      availability: availability,
                      isLoggedIn: _vm.isLoggedIn,
                      index: index,
                      active_result_index: _vm.active_result_index
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleResultClick(index)
                      }
                    }
                  })
                }),
                _vm._v(" "),
                _vm._l(_vm.nearby, function(business, index) {
                  return _c("search-result-map", {
                    key: "business-" + business.id,
                    ref: "result-business-" + business.id,
                    refInFor: true,
                    attrs: {
                      business: business,
                      isLoggedIn: _vm.isLoggedIn,
                      index: _vm.results.length + index,
                      active_result_index: _vm.active_result_index
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleResultClick(index + _vm.results.length)
                      },
                      "toggle-favourite": function($event) {
                        return _vm.$emit("toggle-favourite", $event)
                      }
                    }
                  })
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showSkeleton
            ? _vm._l(4, function(i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass:
                      "w-full flex-wrap shadow-lg-allsides mb-5 hidden md:flex"
                  },
                  [
                    _c("div", {
                      staticClass: "w-full lg:w-40 skeleton-image",
                      staticStyle: { "min-height": "125px" }
                    }),
                    _vm._v(" "),
                    _vm._m(0, true)
                  ]
                )
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-screen flex flex-col",
          staticStyle: { height: "calc(100vh - 95px)" }
        },
        [
          _c(
            "gmap-map",
            {
              ref: "searchMap",
              staticClass: "c-map w-full flex-grow flex-shrink",
              attrs: { center: _vm.centre, zoom: 7 }
            },
            _vm._l(_vm.markers, function(marker, index) {
              return _c("gmap-marker", {
                key: index,
                attrs: {
                  position: {
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng)
                  },
                  icon: _vm.generateIconObj(index)
                },
                on: {
                  click: function($event) {
                    return _vm.handleMarkerClick(marker, index)
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm._m(1)
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-wrap items-center justify-between p-6 flex-grow"
      },
      [
        _c("div", { staticClass: "pr-6 mb-4 xl:mb-0 w-full xl:w-9/12" }, [
          _c("span", { staticClass: "skeleton-text w-6/12" }),
          _vm._v(" "),
          _c("span", {
            staticClass:
              "text-copper-400 font-poynter font-bold text-2xl block skeleton-text w-8/12"
          }),
          _vm._v(" "),
          _c("p", { staticClass: "mb-0 text-base skeleton-text w-10/12" })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full xl:w-2/12 flex items-center text-center" },
          [_c("span", { staticClass: "w-full skeleton-text" })]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "container mx-auto flex-grow-0 flex-shrink-0 py-4 text-center text-sm uppercase text-grey-1000 font-semibold"
      },
      [_c("span", [_vm._v("Click to view more info or book")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }