<template>
    <div>
        <div class="container mx-auto">
            <h2 class="my-6 text-center">{{results && results.length}} Appointments</h2>
        </div>
        <div class="relative">

            <div class="results-scroll-track p-4">
                <span v-if="results_message" class="mb-4 font-semibold">{{results_message}}</span>
                <search-result v-for="(availability, index) in results"
                    :key="`availability-search-result-${availability.id}`"
                    :availability="availability"
                    :isLoggedIn="isLoggedIn"
                />

                <!-- SHOW UNAVAILABLE BUSINESSES -->
                <search-result v-for="(business, index) in nearby"
                    :key="`availability-search-result-business-${business.id}`"
                    :business="business"
                    :isLoggedIn="isLoggedIn"
                    v-on:toggle-favourite="$emit('toggle-favourite', $event)"
                />

                <!-- No results -->
                <!-- <div class="section-pt--md text-center w-full whitespace-normal" v-if="Array.isArray(results) && !results.length && !showSkeleton">
                    <div class="container mx-auto">
                        <img src="/images/headshots/no-results.png" aria-hidden="true" class="no-results-img">
                        <h2 class="mb-2">Oh no!</h2>
                        <p class="mb-0 text-black text-base w-full">
                                It looks like our businesses are busy at these times. Try making the search less specific, if you can
                        </p>
                    </div>
                </div> -->

                <!-- / No results -->
                <template v-if="showSkeleton">
                    <div class="col flex flex-shrink-0 whitespace-normal w-full md:w-6/12 lg:w-4/12 xl:w-3/12 3xl:w-2/12 mt-6" v-for="i in 4" :key="i">
                        <div class="flex flex-col w-full shadow-lg-allsides">
                            <div class="flex flex-wrap items-start flex-shrink-0 flex-grow p-4">
                                <div class="flex flex-col w-8/12">
                                    <span class="w-8/12 skeleton-text mb-2"></span>
                                    <span class="w-10/12 skeleton-text mb-2"></span>
                                </div>
                                <div class="w-4/12 md:pl-2 md:text-right">
                                    <span class="w-full skeleton-text skeleton-text--lg"></span>
                                </div>
                            </div>
                                <div class="flex-grow-0 flex-shrink">
                                <div class="relative skeleton-image skeleton-image--dark skeleton-image--no-shine" style="padding-top:56.25%; min-height:225px;">
                                    <div class="w-full absolute bottom-0 left-0 flex justify-between px-4 py-2 text-white c-image-overlay-gradient__inner"></div>
                                </div>
                                </div>
                            <div class="skeleton-text" style="height:43px;"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
let formatter = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});
export default {

    props: {
        results: {
            type: Array,
            default: []

        },

        showSkeleton: {
            type: Boolean,
            default: false
        },

        isLoggedIn: {
            type: Boolean,
            default: false
        },

        search_filter: {

        },
               // If the API returned more 'relaxed' parameters than what were entered, in order to improve results:
        results_message: {
            type: String,
        },
        nearby: {},
    },

    methods: {
        ...mapActions(['initBooking','initQuickLook', 'initAvailabilityDrawer']),
        getFormattedCost(cost) {
            return formatter.format(cost);
        },
    },


}
</script>

<style lang="scss" scoped>
    @keyframes favourite-heart {
        from    { background-position: left; }
        to      { background-position: right; }
    }

    @keyframes heart-swell {
        0% {
            transform: scale(1, 1);
        }
        100% {
            transform: scale(1.5, 1.5);
        }
    }

    @keyframes heart-beat {
        0% {
            transform: scale(1.5, 1.5);
        }
        100% {
            transform: scale(1, 1);
        }
    }
    .c-salon-preview-card {
        transition: 0.4s ease;
    }

    .c-salon-preview-card > div {
        transition: 0.4s ease;
    }

    .favourite-button {
        transition: transform 0.4s ease;
        font-size: 1.2em;
        transform: scale(1, 1);
        &:active {
            transform: scale(1.5, 1.5);
        }

        // &:not(:active):hover:focus {
        //     animation: heart-beat .4s 1 forwards;

        // }
    }

    .rating {
        font-size: 0.8em;
        margin-left: -2px;
        line-height: 32px;

    }

    .star-icon {
        font-size: 0.6em;
        margin-bottom: 2px;
        line-height: 32px;
    }
    .day-word {
        // color: black;
    }

    .info-button {
        flex: 1
    }

    .book-button {
        flex: 4
    }

    .heart-red {
        color: #E2264D;
    }
</style>
