<template>
    <div v-on-clickaway="() => cancelled ? reloadPage() : mutateOverlayVisibility(false)" class="c-popup">
        <div v-if="!cancelled">
            <h2 class="c-popup__title">Cancel Appointment</h2>
            <p>You're about to cancel your <strong>{{ params.service_name }}</strong> with <strong>{{ params.service_provider }}</strong> at <strong>{{ params.business_name }}</strong></p>

            <div>
                <label class="c-form__label block w-full">Reason for cancelling</label>
                <select class="c-form__select block w-full" v-model="reason">
                    <option :value="null">Please select a reason</option>
                    <option value="I've found an appointment elsewhere">I've found an appointment elsewhere</option>
                    <option value="The appointment conflicts with my other commitments">The appointment conflicts with my other commitments</option>
                    <option value="I no longer require this service">I no longer require this service</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div v-if="reason === 'other'">
                <label class="c-form__label block w-full">Please specify</label>
                <textarea class="c-form__input c-form__input--textarea block w-full" v-model="reason_other"></textarea>
            </div>
            <a class="btn btn--teal w-full text-center" @click="cancelAppointment" v-if="cancellation_reason">Cancel Appointment</a>
        </div>

        <div v-if="cancelled">
            <h2 class="c-popup__title">Appointment Cancelled</h2>
            <p>Your appointment has been cancelled. You should receive email confirmation of this shortly. </p>
            <p>Please note that excessive cancelations may affect your reputation with salons, and make future appointments harder to secure.</p>
        </div>

    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { ACCOUNT_BOOKING_CANCEL } from '@js/endpoints';

export default {
    data() {
        return {
            cancelled: false,
            reason: null,
            reason_other: null
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            params: state => state.component_params.appointmentCancel,
        }),

        cancellation_reason() {
            return this.reason ? (this.reason == "other" ? this.reason_other : this.reason) : null;
        }
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),

        cancelAppointment() {
            axios.put(ACCOUNT_BOOKING_CANCEL(this.params.id), {
                reason: this.cancellation_reason
            }).then(res => {
                this.cancelled = true;
            }).catch(err => {
                alert("There was an error cancelling that appointment.\n\nIf this error continues, please get in touch with the salon.");
            })
        },

        reloadPage() {
            window.location = window.location;
        }
    }
}
</script>
