<template>
    <div>
        <div class="flex justify-center mb-12 md:mb-16">
            <div class="bg-grey-200 flex p-2" style="border-radius:2rem;">
                <div class="text-grey-1000 text-base uppercase font-semibold py-2 px-8" :class="{ 'bg-teal-400' : showMonthlyPrice }" style="border-radius:2rem" role="button" aria-label="Show package prices as monthly" @click="showMonthlyPrice = !showMonthlyPrice">
                    <span>Monthly</span>
                </div>
                <div class="text-grey-1000 text-base uppercase font-semibold py-2 px-8" :class="{ 'bg-teal-400' : !showMonthlyPrice }" style="border-radius:2rem" role="button" aria-label="Show package prices as yearly" @click="showMonthlyPrice = !showMonthlyPrice">
                    <span>Yearly</span>
                </div>
            </div>
        </div>

        <div class="row justify-center mb-12 mb-16 -mt-16 md:-mt-12">

            <a v-for="(option, index) in options" :key="index" :href="link + '?package='+option.id" class="col w-10/12 md:w-5/12 lg:w-3/12 text-center mt-16 md:mt-12">
                <div class="relative w-full bg-teal-400 rounded-full mb-6" style="padding-bottom:100%;">
                    <div class="absolute w-full h-full flex flex-col items-center justify-center text-grey-1000">
                        <span class="font-poynter font-bold">{{ option.name }}</span>
                        <span class="font-poynter font-bold text-4xl xl:text-6xl text-copper-400">
                            <sup class="text-2xl xl:text-4xl mr-2">£</sup>
                            <span>{{ showMonthlyPrice ? (option.cost_per_1/100).toFixed(2) : (option.cost_per_12/100) }}</span>
                        </span>
                        <span class="text-center text-normal font-semibold"><span class="font-poynter font-bold italic">{{ option.seats == 1 ? 1 : `${options[index-1].seats+1} - ${option.seats}` }}</span> {{ (option.seats == '1' ? 'diary' : 'diaries') }}</span>
                        <div class="absolute px-5 py-2 text-white bg-red-500 font-bold text-base" v-if="showMonthlyPrice" style="bottom: 3%">1 Month Free Trial</div>
                    </div>
                </div>
                <p class="mb-0">{{ option.description }}</p>
            </a>

        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            showMonthlyPrice: true
        }
    },

    props: {
        options: {
            type: Array,
            required: true
        },

        link: {
            required: true,
            type: String
        }
    },

    mounted() {
    },

    computed: {
    },

    methods: {

    }
}
</script>

<style lang="scss" scoped>

</style>
