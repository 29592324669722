var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "lg:bg-grey-200" }, [
          _c("div", { staticClass: "container mx-auto" }, [
            _c(
              "ul",
              { staticClass: "c-wf-tab-nav" },
              _vm._l(_vm.tabs, function(link, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "c-wf-tab-nav__item",
                    class: {
                      "c-wf-tab-nav__item--active": _vm.activeTab == link.url
                    }
                  },
                  [
                    _c("a", { attrs: { href: "#" + link.url } }, [
                      _vm._v(_vm._s(link.name))
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col md:flex-row justify-center items-center lg:hidden py-3"
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "flex-shrink-0 flex-grow-0 text-grey-1000 block mb-2 md:mb-0 md:mr-4"
                  },
                  [_vm._v("Show me information on")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "c-wf-dd-sim",
                    class: { "c-wf-dd-sim--open": _vm.showDropDownOptions }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "c-wf-dd-sim__trigger",
                        attrs: { role: "button" },
                        on: {
                          click: function($event) {
                            _vm.showDropDownOptions = !_vm.showDropDownOptions
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.activeTab != null
                              ? _vm.active_pretty_name.name
                              : "Barbers"
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "c-wf-dd-sim__options" }, [
                      _c(
                        "ul",
                        { staticClass: "mb-0" },
                        _vm._l(_vm.tabs, function(link, index) {
                          return _c("li", { key: index }, [
                            _vm.activeTab != link.url
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" + link.url },
                                    on: {
                                      click: function($event) {
                                        _vm.showDropDownOptions = false
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(link.name))]
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.activeTab == "barbers"
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("section", [
              _c(
                "div",
                { staticClass: "c-wf-tab-content-hero" },
                [
                  _vm._l(_vm.tabs[_vm.active_tab_index].images, function(
                    image,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "wf-fade-slide",
                      class: {
                        "wf-fade-slide--active": _vm.sliderActiveSlide == index
                      },
                      style: {
                        background:
                          "center / cover no-repeat url(" + image + ")"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-wf-tab-content-hero__content container mx-auto"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "uppercase font-bold text-white",
                          staticStyle: { "letter-spacing": "4px" }
                        },
                        [_vm._v("Eleventh Hour For")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-center text-white font-poynter text-3xl lg:text-5xl mb-0"
                        },
                        [_vm._v("Barbers")]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-py--sm" }, [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16"
                    },
                    [
                      _c("p", { staticClass: "h2 h2--copper text-center" }, [
                        _vm._v(
                          "Finally; a booking platform that doesn't take commission or charge a fortune."
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Perfect for your"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" usual walk-ins")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("We're the "),
                            _c(
                              "a",
                              {
                                staticClass: "underline",
                                attrs: { href: _vm.whyLink }
                              },
                              [_vm._v("only platform")]
                            ),
                            _vm._v(
                              " marketed specifically at last-minute appointments."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Say 'bye-bye' to "),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v("empty chairs")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Use our push notifications to fill any no-shows or last-minute cancellations with customers who've favourited you."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("One fixed"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" monthly cost")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Forget commission, forget huge fees. "),
                            _c(
                              "a",
                              {
                                staticClass: "underline",
                                attrs: { href: _vm.packagesLink }
                              },
                              [_vm._v("Our scalable model")]
                            ),
                            _vm._v(
                              " means you pay per 'diary', not per business."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("You process"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" all payments")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Cash, card... however you do things, you process the payment and keep the lot."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Optional"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" approval process")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Choose to automatically accept bookings or review them first. Our customer profiles will give you everything you need to know."
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab == "salons"
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("section", [
              _c(
                "div",
                { staticClass: "c-wf-tab-content-hero" },
                [
                  _vm._l(_vm.tabs[_vm.active_tab_index].images, function(
                    image,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "wf-fade-slide",
                      class: {
                        "wf-fade-slide--active": _vm.sliderActiveSlide == index
                      },
                      style: {
                        background:
                          "center / cover no-repeat url(" + image + ")"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-wf-tab-content-hero__content container mx-auto"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "uppercase font-bold text-white",
                          staticStyle: { "letter-spacing": "4px" }
                        },
                        [_vm._v("Eleventh Hour For")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-white font-poynter text-3xl lg:text-5xl mb-0"
                        },
                        [_vm._v("Hair & Beauty Salons")]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-py--sm" }, [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16"
                    },
                    [
                      _c("p", { staticClass: "h2 h2--copper text-center" }, [
                        _vm._v(
                          "Swap last minute cancellations & no-shows for paying customers."
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Fill your last"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" minute appointments")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Advertise and fill last-minute cancellations and empty slots simply by listing them."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Hair, beauty,"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" both?")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "List the different services each of your employee's offer, and the times they're available - then leave the rest to us."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Say NO"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" to sneaky commission!")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "With us, you pay one fixed monthly cost and never ever any commission (even on new customers)!"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Set your prices"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" and payment methods")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "More experienced? Don't want to discount? You set the price for each service and stylist and process this in the salon."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Use us alongside or instead"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" of another platform")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "We're the only one specifically connecting you to customers looking for last-minute appointments, but you can use us for your other bookings too!"
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab == "aestheticians"
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("section", [
              _c(
                "div",
                { staticClass: "c-wf-tab-content-hero" },
                [
                  _vm._l(_vm.tabs[_vm.active_tab_index].images, function(
                    image,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "wf-fade-slide",
                      class: {
                        "wf-fade-slide--active": _vm.sliderActiveSlide == index
                      },
                      style: {
                        background:
                          "center / cover no-repeat url(" + image + ")"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-wf-tab-content-hero__content container mx-auto"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "uppercase font-bold text-white",
                          staticStyle: { "letter-spacing": "4px" }
                        },
                        [_vm._v("Eleventh Hour For")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-white font-poynter text-3xl lg:text-5xl mb-0"
                        },
                        [_vm._v("Aestheticians & Cosmetic")]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-py--sm" }, [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16"
                    },
                    [
                      _c("p", { staticClass: "h2 h2--copper text-center" }, [
                        _vm._v("Find new customers wherever you are")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Work from"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" multiple salons?")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Speak to us and let us set your business up to have multiple locations."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("List your accreditations"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" & certifications")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Let customers know you're safe and qualified to practice."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Make it"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" official")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Choose to take a deposit from new clients (or all if you'd prefer). Simply add this as a requirement and process the payment outside of our platform."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Optional approval process"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" & 2-way ratings")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Choose to automatically accept bookings or review them first. Our 2-way ratings help to give businesses and customers peace of mind."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("0% commission..."),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" ever!")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Our flat monthly fee covers our cut. With us, you keep everything and process your own payments."
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab == "freelance"
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("section", [
              _c(
                "div",
                { staticClass: "c-wf-tab-content-hero" },
                [
                  _vm._l(_vm.tabs[_vm.active_tab_index].images, function(
                    image,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "wf-fade-slide",
                      class: {
                        "wf-fade-slide--active": _vm.sliderActiveSlide == index
                      },
                      style: {
                        background:
                          "center / cover no-repeat url(" + image + ")"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-wf-tab-content-hero__content container mx-auto"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "uppercase font-bold text-white",
                          staticStyle: { "letter-spacing": "4px" }
                        },
                        [_vm._v("Eleventh Hour For")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-white font-poynter text-3xl lg:text-5xl mb-0"
                        },
                        [_vm._v("Freelance & Mobile Professionals")]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-py--sm" }, [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16"
                    },
                    [
                      _c("p", { staticClass: "h2 h2--copper text-center" }, [
                        _vm._v(
                          "Hair, beauty, makeup, aesthetics - run your business, your way"
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [_vm._v("Stay safe")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Make use of our optional pre-booking approvals & 2-way ratings to check customers before appointments are confirmed."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Make your money"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" work for you")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Our solo packages start at £15.99, and because you process all payments, we don't take anything else."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Operate from your"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" own home?")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Choose to hide your location from new customers until appointments are approved."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Work your diary"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" around you")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "There's no minimum number of appointments to be fulfilled or set hours - choose how many appointments you list."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("List your accreditations"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" & certifications")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Let customers know you're safe and qualified to practice."
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab == "spas"
        ? _c("transition", { attrs: { name: "fade" } }, [
            _c("section", [
              _c(
                "div",
                { staticClass: "c-wf-tab-content-hero" },
                [
                  _vm._l(_vm.tabs[_vm.active_tab_index].images, function(
                    image,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "wf-fade-slide",
                      class: {
                        "wf-fade-slide--active": _vm.sliderActiveSlide == index
                      },
                      style: {
                        background:
                          "center / cover no-repeat url(" + image + ")"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-wf-tab-content-hero__content container mx-auto"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "uppercase font-bold text-white",
                          staticStyle: { "letter-spacing": "4px" }
                        },
                        [_vm._v("Eleventh Hour For")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-white font-poynter text-3xl lg:text-5xl mb-0"
                        },
                        [_vm._v("Spas")]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-py--sm" }, [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16"
                    },
                    [
                      _c("p", { staticClass: "h2 h2--copper text-center" }, [
                        _vm._v(
                          "Find new customers without discounting your services"
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Unlike other platforms,"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" you set the price")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "What you do has value. That's why we'll never ever push you to discount or reduce your service cost unless you want to. "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Perfect for those"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" rainy days")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Sometimes, your customers don't know they need you until the last minute. Use us to find new customers and fill last-minute availability."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Say NO to"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" sneaky commission!")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "With us, you pay one fixed monthly cost and never ever any commission (even on new customers)!"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("Make it"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" official")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Choose to take a deposit from new clients (or all if you'd prefer). Simply add this as a requirement and process the payment outside of our platform."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4"
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle text-2xl text-teal-400 mb-2"
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-poynter font-bold text-xl text-grey-1000 mb-4"
                            },
                            [
                              _vm._v("A flexible platform"),
                              _c("br", { staticClass: "hidden md:block" }),
                              _vm._v(" perfect for spa days")
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Adjust and amend treatment times to allow clients to move between professionals if required, and to maximise each persons diary"
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }