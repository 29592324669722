<template>
    <div class="relative" v-on-clickaway="() => { interacting = false }">
        <div class="c-filter-option c-filter-option--active" @click="interacting = !interacting">
            <span class="c-filter-option__text" role="button">{{ selected_label }}</span>

        </div>

        <div class="c-filter-option__drop-down" v-show="interacting">
            <div>
                <div v-for="(option, index) in search_filter.options[options]" :key="index" class="c-form__label-as-btn">

                    <!-- Radio -->
                    <input type="radio" :id="option.label+index" v-model="search_filter.fields[selected]" :value="option.value">

                    <label :for="option.label+index">{{ option.label }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            interacting: false
        }
    },

    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        options: {
            type: String //refers to $store search_filter.options.XXXX
        }
    },

    computed: {
        ...mapState(['search_filter']),
        selected_label() {
            return this.search_filter.options[this.options].find(item => item.value == this.search_filter.fields[this.selected]).label;
        },


    },

    methods: {
        clearSelected() {

        }
    }


}
</script>
