var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-center" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.categories.length
          ? _c(
              "ul",
              { staticClass: "c-blog-filter -mx-4" },
              [
                _c("li", { staticClass: "px-4" }, [
                  _c(
                    "a",
                    {
                      staticClass: "c-blog-filter__item",
                      class: {
                        "c-blog-filter__item--active": !_vm.requestedCategory
                      },
                      attrs: { href: _vm.blogBaseUrl }
                    },
                    [_vm._v("All")]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.categories, function(cat, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "px-4 flex-shrink-0" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "c-blog-filter__item",
                          class: {
                            "c-blog-filter__item--active":
                              _vm.requestedCategory == cat.slug
                          },
                          attrs: { href: _vm.catBaseUrl + "/" + cat.slug }
                        },
                        [_vm._v(_vm._s(cat.name))]
                      )
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }