<template>
    <div>
        <div v-if="post && post.post_header_style == 'one'">
            <div class="c-blog-hero c-blog-hero--text-only">
                <div class="container mx-auto">
                    <div class="w-full md:w-10/12 xl:w-8/12 flex flex-col items-center text-center mx-auto">
                        <ul class="flex flex-wrap items-center justify-center -mx-2 mb-2">
                            <li
                                v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                class="uppercase font-semibold px-2 text-copper-400"
                            >{{ category.cat_name }}</li>
                        </ul>
                        <h1 v-html="post.post_title" class="text-black" style="margin-bottom:0;"></h1>
                        <span class="block text-base font-normal italic mb-6">by {{ post.post_author.fname}} {{ post.post_author.sname}}, {{ post.post_author.job_role}}</span>
                        <span class="block text-base font-normal text-copper-400">{{ moment(post.post_date).format('Do MMMM YYYY') }}</span>
                    </div>
                </div>
            </div>

            <div class="container mx-auto pt-10 mb-16">
                <span class="block text-center text-base font-semibold text-grey-1000 uppercase mb-2">Share The Love</span>
                <div class="flex justify-center -mx-2">
                    <span class="px-2">
                        <a
                            :href="'https://twitter.com/intent/tweet?url='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon  rounded-social-icon--twitter"
                        >
                            <i class="fab fa-twitter text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'https://www.facebook.com/sharer/sharer.php?u='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--fb"
                        >
                            <i class="fab fa-facebook-f text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'whatsapp://send?text='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--whatsapp">
                            <i class="fab fa-whatsapp text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'mailto:?body='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--email">
                            <i class="far fa-envelope text-white"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div v-if="post.post_featured_image" class="container mx-auto">
                <div class="mb-16 md:mb-20">
                    <img :src="post.post_featured_image" class="mx-auto">
                </div>
            </div>
        </div>

        <div v-if="post && post.post_header_style == 'two'">
            <div
                :style="{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.9) 100%), center / cover no-repeat url('+(!post.post_featured_image ? '/images/blog-fallback.svg' : post.post_featured_image )+')' }"
                class="c-blog-hero c-blog-hero--image">
                <div class="container mx-auto">
                    <div class="w-full md:w-10/12 xl:w-8/12 flex flex-col items-center text-white text-center mx-auto">
                        <ul class="flex flex-wrap items-center justify-center -mx-2 mb-2">
                            <li
                                v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                class="uppercase font-semibold px-2"
                            >{{ category.cat_name }}</li>
                        </ul>
                        <h1 v-html="post.post_title" class="text-white" style="margin-bottom:0;"></h1>
                        <span class="block text-base font-normal italic mb-6">by {{ post.post_author.fname}} {{ post.post_author.sname}}, {{ post.post_author.job_role}}</span>
                        <span class="block text-base font-normal">{{ moment(post.post_date).format('Do MMMM YYYY') }}</span>
                    </div>
                </div>
            </div>

            <div class="container mx-auto pt-10 pb-12 3xl:pb-16">
                <span class="block text-center text-base font-semibold text-grey-1000 uppercase mb-2">Share The Love</span>
                <div class="flex justify-center -mx-2">
                    <span class="px-2">
                        <a
                            :href="'https://twitter.com/intent/tweet?url='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon  rounded-social-icon--twitter"
                        >
                            <i class="fab fa-twitter text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'https://www.facebook.com/sharer/sharer.php?u='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--fb"
                        >
                            <i class="fab fa-facebook-f text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'whatsapp://send?text='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--whatsapp">
                            <i class="fab fa-whatsapp text-white"></i>
                        </a>
                    </span>
                    <span class="px-2">
                        <a
                            :href="'mailto:?body='+blogBaseUrl+'/'+post.post_name"
                            target="_blank"
                            class="rounded-social-icon rounded-social-icon--email">
                            <i class="far fa-envelope text-white"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>

        <div v-if="post && post.post_header_style == 'three'">
            <div
                :class="post.post_theme_colour"
                class="c-blog-hero c-blog-hero--two-col text-white mb-16">
                <div
                    :style="{ background: 'center / cover no-repeat url('+(!post.post_featured_image ? '/images/blog-fallback.svg' : post.post_featured_image )+')' }"
                    class="lg:h-full lg:w-6/12 lg:absolute lg:top-0 lg:left-0 px-8" style="min-height:275px;">
                </div>
                <div class="container mx-auto">
                    <div class="lg:flex lg:justify-end lg:items-center -mx-8">
                        <div class="w-full md:w-10/12 lg:w-6/12 px-8 mx-auto lg:mx-0">
                            <div class="w-full xl:w-10/12 md:px-8 flex flex-col items-center text-center py-8 mx-auto">
                                <ul class="flex flex-wrap items-center justify-center -mx-2 mb-2">
                                    <li
                                        v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                        class="uppercase font-semibold px-2"
                                    >{{ category.cat_name }}</li>
                                </ul>
                                <h1 v-html="post.post_title" class="text-white" style="margin-bottom:0;"></h1>
                                <span class="block text-base font-normal italic mb-6">by {{ post.post_author.fname}} {{ post.post_author.sname}}, {{ post.post_author.job_role}}</span>
                                <span class="block text-base font-normal">{{ moment(post.post_date).format('Do MMMM YYYY') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div class="section-pb--md">
            <div class="container mx-auto">
                <div class="w-full md:w-10/12 lg:w-8/12 mx-auto">
                    <div v-if="post" v-html="post.post_content" class="flowing-content blog-content"></div>

                    <!-- Gallery trigger -->
                    <div
                        v-if="post && post.gallery.length"
                        @click="initBlogSingleGallery({'slides': post.gallery, 'title':post.gallery_title})"
                        :style="{ background: 'center / cover no-repeat url('+post.gallery[0].image.url+')' }"
                        class="c-blog-gallery-preview"
                        role="button"
                        aria-label="Open gallery"
                    >
                        <div class="c-blog-gallery-preview__count">
                            <p class="mb-0"><span class="block">{{ post.gallery.length }}</span> {{ (post.gallery.length > 1 ? 'Images' : 'Image') }}</p>
                        </div>
                    </div>
                    <!-- / Gallery trigger -->
                </div>
            </div>
        </div>
        <!-- / Content -->


        <!-- Newsletter signup -->
        <div class="container mx-auto section-pb--md">
            <div class="w-full md:w-10/12 lg:w-8/12 mx-auto c-blog-signup-form">
                <newsletter-signup header-size="h3"></newsletter-signup>
            </div>
        </div>
        <!-- / Newsletter signup -->

        <!-- More blogs -->
        <transition name="fade">
            <div
                v-if="otherPosts.length"
                class="section-py--sm px-8 bg-grey-100">
                <h3 class="lg:text-center mb-12">More from the blog</h3>

                <div class="flex flex-wrap -mt-4 -mx-2">
                    <div
                        v-for="(post, index) in otherPosts" :key="index"
                        class="w-full lg:w-6/12 xl:w-4/12 mt-4 px-2 flex flex-wrap"
                    >
                        <div
                            :style="{ background: 'center / cover no-repeat url('+(!post.post_featured_image ? '/images/blog-fallback.svg' : post.post_featured_image )+')' }"
                            style="min-height:150px;"
                            class="w-full md:w-4/12"
                            role="image"
                        ></div>
                        <div class="w-full md:w-8/12 p-4 flex flex-col bg-white">
                            <ul class="flex flex-wrap items-center justify-start -mx-2 mb-2">
                                <li
                                    v-for="(category, catIndex) in post.post_categories" :key="catIndex"
                                    class="uppercase font-semibold px-2 text-copper-400"
                                >
                                    <a  :href="catBaseUrl+'/'+category.slug" class="hover:underline">
                                        {{ category.cat_name }}
                                    </a>
                                </li>
                            </ul>
                            <a :href="blogBaseUrl+'/'+post.post_name" v-html="clipString(post.post_title, 50)" class="block text-xl font-poynter text-grey-1000 hover:underline"></a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- / More blogs -->
    </div>
</template>

<script>
import moment from 'moment';
import { FETCH_BLOG, FETCH_OTHER_BLOGS } from '@js/endpoints';
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
    data() {
        return {
            moment,
            post: {
                gallery: []
            },
            otherPostsToShow: 6,
            otherPosts: [],
        }
    },

    props: {
        postName: {
            required: true,
            type: String
        },

        blogBaseUrl: {
            type: String,
            required: true
        },

        catBaseUrl: {
            type: String,
            required: true
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
        }),
    },

    mounted() {
        this.compilePostContent(this.postName)
    },

    watch: {
        post: function() {
            this.compileOtherPosts(this.post.post_id)
        },
    },

    methods: {
        compilePostContent(postName) {
           axios.get(FETCH_BLOG(postName)).then(res => {
                this.post = res.data;
            })
        },

        compileOtherPosts(currentPostID) {
            axios.get(FETCH_OTHER_BLOGS(currentPostID, this.otherPostsToShow)).then(res => {
                this.otherPosts = res.data;
            })
        },

        clipString(string, maxChars) {
            return (string.length > maxChars ? string.substr(0, maxChars)+'...' : string);
        },

        ...mapMutations(['mutateOverlayVisibility']),
        ...mapActions(['initBlogSingleGallery']),
    }
}
</script>

<style lang="scss" scoped>
.c-blog-hero {
    &--image {
        @apply flex items-end py-8;
         min-height: 40vh;

        @screen md {
            min-height: 35vh;
        }

        @screen lg {
            min-height: 60vh;
        }

        @screen 3xl {
            min-height: 45vh;
        }
    }

    &--text-only {
        @apply pt-16;
    }

    &--two-col {
        @screen md {
            min-height: 35vh;
        }

        @screen lg {
            @apply relative flex items-center;
            min-height: 60vh;
        }

        @screen 3xl {
            min-height: 45vh;
        }
    }

    h1 {
        @media(max-width: 767px) {
            font-size: 2.5rem;
            line-height: 2.75rem;
        }
    }
}

// ::v-deep .c-further-reading-item {
//     transition: 0.4s ease;

//     &:hover {
//         transform: scale(1.05);
//         box-shadow: 0 0 10px 6px rgba(169, 169, 169, 0.3);
//         z-index: 1;
//     }
// }

::v-deep .c-blog-signup-form {
    form {
        @apply -mx-2;
    }

    .signup-col {
        @apply mb-4 px-2;

        @screen lg {
            @apply w-6/12 mb-0;
        }
    }

    input {
        @apply mb-0;
    }
}

::v-deep .blog-content {
    @apply text-grey-400;
}

::v-deep .text-lead {
    @apply text-xl text-black font-normal;
}

::v-deep .c-blog-image-split {
    @apply flex flex-wrap mt-10 mb-12 -mx-1;

    &__col {
        @apply w-full mt-2 px-1;

        @screen md {
            @apply w-6/12;
        }
    }
}

::v-deep .c-blog-quote {
    @apply my-12;

    &__body {
        @apply block font-normal;
    }

    &__author {
        @apply italic font-bold;
    }
}

::v-deep .c-blog-highlight-box {
    @apply border-2 border-copper-400 mb-8 p-4;

    @screen md {
        @apply p-8;
    }

    &__content {
        @apply text-copper-400 font-normal mb-0;
    }
}

.c-blog-gallery-preview {
    @apply flex items-center justify-center py-6;
    height: 200px;

    &__count {
        @apply flex items-center justify-center text-center text-white font-poynter text-xl font-bold bg-copper-400 rounded-full p-4;
        height: 150px;
        width: 150px;

        span {
            @apply font-light;
            font-size: 60px;
            line-height: 60px;
        }
    }
}
</style>
