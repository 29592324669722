var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "c-image-uploader__image cursor-pointer",
        class: {
          "c-image-uploader__image--processing": _vm.processing
        },
        style: _vm.background
      },
      [
        _c(
          "div",
          {
            staticClass: "button-wrapper flex flex-row",
            class: {
              "justify-between": _vm.preview,
              "justify-center": !_vm.preview
            }
          },
          [
            !_vm.disabled
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.preview,
                        expression: "!preview"
                      }
                    ],
                    staticClass:
                      "upld-btn-wrapper flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white",
                    style: _vm.circleStyle
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.preview,
                          expression: "!preview"
                        }
                      ],
                      ref: "upload_field",
                      staticClass:
                        " hidden-img-input w-full h-full opacity-0 bottom-0 left-0  absolute cursor-pointer",
                      attrs: {
                        type: "file",
                        disabled: _vm.disabled,
                        accept: "image/*",
                        name: _vm.name
                      },
                      on: {
                        change: function($event) {
                          _vm.generatePreview($event)
                          _vm.preventNavigateAway($event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.preview
                      ? _c("i", {
                          staticClass:
                            "profile-img-btn fas fa-pencil-alt text-sm"
                        })
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.preview && !_vm.saving
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white red",
                    class: { "pointer-events-none": !_vm.preview },
                    style: _vm.circleStyle,
                    on: { click: _vm.clearField }
                  },
                  [
                    _vm.preview
                      ? _c("i", {
                          staticClass:
                            "profile-img-btn fal fa-trash-alt text-sm cursor-pointer"
                        })
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.preview && !_vm.saving
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white green",
                    class: { "pointer-events-none": !_vm.preview },
                    style: _vm.circleStyle,
                    on: { click: _vm.savePicture }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "profile-img-btn fal fa-save text-sm cursor-pointer"
                    })
                  ]
                )
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }