<template>
    <div>
        <div class="c-form__radio-as-toggle">
            <input type="radio" name="view-type" id="list" value="list" v-model="selected">
            <label for="list">List</label>

            <input type="radio" name="view-type" id="map" value="map" v-model="selected">
            <label for="map">Map</label>
        </div>

        <div class="md:hidden">
            <input type="checkbox" class="hidden" id="mobile-view-toggle" v-model="selected" true-value="list" false-value="map">
            <label for="mobile-view-toggle" class="text-grey-1000 uppercase font-semibold text-base cursor-pointer">{{ selected == "map" ? "List" : "Map"}} View</label>
        </div>
    </div>


</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['value'],
    data() {
        return {
            selected: this.value,
        }
    },

    watch: {
        selected(val) {
            this.$emit('input', val);
        }
    }
}
</script>

<style lang="scss" scoped>
    .c-form__radio-as-toggle {
        @apply mb-0;
        display:none;

        //Regular tailwind 'hidden md:block' not working
        @screen md {
            display: flex;
        }
    }



</style>
