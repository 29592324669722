var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gmap-map",
    {
      ref: "searchMap",
      staticClass: "w-full",
      staticStyle: { height: "600px" },
      attrs: {
        center: _vm.centre,
        "map-type-id": "terrain",
        zoom: 7,
        options: {
          disableDefaultUi: true,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        }
      }
    },
    _vm._l(_vm.markers, function(marker, index) {
      return _c("gmap-marker", {
        key: index,
        attrs: {
          position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }
        },
        on: {
          click: function($event) {
            return _vm.setCentre(marker)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }