<template>
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Create</th>
                <th>Read</th>
                <th>Update</th>
                <th>Delete</th>
            </tr>

            <tr v-for="(permission, index) in permissions" :key="index" class="border-b">
                <td class="border-r">{{ permission.friendly_name }} <input type="hidden" :name="`permission[${permission.id}][permission]`" value="true"></td>
                <td class="border-r" align="center">
                    <div class="c-simulated-checkbox">
                        <input type="checkbox" :name="`permission[${permission.id}][C]`" :id="`permission[${permission.id}][C]`" v-bind:checked="hasPermission(permission.id, 'C')" class="c-simulated-checkbox__input">
                        <label :for="`permission[${permission.id}][C]`" class="c-simulated-checkbox__label"></label>
                    </div>
                </td>
                <td class="border-r" align="center">
                    <div class="c-simulated-checkbox">
                        <input type="checkbox" :name="`permission[${permission.id}][R]`" :id="`permission[${permission.id}][R]`" v-bind:checked="hasPermission(permission.id, 'R')" class="c-simulated-checkbox__input">
                        <label :for="`permission[${permission.id}][R]`" class="c-simulated-checkbox__label"></label>
                    </div>
                </td>
                <td class="border-r" align="center">
                    <div class="c-simulated-checkbox">
                        <input type="checkbox" :name="`permission[${permission.id}][U]`" :id="`permission[${permission.id}][U]`" v-bind:checked="hasPermission(permission.id, 'U')" class="c-simulated-checkbox__input">
                        <label :for="`permission[${permission.id}][U]`" class="c-simulated-checkbox__label"></label>
                    </div>
                </td>
                <td align="center">
                    <div class="c-simulated-checkbox">
                        <input type="checkbox" :name="`permission[${permission.id}][D]`" :id="`permission[${permission.id}][D]`" v-bind:checked="hasPermission(permission.id, 'D')" class="c-simulated-checkbox__input">
                        <label :for="`permission[${permission.id}][D]`" class="c-simulated-checkbox__label"></label>
                    </div>
                </td>
            </tr>
        </thead>

    </table>
</template>


<script>
export default {
    props: {
        permissions: {
            required: true,
            type: Array
        },

        checked: {
            required: false,
            type: Array,
            default: []
        }
    },

    methods: {
        hasPermission(permission, type) {
            return this.checked.find(array => {
                return array.permission_id == permission && array[type] == 1;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    table {
        @apply block overflow-x-auto;
        max-width: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    
    td,
    th {
        @apply p-4;
    }

    .c-simulated-checkbox {
        @apply cursor-auto;
        
        &__label {
            @apply inline-block mb-0;
            padding-left: 20px;
            height: 20px;
        }
    }

    tr:last-of-type {
        border-bottom: none;
    }
</style>
