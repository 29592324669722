var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.post && _vm.post.post_header_style == "one"
        ? _c("div", [
            _c("div", { staticClass: "c-blog-hero c-blog-hero--text-only" }, [
              _c("div", { staticClass: "container mx-auto" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full md:w-10/12 xl:w-8/12 flex flex-col items-center text-center mx-auto"
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "flex flex-wrap items-center justify-center -mx-2 mb-2"
                      },
                      _vm._l(_vm.post.post_categories, function(
                        category,
                        catIndex
                      ) {
                        return _c(
                          "li",
                          {
                            key: catIndex,
                            staticClass:
                              "uppercase font-semibold px-2 text-copper-400"
                          },
                          [_vm._v(_vm._s(category.cat_name))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("h1", {
                      staticClass: "text-black",
                      staticStyle: { "margin-bottom": "0" },
                      domProps: { innerHTML: _vm._s(_vm.post.post_title) }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "block text-base font-normal italic mb-6"
                      },
                      [
                        _vm._v(
                          "by " +
                            _vm._s(_vm.post.post_author.fname) +
                            " " +
                            _vm._s(_vm.post.post_author.sname) +
                            ", " +
                            _vm._s(_vm.post.post_author.job_role)
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "block text-base font-normal text-copper-400"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm
                              .moment(_vm.post.post_date)
                              .format("Do MMMM YYYY")
                          )
                        )
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container mx-auto pt-10 mb-16" }, [
              _c(
                "span",
                {
                  staticClass:
                    "block text-center text-base font-semibold text-grey-1000 uppercase mb-2"
                },
                [_vm._v("Share The Love")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex justify-center -mx-2" }, [
                _c("span", { staticClass: "px-2" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "rounded-social-icon  rounded-social-icon--twitter",
                      attrs: {
                        href:
                          "https://twitter.com/intent/tweet?url=" +
                          _vm.blogBaseUrl +
                          "/" +
                          _vm.post.post_name,
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "fab fa-twitter text-white" })]
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "px-2" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "rounded-social-icon rounded-social-icon--fb",
                      attrs: {
                        href:
                          "https://www.facebook.com/sharer/sharer.php?u=" +
                          _vm.blogBaseUrl +
                          "/" +
                          _vm.post.post_name,
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "fab fa-facebook-f text-white" })]
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "px-2" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "rounded-social-icon rounded-social-icon--whatsapp",
                      attrs: {
                        href:
                          "whatsapp://send?text=" +
                          _vm.blogBaseUrl +
                          "/" +
                          _vm.post.post_name,
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "fab fa-whatsapp text-white" })]
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "px-2" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "rounded-social-icon rounded-social-icon--email",
                      attrs: {
                        href:
                          "mailto:?body=" +
                          _vm.blogBaseUrl +
                          "/" +
                          _vm.post.post_name,
                        target: "_blank"
                      }
                    },
                    [_c("i", { staticClass: "far fa-envelope text-white" })]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.post.post_featured_image
              ? _c("div", { staticClass: "container mx-auto" }, [
                  _c("div", { staticClass: "mb-16 md:mb-20" }, [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: { src: _vm.post.post_featured_image }
                    })
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.post && _vm.post.post_header_style == "two"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "c-blog-hero c-blog-hero--image",
                style: {
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.9) 100%), center / cover no-repeat url(" +
                    (!_vm.post.post_featured_image
                      ? "/images/blog-fallback.svg"
                      : _vm.post.post_featured_image) +
                    ")"
                }
              },
              [
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full md:w-10/12 xl:w-8/12 flex flex-col items-center text-white text-center mx-auto"
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "flex flex-wrap items-center justify-center -mx-2 mb-2"
                        },
                        _vm._l(_vm.post.post_categories, function(
                          category,
                          catIndex
                        ) {
                          return _c(
                            "li",
                            {
                              key: catIndex,
                              staticClass: "uppercase font-semibold px-2"
                            },
                            [_vm._v(_vm._s(category.cat_name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("h1", {
                        staticClass: "text-white",
                        staticStyle: { "margin-bottom": "0" },
                        domProps: { innerHTML: _vm._s(_vm.post.post_title) }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "block text-base font-normal italic mb-6"
                        },
                        [
                          _vm._v(
                            "by " +
                              _vm._s(_vm.post.post_author.fname) +
                              " " +
                              _vm._s(_vm.post.post_author.sname) +
                              ", " +
                              _vm._s(_vm.post.post_author.job_role)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "block text-base font-normal" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.post.post_date)
                                .format("Do MMMM YYYY")
                            )
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container mx-auto pt-10 pb-12 3xl:pb-16" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "block text-center text-base font-semibold text-grey-1000 uppercase mb-2"
                  },
                  [_vm._v("Share The Love")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex justify-center -mx-2" }, [
                  _c("span", { staticClass: "px-2" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "rounded-social-icon  rounded-social-icon--twitter",
                        attrs: {
                          href:
                            "https://twitter.com/intent/tweet?url=" +
                            _vm.blogBaseUrl +
                            "/" +
                            _vm.post.post_name,
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "fab fa-twitter text-white" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "px-2" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "rounded-social-icon rounded-social-icon--fb",
                        attrs: {
                          href:
                            "https://www.facebook.com/sharer/sharer.php?u=" +
                            _vm.blogBaseUrl +
                            "/" +
                            _vm.post.post_name,
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "fab fa-facebook-f text-white" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "px-2" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "rounded-social-icon rounded-social-icon--whatsapp",
                        attrs: {
                          href:
                            "whatsapp://send?text=" +
                            _vm.blogBaseUrl +
                            "/" +
                            _vm.post.post_name,
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "fab fa-whatsapp text-white" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "px-2" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "rounded-social-icon rounded-social-icon--email",
                        attrs: {
                          href:
                            "mailto:?body=" +
                            _vm.blogBaseUrl +
                            "/" +
                            _vm.post.post_name,
                          target: "_blank"
                        }
                      },
                      [_c("i", { staticClass: "far fa-envelope text-white" })]
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.post && _vm.post.post_header_style == "three"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "c-blog-hero c-blog-hero--two-col text-white mb-16",
                class: _vm.post.post_theme_colour
              },
              [
                _c("div", {
                  staticClass:
                    "lg:h-full lg:w-6/12 lg:absolute lg:top-0 lg:left-0 px-8",
                  staticStyle: { "min-height": "275px" },
                  style: {
                    background:
                      "center / cover no-repeat url(" +
                      (!_vm.post.post_featured_image
                        ? "/images/blog-fallback.svg"
                        : _vm.post.post_featured_image) +
                      ")"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "container mx-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lg:flex lg:justify-end lg:items-center -mx-8"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-10/12 lg:w-6/12 px-8 mx-auto lg:mx-0"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full xl:w-10/12 md:px-8 flex flex-col items-center text-center py-8 mx-auto"
                            },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "flex flex-wrap items-center justify-center -mx-2 mb-2"
                                },
                                _vm._l(_vm.post.post_categories, function(
                                  category,
                                  catIndex
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: catIndex,
                                      staticClass:
                                        "uppercase font-semibold px-2"
                                    },
                                    [_vm._v(_vm._s(category.cat_name))]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("h1", {
                                staticClass: "text-white",
                                staticStyle: { "margin-bottom": "0" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.post.post_title)
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "block text-base font-normal italic mb-6"
                                },
                                [
                                  _vm._v(
                                    "by " +
                                      _vm._s(_vm.post.post_author.fname) +
                                      " " +
                                      _vm._s(_vm.post.post_author.sname) +
                                      ", " +
                                      _vm._s(_vm.post.post_author.job_role)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "block text-base font-normal" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(_vm.post.post_date)
                                        .format("Do MMMM YYYY")
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "section-pb--md" }, [
        _c("div", { staticClass: "container mx-auto" }, [
          _c("div", { staticClass: "w-full md:w-10/12 lg:w-8/12 mx-auto" }, [
            _vm.post
              ? _c("div", {
                  staticClass: "flowing-content blog-content",
                  domProps: { innerHTML: _vm._s(_vm.post.post_content) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.post && _vm.post.gallery.length
              ? _c(
                  "div",
                  {
                    staticClass: "c-blog-gallery-preview",
                    style: {
                      background:
                        "center / cover no-repeat url(" +
                        _vm.post.gallery[0].image.url +
                        ")"
                    },
                    attrs: { role: "button", "aria-label": "Open gallery" },
                    on: {
                      click: function($event) {
                        return _vm.initBlogSingleGallery({
                          slides: _vm.post.gallery,
                          title: _vm.post.gallery_title
                        })
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "c-blog-gallery-preview__count" },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _c("span", { staticClass: "block" }, [
                            _vm._v(_vm._s(_vm.post.gallery.length))
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.post.gallery.length > 1 ? "Images" : "Image"
                              )
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container mx-auto section-pb--md" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full md:w-10/12 lg:w-8/12 mx-auto c-blog-signup-form"
          },
          [_c("newsletter-signup", { attrs: { "header-size": "h3" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.otherPosts.length
          ? _c("div", { staticClass: "section-py--sm px-8 bg-grey-100" }, [
              _c("h3", { staticClass: "lg:text-center mb-12" }, [
                _vm._v("More from the blog")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap -mt-4 -mx-2" },
                _vm._l(_vm.otherPosts, function(post, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "w-full lg:w-6/12 xl:w-4/12 mt-4 px-2 flex flex-wrap"
                    },
                    [
                      _c("div", {
                        staticClass: "w-full md:w-4/12",
                        staticStyle: { "min-height": "150px" },
                        style: {
                          background:
                            "center / cover no-repeat url(" +
                            (!post.post_featured_image
                              ? "/images/blog-fallback.svg"
                              : post.post_featured_image) +
                            ")"
                        },
                        attrs: { role: "image" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full md:w-8/12 p-4 flex flex-col bg-white"
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "flex flex-wrap items-center justify-start -mx-2 mb-2"
                            },
                            _vm._l(post.post_categories, function(
                              category,
                              catIndex
                            ) {
                              return _c(
                                "li",
                                {
                                  key: catIndex,
                                  staticClass:
                                    "uppercase font-semibold px-2 text-copper-400"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "hover:underline",
                                      attrs: {
                                        href:
                                          _vm.catBaseUrl + "/" + category.slug
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(category.cat_name) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("a", {
                            staticClass:
                              "block text-xl font-poynter text-grey-1000 hover:underline",
                            attrs: {
                              href: _vm.blogBaseUrl + "/" + post.post_name
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.clipString(post.post_title, 50)
                              )
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }