var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs-wrapper" },
    _vm._l(_vm.extracted_industries, function(option, index) {
      return _c("div", { key: index, staticClass: "c-horizontal-tab" }, [
        _c(
          "div",
          {
            staticClass: "c-horizontal-tab__title",
            class: { active: option.title == _vm.active_parent },
            attrs: { role: "button" },
            on: {
              click: function($event) {
                return _vm.setActive(option)
              }
            }
          },
          [
            _c("span", { staticClass: "c-horizontal-tab__title-wrapper" }, [
              _c(
                "div",
                { staticClass: "c-rounded-icon c-rounded-icon--small mr-3" },
                [
                  _c("img", {
                    staticClass: "c-rounded-icon__icon",
                    staticStyle: { "max-width": "70%" },
                    attrs: {
                      src: option.icon || "/images/icons/industries/other.svg"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text-black mb-0 font-poynter font-bold" },
                [_vm._v(_vm._s(option.title))]
              )
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "fas",
              class: {
                "fa-chevron-down": !(option.title === _vm.active_parent),
                "fa-chevron-up": option.title === _vm.active_parent
              }
            })
          ]
        ),
        _vm._v(" "),
        option.title == _vm.active_parent
          ? _c("div", { staticClass: "c-horizontal-tab__content" }, [
              _c(
                "ul",
                { staticClass: "mb-0 -mx-3 flex flex-wrap" },
                _vm._l(
                  _vm.options.filter(function(option) {
                    return option.industry == _vm.active_parent
                  }),
                  function(service, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass:
                          "w-full md:w-6/12 lg:w-4/12 font-normal text-grey-1000 px-3 mb-1"
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(service.name) +
                            "\n                "
                        )
                      ]
                    )
                  }
                ),
                0
              )
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }