<template>
    <div class="flex justify-center">
        <transition name="fade">
            <ul 
                v-if="categories.length"
                class="c-blog-filter -mx-4">
                <li class="px-4">
                    <a 
                        :href="blogBaseUrl" 
                        class="c-blog-filter__item"
                        :class="{ 'c-blog-filter__item--active' : !requestedCategory}"
                    >All</a>
                </li>
                <li v-for="(cat, index) in categories" :key="index" class="px-4 flex-shrink-0">
                    <a 
                        :href="catBaseUrl+'/'+cat.slug" 
                        :class="{ 'c-blog-filter__item--active' : requestedCategory == cat.slug}"
                        class="c-blog-filter__item"
                    >{{ cat.name }}</a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
    import { FETCH_BLOG_CATEGORIES } from '@js/endpoints';

    export default {
        data() {
            return {
                categories: [],
            }
        },

        props: {
            requestedCategory: {
                type: String,
                required: false
            },

            blogBaseUrl: {
                type: String,
                required: true
            },

            catBaseUrl: {
                type: String,
                required: true
            }
        },

        mounted() {
            this.compileCategories()
        },

        methods: {
            compileCategories() {
                axios.get(FETCH_BLOG_CATEGORIES()).then(res => {
                    this.categories = res.data;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c-blog-filter {
        @apply flex whitespace-no-wrap overflow-y-auto mb-0;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        max-width: 100%;
        padding-bottom: 7px;

        &__item {
            @apply relative block uppercase font-semibold text-base text-black;
            transition: 0.4s ease;

            &::before {
                @apply absolute left-0 w-full bg-copper-400 opacity-0;
                content: "";
                bottom: -6px;
                height: 2px;
                transition: 0.4s ease;
            }

            &:hover,
            &--active {
                @apply text-copper-400;

                &::before {
                    @apply opacity-100;
                }
            }
        }
    }
</style>