import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import VueCookies from 'vue-cookies';
Vue.use(Vuex, VueCookies);
import { LOGIN_PATH, RESERVE_AVAILABILITY, UNRESERVE_AVAILABILITY, CAN_BOOK, PASSWORD_RESET_PATH, PASSWORD_REQUEST_PATH, SIGNUP_PATH } from '@js/endpoints';
export const store = new Vuex.Store({
    state: {
        components: {
            loginForm: () => import(/* webpackChunkName: "components/LoginForm" */ "@js/components/LoginForm"),
            resetPassword: () => import(/* webpackChunkName: "components/ResetPassword" */ "@js/components/ResetPassword"),
            vimeoVideo: () => import(/* webpackChunkName: "components/VimeoVideo" */ "@js/components/VimeoVideo"),
            genericModal: () => import(/* webpackChunkName: "components/GenericModal" */ "@js/components/GenericModal"),
            mobileFilters: () => import(/* webpackChunkName: "components/MobileFilters" */ "@js/components/MobileFilters"),
            appointmentCancel: () => import(/* webpackChunkName: "components/appointmentCancel" */ "@js/components/AppointmentCancel"),
            demoRequest: () => import(/* webpackChunkName: "components/demoRequest" */ "@js/components/DemoRequest"),


            blogSingleGallery: () => import(/* webpackChunkName: "components/BlogSingleGallery" */ "@js/components/blog/BlogSingleGallery"),
        },

        component_params: {
            loginForm: {
                endpoint: LOGIN_PATH,
                links: {
                    signup: SIGNUP_PATH,
                    passwordReset: PASSWORD_REQUEST_PATH
                }
            },

            resetPassword: {
                endpoint: PASSWORD_RESET_PATH
            },

            vimeoVideo: {
                iframeSrc: null
            },

            genericModal: {
                title: null,
                body: null
            },

            appointmentCancel: {
                id: null,
                service_name: null,
                service_provider: null,
                business_name: null
            }
        },

        overlay: {
            visibility: false,
            modal: {
                content: null,
                modifierClass: null
            }
        },

        booker: {
            visibility: $cookies.get('booking') ? true : false,
            details: $cookies.get('booking'),
            selected: $cookies.get('booking_selected') || {
                availability_id: null,
                service: null,
                time: null,
                stage: 'booking' // ['booking','confirming','confirmed'] (possibly more added)
            },

            appointment:{},

            preselected: {
                service: null,
            },

        },

        availability_drawer: {
            visibility: false,
            business_id: null
        },


        service_drawer: {
            visibility: false,
            business_id: null,
            availability: null,
        },

        quick_look_drawer: {
            visibility: false,
            type: 'business',
            id: null
        },

        filter_drawer: {
            visibility: false
        },

        blog_single_gallery: {
            visibility: false,
            slider: {
                title: null,
                slides: []
            }
        },

        misc: {
            authenticated: false,
            accordionActive: 0
        },

        search_filter: {
            fields: $cookies.get('search_filter') || {
                mobile: 0,
                times: [],
                service_id: null,
                industry_id: null,
                // service_id: 99, //Catch up
                dates: [],
                location: {
                    id: null,
                    name: "All",
                    lat: null,
                    lng: null
                }
            },

            options: {
                mobile: [
                    {
                        label: "In Salon",
                        value: 0
                    },

                    {
                        label: "My Home",
                        value: 1
                    }
                ],

                times: [
                    {
                        label: "Morning",
                        value: "Morning"
                    },

                    {
                        label: "Afternoon",
                        value: "Afternoon"
                    },

                    {
                        label: "Evening",
                        value: "Evening"
                    }
                ]
            },
            view_type: 'list',
            triggerable: false,
            submit: false
        },

        active_result_index: null,
        search_visibility: false,
        menu: {
           visible: false,
           type: 'main'
        },
        services: [],
        industries: [],
        search_adjustments: {},
        is_booking: false,

    },

    getters: {

    },

    mutations: {
        setSearchFilters(state, payload) {
            Vue.set(state.search_filter, 'fields', {
                ...state.search_filter.fields,
                ...payload
            });
            // state.search_filter = {
            //     ...state.search_filter,
            //     fields:
            // };

            $cookies.set('search_filter',payload, '5min');
        },

        mutateOverlayVisibility(state, payload = null) {
            payload == null ? state.overlay.visibility = !state.overlay.visibility : state.overlay.visibility = payload;
        },

        mutateOverlayModalContent(state, payload) {
            state.overlay.modal.content = state.components[payload] || null;
        },

        mutateOverlayModalModifierClass(state, payload) {
            state.overlay.modal.modifierClass = payload;
        },

        mutateComponentParamsLoginForm(state, payload) {
            payload ? state.component_params.loginForm = payload : null;
        },

        mutateComponentParamsResetPassword(state, payload) {
            payload ? state.component_params.resetPassword = payload : null;
        },

        mutateComponentParamsVimeoVideo(state, payload) {
            payload ? state.component_params.vimeoVideo = payload : null;
        },

        mutateComponentParamsGenericModal(state, payload) {
            payload ? state.component_params.genericModal = payload : null;
        },

        mutateComponentParamsAppointmentCancel(state, payload) {
            payload ? state.component_params.appointmentCancel = payload : null;
        },

        mutateBookerVisibility(state, payload) {
            state.booker.visibility = payload;
        },

        mutateBookerDetails(state, payload) {
            state.booker.details = payload;
        },

        mutateBookerSelected(state, payload) {
            state.booker.selected = payload;
        },

        mutateBookerSelectedStage(state, payload) {
            state.booker.selected.stage = payload;
        },

        mutateBookerSelectedAvailabilityId(state, payload) {
            state.booker.selected.availability_id = payload;
        },

        mutateMiscAuthenticated(state, payload) {
            state.misc.authenticated = payload;
        },

        mutateAvailabilityDrawerVisibility(state, payload) {
            state.availability_drawer.visibility = payload;
        },

        mutateAvailabilityDrawerBusinessId(state, payload) {
            state.availability_drawer.business_id = payload;
        },



        mutateServiceDrawerVisibility(state, payload) {
            state.service_drawer.visibility = payload;
        },

        mutateServiceDrawerBusinessId(state, payload) {
            state.service_drawer.business_id = payload;
        },
        mutateServiceDrawerAvailability(state, payload) {
            state.service_drawer.availability = payload;
        },


        mutateBookerPreselectedService(state, payload) {
            
              Vue.set(state.booker, 'selected', {...state.booker.selected, service: payload});
            
            
        },

        mutateSearchFilterFieldsServiceId(state, payload) {
            state.search_filter.fields.service_id = payload;
        },

        mutateQuickLookDrawerVisibility(state, payload) {
            state.quick_look_drawer.visibility = payload;
        },

        mutateQuickLookDrawerId(state, payload) {
            state.quick_look_drawer.id = payload;
        },

        mutateFilterDrawerVisibility(state, payload) {
            state.filter_drawer.visibility = payload;
        },

        mutateActiveAccordionIndex(state, payload) {
            state.misc.accordionActive = payload;
        },

        mutateActiveResultIndex(state, payload) {
            state.active_result_index = payload;
        },

        mutateSearchVisibility(state, payload) {
            state.search_visibility = payload;
        },

        mutateMenu(state, payload) {
            state.menu = payload;
        },

        mutateBlogSingleGalleryVisibility(state, payload) {
            state.blog_single_gallery.visibility = payload;
        },

        mutateBlogSingleGallerySlider(state, payload) {
            state.blog_single_gallery.slider = payload;
        },

        mutateServices(state, payload) {
            Vue.set(state, 'services', payload);
        },

        mutateIndustries(state, payload) {
            Vue.set(state, 'industries', payload);
        },


        mutateSearchAdjustments(state, payload) {
            Vue.set(state, 'search_adjustments', payload);
            Object.keys(payload).forEach(param => {
                let val = state.search_filter.fields[param];
                let default_value = val instanceof Array ? [] : val instanceof Object ? {} : null;
                Vue.set(state.search_filter.fields, param, default_value);
            })
        },
        

        mutateIsBooking(state, value) {
            Vue.set(state, 'is_booking', value);
        },
    },

    actions: {
        cancelReservation(context) {
            axios.delete(`${UNRESERVE_AVAILABILITY}/${$cookies.get('visitor_id')}`).then(res => {
                $cookies.remove('booking');
                $cookies.remove('booking_selected');
                context.commit('mutateBookerVisibility', $cookies.get('booking') ? true : false);

                context.commit('mutateBookerSelected', {
                    availability_id: null,
                    service: null,
                    time: null,
                    stage: 'booking' // ['booking','confirming','confirmed'] (possibly more added)
                });

                context.commit('mutateBookerDetails', $cookies.get('booking'));
            });
        },


        async initBooking(context, {availability_id, service_id = null}) {
            
            
            if (await context.dispatch('checkIfAuthenticated')) {
                axios.put(RESERVE_AVAILABILITY, {
                    availability_id,
                    visitor_id: $cookies.get('visitor_id')
                }).then(res => {
                    res.data.data.provider.business.services = [];
                    $cookies.set('booking', res.data.data, moment.duration(moment(res.data.data.expires_at).diff()).asSeconds());
                    //                    context.commit('mutateSearchFilterFieldsServiceId', service_id);
                    context.commit('mutateBookerPreselectedService',service_id);
                    context.commit('mutateBookerVisibility',true);
                    context.commit('mutateAvailabilityDrawerVisibility',false);
                    context.commit('mutateServiceDrawerVisibility',false);
                    context.commit('mutateBookerDetails',res.data.data);
                    context.commit('mutateBookerSelectedAvailabilityId',availability_id);
                    context.commit('mutateBookerSelectedStage','booking');

                }).catch(err => {

                    alert(err.response.data.data.message || err);
                    $cookies.remove('booking');
                    context.commit('mutateBookerVisibility',false);
                });
            }

            else {
                if (window.ReactNativeWebView) {

                    let message = {
                        type: "ALERT",
                        title: "Log In",
                        message: "You must log in to book appointments",
                        buttons: [
                            {
                                title: "Sign Up",
                                action: {
                                    type: "GOTO",
                                    target: {
                                        name: 'GeneralWeb',
                                        url: "/signup"
                                    }
                                }
                            },
                            {
                                title: "Log In",
                                action: {
                                    type: "GOTO",
                                    target: {
                                        name: 'Login',
                                    }
                                }
                            },
                        ]
                    };
                    let stringMsg = JSON.stringify(message);
                    window.ReactNativeWebView.postMessage(stringMsg);
                } else {
                    context.dispatch('showLoginForm');
                }
            }
        },

        async checkIfAuthenticated(context) {
            const data = await axios.get(CAN_BOOK);
            return data.data.status;
        },

        initAvailabilityDrawer(context, business_id) {
            context.commit('mutateAvailabilityDrawerVisibility',true);
            context.commit('mutateAvailabilityDrawerBusinessId',business_id);
        },
        
        
        initServiceDrawer(context, slot) {
            context.commit('mutateServiceDrawerVisibility',true);
            context.commit('mutateAvailabilityDrawerVisibility',false);
             context.commit('mutateServiceDrawerBusinessId',slot.business_id);
            context.commit('mutateServiceDrawerAvailability',slot);
        },

        initQuickLook(context, id, type = 'business') {
            context.commit('mutateQuickLookDrawerId',id);
            context.commit('mutateQuickLookDrawerVisibility',true);
        },

        showLoginForm(context, params) {
            context.commit('mutateOverlayModalContent','loginForm');

            if (params) {
                context.commit('mutateComponentParamsLoginForm',params);
            }
            context.commit('mutateOverlayVisibility',true);
        },

        showDemoRequestForm(context) {
            context.commit('mutateOverlayModalContent','demoRequest');

            context.commit('mutateOverlayVisibility',true);
        },

        showResetPassword(context, params) {
            context.commit('mutateOverlayModalContent','resetPassword');
            //context.commit('mutateComponentParamsResetPassword',params);
            context.commit('mutateOverlayVisibility',true);
        },

        showVimeoVideo(context, params) {
            context.commit('mutateOverlayModalContent','vimeoVideo');
            context.commit('mutateComponentParamsVimeoVideo',params);
            context.commit('mutateOverlayModalModifierClass','c-overlay__modal--large');
            context.commit('mutateOverlayVisibility',true);
        },

        showModalAlert(context, params) {
            context.commit('mutateOverlayModalContent','genericModal');
            context.commit('mutateComponentParamsGenericModal',params);
            context.commit('mutateOverlayVisibility',true);
        },

        showMobileFilters(context, params) {
            context.commit('mutateFilterDrawerVisibility', true);
        },

        showAppointmentCancel(context, params) {
            context.commit('mutateOverlayModalContent', 'appointmentCancel');
            context.commit('mutateOverlayVisibility', true);
            context.commit('mutateComponentParamsAppointmentCancel', params);
        },

        initBlogSingleGallery(context, slider) {
            context.commit('mutateOverlayModalContent', 'blogSingleGallery');
            context.commit('mutateOverlayVisibility', true);
            context.commit('mutateOverlayModalModifierClass','c-overlay__modal--xl');
            context.commit('mutateBlogSingleGallerySlider', slider);
        }
    }
});
