var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "carousel",
    { staticClass: "story-carousel story-carousel--colors" },
    _vm._l(_vm.images, function(image, index) {
      return _c(
        "slide",
        { key: "image-" + index, staticClass: "story-carousel__slide" },
        [_c("img", { attrs: { src: image } })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }