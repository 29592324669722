<template>
    <div class="c-popup-drawer">
            <button aria-label="Close" class="c-drawer-close" @click="booker.selected.stage == 'processed' ? dismiss() : initCancelProcedure()">
                <span class="c-drawer-close__icon" style="top:-2px">&times;</span>
                <span class="c-drawer-close__tooltip">{{ booker.selected.stage == 'processed' ? 'Dismiss ' : 'Cancel Booking?' }}</span>
            </button>
        <div class="c-popup-drawer__inner">

            <div>
                <!-- Booking -->
                <div v-if="booker.selected.stage == 'booking'">
                    <div class="row row--tight" style="margin:0;">
                        <div class="w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left">
                            <h2 class="text-copper-400 mb-0">What Time?</h2>
                        </div>

                        <div class="w-full lg:w-6/12 xl:w-8/12 col text-center pt-4 lg:pt-0 my-4 lg:my-0 border-t border-b lg:border-none">
                            <p class="mb-0"><strong>{{ booker.details.provider.business.name }}</strong> with <strong>{{ booker.details.provider.full_name }}</strong></p>
                            <p class="mb-2">{{ availability_date }}</p>

                            <div class="xl:flex xl:justify-center">
                                <div>
                                    <label class="hidden md:block xl:inline-block text-left">Request</label>
                                    <select class="c-form__select w-full xl:w-auto" v-model="booker.selected.service">
                                        <option v-for="(service, index) in booker.details.services" :key="index" :value="service">{{ service.name }} - {{ service.cost }}*</option>
                                    </select>
                                </div>

                                <div class="text-left">
                                    <label class="hidden md:block xl:inline-block xl:mx-2">At</label>
                                    <select class="c-form__select w-auto" v-model="booker.selected.time" style="min-width:auto;">
                                        <option v-for="(time, index) in times" :key="index" :value="time.value">{{ time.label }}</option>
                                    </select>
                                    <span v-if="booker.selected.service && booker.selected.time" class="md:ml-2">Finishing at: {{ finishing_time }}</span>
                                </div>
                            </div>
                        </div>

                           <div class="w-full lg:w-3/12 xl:w-2/12 col text-right">
                            <button 
                                class="btn btn--green btn--arrow" 
                                v-if="booker['selected']['time'] && booker['selected']['service']" 
                                @click="booker.selected.stage =( search_filter.fields.mobile || provider_is_mobile)? 'location' : 'confirming'"
                            >
                                    Next <i class="fas fa-chevron-right"></i>
                                </button>
                        </div>
                    </div>

                    <div class="c-drawer-timer" :class="time_remaining_traffic_light">
                        <div class="c-drawer-timer__icon">
                            <i class="far fa-stopwatch text-lg"></i>
                            <span>{{ time_until_expire }}</span>
                        </div>
                        <span class="c-drawer-timer__tooltip">To complete your booking</span>
                    </div>
                </div>
                <!-- / Booking -->

                <!-- Location -->
                 <div v-if="booker.selected.stage == 'location'">
                    <div class="row row--tight" style="margin:0;">
                        <div class="w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left">
                            <h2 class="text-copper-400 mb-0">Confirm Location</h2>
                        </div>

                        <div class="w-full lg:w-6/12 xl:w-8/12 col text-center pt-4 lg:pt-0 my-4 lg:my-0 border-t border-b lg:border-none font-bold">
                            Confirm or update your home address
                            <div class="xl:flex xl:justify-center">
                                <!-- FAKE INPUT -->
                                <div contenteditable="" ref="fake_textarea" @input="onLocationInput" class="focus:outline-none bg-gray-200 px-4 py-2 w-full my-2 rounded-md text-center font-normal">
                                    {{current_user_address}}
                                </div>
                            </div>
                            <label class="sublabel text-xs">This information comes from your <a class="underline hover:no-underline" href="/my-account/details">Profile</a>. If you want to speed up this process in the future make sure your address is up to date.</label>
                        </div>
              
                        <div class="w-full lg:w-3/12 xl:w-2/12 col text-right">
                            <button class="btn btn--green btn--arrow" v-if="booker['selected']['time'] && booker['selected']['service']" @click="booker.selected.stage = 'confirming'">Next <i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>

                    <div class="c-drawer-timer" :class="time_remaining_traffic_light">
                        <div class="c-drawer-timer__icon">
                            <i class="far fa-stopwatch text-lg"></i>
                            <span>{{ time_until_expire }}</span>
                        </div>
                        <span class="c-drawer-timer__tooltip">To complete your booking</span>
                    </div>
                </div>
                <!-- / Location -->


                <!-- Confirm booking -->
                <div v-if="booker.selected.stage == 'confirming'">
                    <div class="row row--tight" style="margin:0;">
                        <div class="w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left">
                            <h2 class="text-copper-400 mb-0">Confirm</h2>
                        </div>

                        <div class="w-full lg:w-6/12 xl:w-8/12 col text-center mb-8 lg:mb-0 py-4 lg:py-0 my-4 lg:my-0 border-t border-b lg:border-none">
                            <p class="mb-2">
                                You are about to book 
                                <strong>{{ booker.selected.service.name }}</strong>
                                with <br class="hidden md:inline"> <strong>{{ booker.details.provider.business.name }}</strong> 
                                by <strong>{{ booker.details.provider.full_name }}</strong> 
                                at <strong>{{ selected_time_pretty }}</strong>
                            </p>
                            <a v-if="times.length > 1" @click="booker.selected.stage = 'booking'" class="underline hover:no-underline text-grey-400" role="button">Change Details</a>
                        </div>

                        <div class="w-full lg:w-3/12 xl:w-2/12 col text-right">
                            <button class="btn btn--green btn--arrow" @click="initConfirmProcedure">Confirm <i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>

                    <div class="c-drawer-timer lg:mt-4" :class="time_remaining_traffic_light">
                        <div class="c-drawer-timer__icon">
                            <i class="far fa-stopwatch text-lg"></i>
                            <span>{{ time_until_expire }}</span>
                        </div>
                        <span class="c-drawer-timer__tooltip">To complete your booking</span>
                    </div>
                </div>
                <!-- / Confirm booking -->


                <template v-if="booker.selected.stage == 'processed'">
                    <!-- Confirmation -->
                    <div v-if="booker.appointment.status == 'confirmed'" class="text-center">
                        <h2 class='text-copper-400 mb-2'>Yay! All booked</h2>
                        <p class="mb-2">We'll send you an email to confirm your booking. <template v-for="prop in booker.appointment.properties">{{ prop.description }} </template></p>
                        <button @click="dismiss" :href="ACCOUNT_BOOKINGS" class="underline hover:no-underline focus:outline-none">Got It</button>
                    </div>

                    <div v-else class="text-center">
                        <h2 class='text-copper-400 mb-2'>Almost There!</h2>
                        <p class="mb-2">We're waiting for {{ booker.details.provider.business.name }} to confirm your booking. They may get in touch to check details. <br class="hidden md:inline">Make sure your contact details are up to date in Settings.
                        <template v-for="prop in appointment_properties">{{ prop.description }} </template>

                        </p>
                        <button @click="dismiss" class="underline hover:no-underline focus:outline-none">Got It</button>
                    </div>

                </template>


            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import { CONFIRM_BOOKING, ACCOUNT_BOOKINGS } from '@js/endpoints';

//Confetti
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

export default {
    data() {
        return {
            ACCOUNT_BOOKINGS,
            time_until_expire: '00:00',
            time_until_expire_ms: null,
            countdown: null,
            appt_location: null,
            selected: {
                availability_id: null,
                service: null,
                stage: null,
                time: null
            },
            current_user: null,

        }
    },

    watch: {
        'booker.details'() {
            if (this.booker.selected.service || this.booker.selected.time) {
                this.booker.selected.service = this.booker.selected.time = null;
            }
        },

        'booker.selected': {
            deep:true,
            immediate:true,
            handler() {
                if (Number.isInteger(this.booker.selected.service)) {
                    this.$store.commit(
			'mutateBookerPreselectedService', 
			this.booker.details.services.find(
				service => service.id === this.booker.selected.service
			)
 		    );
                }

		this.$nextTick(() => {$cookies.set('booking_selected', this.booker.selected, moment.duration(moment.utc(this.booker.details.expires_at).diff()).asSeconds());});
            }
        },

        time_until_expire_ms() {
            if (this.time_until_expire_ms < 0) {
                clearInterval(this.countdown);
                this.cancelReservation();
                alert('Sorry! You took a while to complete the booking, so we\'ve had to let the availability slot open to other customers.\r\rIf the slot is still available, you\'ll be able to start the process again');
            }
        }
    },

    created() {

        this.countdown = setInterval(() => {
            let diff = moment.duration(moment.utc(this.booker.details.expires_at).diff());
            this.time_until_expire = moment.utc(diff.as('milliseconds')).format('mm:ss');
            this.time_until_expire_ms = diff.as('milliseconds');
        }, 1000);
    },

    mounted() {
            this.mutateIsBooking(true);

        this.populatePreselectedService().then(() =>{
            this.populateFirstAvailableTimeSlot();
        });
        if (Number.isInteger(this.booker.selected.service)) {
            this.$store.commit('mutateBookerPreselectedService', this.booker.services.find(service => service.id === this.booker.selected.service))
        }
        axios.get('/api/user/details').then(response => {
            this.current_user = response.data;
            let {address_line_1, address_line_2, address_line_3, address_line_4, postcode} = this.current_user;
            let address = [address_line_1, address_line_2, address_line_3, address_line_4, postcode];
            this.appt_location =  address.filter(a => a).join(', ');
        })
    },

    beforeDestroy() {
        clearInterval(this.countdown);
    },



    computed: {
        ...mapState(['booker', 'search_filter']),

        address() {
            return this.search_filter.fields.location

        },

        times() {


            const start = moment.utc(this.booker.details.availability_start);
            const end = moment.utc(this.booker.details.availability_end);
            const duration = moment.duration(end.diff(start)).asHours();
            const time_required = this.booker.selected.service ? this.booker.selected.service.time_required.split(":") : [0,0];
            const adjusted_duration = moment.duration(end.diff(start.clone().add(time_required[0], 'hours').add(time_required[1], 'minutes'))).asHours();
            const increments = adjusted_duration * 12;

            let times = [];

            for (let i = 0; i <= increments; i++) {
                let time = moment.utc(start).add(5 * i, 'minutes');
                times.push({ 'label' : time.format('h:mmA'), 'value' : time.format('Y-MM-DD HH:mm:ss') });
            }

            return times;

        },

        time_remaining_traffic_light() {

            switch (true) {
                case (this.time_until_expire_ms < 120000):
                    return "text-red-500";
                    break;

                case (this.time_until_expire_ms < 300000):
                    return "text-orange-500";
                    break;

                default:
                    return false;
            }
        },

        selected_time_pretty() {
            return this.booker.selected.time ? moment.utc(this.booker.selected.time).format('hh:mmA') : null;
        },
        finishing_time() {
            if (this.booker.selected.time) {
                const time_required = this.booker.selected.service.time_required.split(":");
                return moment.utc(this.booker.selected.time).add(time_required[0], 'hours').add(time_required[1], 'minutes').format('h:mmA');
            }
        },

        availability_date() {
            return moment.utc(this.booker.details.availability_start).format('dddd Do MMMM');
        },

        current_user_address() {
            if (!this.current_user) return null;

            let {address_line_1, address_line_2, address_line_3, address_line_4, postcode} = this.current_user;
            let address = [address_line_1, address_line_2, address_line_3, address_line_4, postcode];
            return address.filter(a => a).join(', ');
        },

        provider_is_mobile() {
            return this.booker?.details?.provider?.diary?.mobile || this.booker?.details?.business.is_mobile;
        },

        appointment_properties() {
            let properties = [];
            return this.booker.appointment.properties.filter(prop => {
                if (!properties.includes(prop.property_id)) {
                    properties.push(prop.property_id);
                    return true;
                }
                return false;
            })
        },
    },

    methods: {

        ...mapMutations(['mutateBookerVisibility','mutateBookerSelected', 'mutateIsBooking']),
        ...mapActions(['cancelReservation']),

        setLocation(location) {
            this.appt_location = location;
        },

        populatePreselectedService() {
            return new Promise((resolve) => {

        //        this.booker.selected.service = this.booker.details.services.find(service => service.id == this.search_filter.fields.service_id);
                resolve("Done");
            });
        },

        populateFirstAvailableTimeSlot() {
            this.booker.selected.time = this.times[0] ? this.times[0]['value'] : null;
        },

        initCancelProcedure() {
            if (confirm("Are you sure you want to cancel? If you do, another user will be able to book this slot.")) {
                this.mutateIsBooking(false);
                this.cancelReservation();

            } 
        },

        initConfirmProcedure() {
            axios.put(CONFIRM_BOOKING, {
                ...this.booker.selected,
                address: this.appt_location,
                lat: null,
                lng: null,
                is_mobile: this.search_filter.fields.mobile,
            }).then(res => {
                if (res.data.data.status) {
                    $cookies.remove('booking');
                    $cookies.remove('booking_selected');
                    this.booker.selected.stage = 'processed';
                    this.booker.appointment = res.data.data;

                    this.$confetti.start({
                        defaultColors: [
                            '#b16d00',
                            '#bbded3',
                            '#343b48',
                        ],
                    });

                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 5000);

                    clearInterval(this.countdown);
                }
            });
        },

        dismiss() {

            this.mutateBookerSelected({
                availability_id: null,
                service: null,
                time: null,
                stage: 'booking' // ['booking','confirming','confirmed'] (possibly more added)
            });

            this.mutateBookerVisibility(false);

            // window.nsWebViewBridge.emit('reloadAppointments');
        },

        onLocationInput(event) {
            this.appt_location = this.$refs.fake_textarea.innerText
        },
    }
}
</script>

<style lang="scss" scoped>
.sublabel {
    font-size: 12px;
    font-weight: 400;
    color: rgb(153, 153, 153);
}
</style>
