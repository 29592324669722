<template>
  <div class="c-popup-drawer">
    <div class="c-popup-drawer__inner">
        <button
          aria-label="Close"
          class="c-drawer-close"
          @click="close()"
        >
          <span class="c-drawer-close__icon" style="top:-2px">&times;</span>
          <span class="c-drawer-close__tooltip">Cancel?</span>
        </button>
      <div>

        <h2 class="text-copper-400">
          Availability - {{ availability[0] ? availability[0].business_name : null }}
        </h2>

        <p v-if="!availability.length">Loading availability...</p>

        <table class="c-stylised-table w-full text-grey-1000">
          <tr v-for="(slot, index) in availability" :key="index" class="flex flex-wrap">
            <td class="w-full">
              <strong>{{ formatDate(slot.availability_start, slot.availability_end) }}</strong>
              <br />
              <span class="text-copper-400 italic">
                with
                <a
                  :href="slot.service_provider.link"
                  class="underline hover:no-underline"
                >{{ slot.service_provider.name }}</a>
              </span>
            </td>
            <td class="text-grey-1000 items-center flex font-poynter font-bold text-lg text-right">
              <span class="flex flex-row">
                <span class="italic text-102 mr-1">From </span>
                <span>{{slot.cost }}</span>
              </span>
            </td>
            <td  style="padding-bottom: 6.5px;">
              <a class="btn btn--teal truncate whitespace-nowrap" @click="bookClicked(slot)">
                {{services.length > 1 ? 'See Services' : 'Book'}}
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { BUSINESS_AVAILABILITY } from "@js/endpoints";
import moment from "moment";

export default {
  data() {
    return {
      availability: []
    };
  },

  props: {},

  computed: {
    ...mapState(["availability_drawer", "search_filter"]),
    services() {
      return this.availability.reduce((services, avail) => {
        avail.services.forEach(as => {
          if (!services.find(s => s.id === as.id )) {
            services.push(as);
          }

        });
        return services;
      }, [])
    }
  },

  watch: {
    "availability_drawer.business_id"() {
      this.getBusinessAvailability();
    }
  },

  mounted() {
    this.getBusinessAvailability();
    this.mutateIsBooking(true);

  },

  methods: {
    ...mapMutations(["mutateAvailabilityDrawerVisibility", "mutateIsBooking"]),


    ...mapActions(["initBooking", 'initServiceDrawer']),

    bookClicked(slot) {
      if (this.services.length > 1 && !this.search_filter.fields.service_id) {
        this.initServiceDrawer(slot);
      } else {
        this.initBooking({availability_id: slot.id, service_id: this.search_filter.fields.service_id})
      }
    },
    getBusinessAvailability() {
      axios
        .get(
          `${BUSINESS_AVAILABILITY}/${this.availability_drawer.business_id}`,
          {
            params: this.search_filter.fields
          }
        )
        .then(res => {
          this.availability = res.data.data;
        });
    },

    formatDate(start, end) {
      let times =
        moment.utc(start).format("h:mmA") +
        " - " +
        moment.utc(end).format("h:mmA");

      return moment.utc(start).isSame(moment.utc(), "day")
        ? `Today ${times}`
        : moment.utc(start).isSame(moment.utc().add(1, "days"), "day")
        ? `Tomorrow ${times}`
        : moment.utc(start).format("dddd Do MMMM") + ` ${times}`;
    },

    close() {
        this.mutateIsBooking(false);
        this.mutateAvailabilityDrawerVisibility(false);
    }
  },

  watch: {
    "search_filter.fields": {
      deep: true,
      handler() {
        this.getBusinessAvailability();
      }
    },

    availability(new_val, old_val) {
      if (!new_val.length && old_val.length) {
        this.mutateAvailabilityDrawerVisibility(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  td:last-of-type {
    width: 100px;
  }

  td:nth-last-child(2) {
    width: 100px;
  }
}

.text-102 {
    color: rgb(102, 102, 102);
}
</style>
