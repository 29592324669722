<template>
  <div>
    <h2 v-if="headerSize == 'h2'" class="mb-4">Stay in touch</h2>
    <h3 v-if="headerSize == 'h3'" class="mb-4">Stay in touch</h3
    >
    <form action @submit.prevent="newletterSignup" v-if="!signed_up" class="flex flex-wrap">
      <div class="signup-col w-full">
        <input type="text" placeholder="Name" class="c-form__input w-full mb-2" required v-model="details.name" />
      </div>
      <div class="w-full flex signup-col">
        <input
          type="email"
          placeholder="Email address"
          class="c-form__input flex-grow"
          style="margin-bottom:0;"
          v-model="details.email"
          required
        />
        <button
          aria-label="Submit"
          class="flex items-center justify-center flex-shrink-0 flex-grow-0 rounded-r bg-grey-1000 text-white p-3"
        >
          <i class="far fa-check"></i>
        </button>
      </div>
    </form>
    <p v-else>Thank you. We'll keep you updated. Keep an eye on your inbox.</p>
  </div>
</template>

<script>
import { NEWSLETTER_SIGNUP } from '@js/endpoints';
export default {
    data() {
        return {
            details: {
                name: null,
                email: null
            },

            signed_up: false
        }
    },

    props: {
        endpoint: {
            required: false,
            default: NEWSLETTER_SIGNUP
        },
        headerSize: {
          type: String,
          required: false,
          default: 'h2'
        }
    },

    computed: {
        form_valid() {
            return this.details.name && this.details.email;
        }
    },

    methods: {
        newletterSignup() {

            if(this.form_valid) {
                axios.post(this.endpoint, this.details).then(res => {
                    this.signed_up = true;
                }).catch(error => {
                    alert('Sorry, there was an error and we couldn\'t not sign you up.\nPlease make you supply both your first and last name.');
                });
            } else {
                alert("Please populate both your name and email address");
            }
        }
    }
}
</script>
