<template>
    <div class="c-overlay fixed inset-0" v-if="overlay.visibility">
        <div class="c-overlay__modal" :class="overlay.modal.modifierClass">
            <component :is="overlay.modal.content" class="pt-10 pt-4 p-4"></component>
            <span @click="mutateOverlayModalModifierClass(null) && mutateOverlayVisibility(false)" class="c-overlay__close" role="button" aria-label="Close popup"><span class="relative" style="top:-2px;">&times;</span></span>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {

        }
    },

    computed: {
        ...mapState(['overlay'])
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility', 'mutateOverlayModalModifierClass'])
    },

    created() {
        document.onkeydown = e => {
            e = e || window.event;
            if (e.keyCode == 27) {
                this.mutateOverlayModalModifierClass(null);
                this.mutateOverlayVisibility(false)
            }
        };
    }
}
</script>

<style lang="scss" scoped>
    .c-overlay {
        @apply flex items-end;
        background: rgba(0,0,0,0.8);
        z-index: 99;

        &__modal {
            @apply absolute bg-white;
            z-index: 100;

            > div {
                // @apply p-4;
                @apply h-full w-screen overflow-auto;
                max-height: 80vh;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }

            ::v-deep h1 {
                @media(max-width: 767px) {
                    font-size: 1.75rem;
                }
            }
        }

        &__close {
            @apply absolute top-0 flex items-center justify-center bg-white shadow-lg-allsides font-light text-3xl rounded-full;
            right: 50%;
            transform: translate(50%, -50%);
            width: 40px;
            height: 40px;
            transition: 0.4s ease;

            @screen md {
                @apply right-0;
                width: 30px;
                height: 30px;
            }

            &:hover {
                transform: translate(50%, -50%) scale(1.05);
            }
        }
    }
</style>
