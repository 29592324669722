<template>
    <div class="c-popup-drawer">
        <div class="c-popup-drawer__inner">
            <div>
                <div class="md:flex md:flex-wrap md:items-center overflow-hidden">

                        <h2 class="text-center text-copper-400">Filter</h2>

                        <!-- <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Salon Type</span>
                            <filter-toggle label="Where" v-model="search_filter.mobile" options="mobile" selected="mobile"></filter-toggle>
                        </div> -->

                        <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Category</span>
                            <filter-suggest label="Category" :endpoint="LIVE_SEARCH_INDUSTRIES" v-model="search_filter.industry_id" to-set-prop="id"></filter-suggest>


                        </div>

                        <div class="c-mobile-filter-group flex-col flex items-center">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Salon Type</span>
                            <filter-toggle-alt label="Where" v-model="search_filter.mobile" options="mobile" selected="mobile"></filter-toggle-alt>
                        </div>


                        <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Service</span>
                            <filter-suggest v-model="search_filter.service_id"
                                label="Service"
                                :endpoint="LIVE_SEARCH_SERVICES"
                                :endpoint_params="{industry_id: search_filter.industry_id}"
                                to-set-prop="id"
                            />
                        </div>

                        <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Location</span>
                            <filter-location v-model="search_filter.location" label="Location"></filter-location>
                        </div>

                        <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Date</span>
                            <filter-date v-model="search_filter.dates" label="Date" selected="dates" :interacting-default="false"></filter-date>
                        </div>

                        <div class="c-mobile-filter-group">
                            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Time</span>
                            <filter-checkbox v-model="search_filter.times" label="When" selected="times"></filter-checkbox>
                        </div>

                        <div class="c-filter-update-msg" v-if="filterUpdated">
                            <i class="fas fa-check-circle"></i>
                            <span class="px-2">Search Results Updated</span>
                            <i class="fal fa-times"></i>
                        </div>
                </div>
            </div>
        </div>
        <button aria-label="Close" class="c-drawer-close" @click="$emit('close')">
            <span class="c-drawer-close__icon" style="top:-2px">&times;</span>
            <span class="c-drawer-close__tooltip">Close</span>
        </button>
    </div>
</template>

<script>
import { LIVE_SEARCH_SERVICES, LIVE_SEARCH_INDUSTRIES } from '@js/endpoints';
import { mapState, mapMutations } from 'vuex';
import _ from 'lodash';
export default {
    props: [ 'value'],
    data() {
        return {
            LIVE_SEARCH_SERVICES,
            LIVE_SEARCH_INDUSTRIES,
            filterUpdated: false,
            mounted: false,
            // search_filter: {...this.value}
        }
    },
    computed: {
        ...mapState({store_filter: 'search_filter'}),

        search_filter: {
            get() {
                return this.value;
            },

            set(newVal) {
                this.$emit('input', {...newVal});
            }
        }
    },
    watch: {
         'search_filter':{
            deep: true,
            handler: function (newVal, oldVal) {
                if (
                    newVal.location.lat != this.store_filter.fields.location.lat ||
                    newVal.location.lng != this.store_filter.fields.location.lng ||
                    newVal.service_id != this.store_filter.fields.service_id ||
                    newVal.industry_id != this.store_filter.fields.industry_id ||
                    newVal.dates.length != this.store_filter.fields.dates.length ||
                    newVal.times.length != this.store_filter.fields.times.length ||
                    newVal.mobile !== this.store_filter.fields.mobile
                ) this.$emit('input', newVal);
                else {
                   
                }
            }
        },
        'store_filter.fields': {
            deep: true,
            handler: function(values) {
                Object.keys(values).forEach(param => {
                    let value = values[param];
                    if (value instanceof Array) {
                        if (value.length === 0) {
                            this.search_filter[param] = [];
                        }
                    } else if (value instanceof Object) {
                        if (Object.keys(value).length === 0) {
                            this.search_filter[param] = {}
                        }
                    } else {
                        if (value === null) {
                            this.search_filter[param] = null;
                        }
                    }
                })
            }
        },
        filterUpdated: {
            handler(val){
                val === true ? setTimeout(()=> this.filterUpdated = !this.filterUpdated, 4000) : null;
            }
        },
        // value(newValue) {
        //     this.search_filter = {...newValue}
        // }
    },

    methods: {
        updateService(service) {
        },


    },
    mounted(){
        setTimeout(() => this.mounted = true, 1000 )
    },
}
</script>

<style lang="scss" scoped>
    .c-popup-drawer__inner {
        @apply px-0;
    }

    .c-mobile-filter-group {
        @apply py-8 border-t border-b border-grey-300 px-4;

        @screen xxs {
            @apply px-8;
        }
    }

    .c-filter-update-msg {
        @apply absolute py-2 px-4 border border-green-500 bg-teal-400 text-grey-1000 text-sm rounded;
        top: -2rem;
        left: 50%;
        transform: translate(-50%, -100%);
        white-space: nowrap;

        &:last-of-type {
            border-bottom: none;
        }
    }
</style>
