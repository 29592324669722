var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-accordion",
      class: { active: _vm.shouldShow },
      on: {
        click: function($event) {
          return _vm.mutateActiveAccordionIndex(_vm.accordionIndex)
        }
      }
    },
    [
      _c("div", { staticClass: "flex justify-between" }, [
        _c("h3", { staticClass: "c-accordion__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "c-accordion__toggle",
          attrs: { role: "button" }
        })
      ]),
      _vm._v(" "),
      _vm.shouldShow
        ? _c(
            "div",
            { staticClass: "c-accordion__content" },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }