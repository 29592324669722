var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col w-full lg:mb-0 relative",
      class: { "lg:w-8/12": _vm.show_days || !_vm.business.is_mobile },
      staticStyle: { height: "250px" }
    },
    [
      _c(
        "gmap-map",
        {
          ref: "map",
          staticClass: " business-customer-map h-full w-full",
          attrs: {
            center: {
              lat: parseFloat(_vm.business.lat),
              lng: parseFloat(_vm.business.lng)
            },
            "map-type-id": "terrain",
            zoom: 10,
            options: {
              disableDefaultUi: true,
              zoomControl: true,
              mapTypeControl: false,
              streetViewControl: true,
              fullscreenControl: false,
              controlSize: 30
            }
          }
        },
        [
          _vm.is_mobile
            ? _vm._l(_vm.users, function(user) {
                return _c("gmap-circle", {
                  key: "user-radius-" + user.id,
                  ref: "map_circle",
                  refInFor: true,
                  attrs: {
                    center: {
                      lat: parseFloat(user.diary.lat),
                      lng: parseFloat(user.diary.lng)
                    },
                    radius: user.diary.radius_miles * 1609.34,
                    visible: true,
                    options: {
                      fillColor: user.color,
                      strokeColor: user.stroke_color,
                      strokeOpacity: "0.5",
                      strokeWeight: 1
                    },
                    "fillOpacity:": 0.5
                  }
                })
              })
            : [
                _c("gmap-marker", {
                  attrs: {
                    position: {
                      lat: parseFloat(_vm.business.lat),
                      lng: parseFloat(_vm.business.lng)
                    },
                    icon: { url: "/images/icons/pin-active--small.png" }
                  }
                })
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.is_mobile && _vm.users.length > 1
        ? _c("div", { staticClass: "providers-legend" }, [
            _c(
              "div",
              { staticClass: "legend-bg" },
              _vm._l(_vm.users, function(user) {
                return _c(
                  "div",
                  {
                    key: "provider-key-item-" + user.id,
                    staticClass: "provider-key"
                  },
                  [
                    _c("span", {
                      staticClass: "key-color",
                      style: {
                        "background-color": user.color,
                        border: "1px solid"
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "provider-key-name" }, [
                      _vm._v(_vm._s(user.full_name))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }