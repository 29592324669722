var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-popup-drawer" }, [
    _c("div", { staticClass: "c-popup-drawer__inner" }, [
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "md:flex md:flex-grow-0 md:flex-shrink md:items-start md:items-center justify-between flex-grow mb-10"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex items-start flex-shrink flex-grow-0 mb-4 md:mb-0 md:mr-2"
              },
              [
                _c("div", [
                  _c("h2", { staticClass: "mb-0 text-copper-400" }, [
                    _vm._v(_vm._s(_vm.data.name))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 font-semibold" }, [
                    _vm._v(_vm._s(_vm.data.address))
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "md:flex md:flex-grow md:justify-end flex-shrink-0"
              },
              [
                _c("div", { staticClass: "flex-shrink-0 md:mr-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--grey w-full md:w-auto text-center",
                      attrs: { href: _vm.data.link }
                    },
                    [_vm._v("View")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 md:mt-0" },
                  [
                    _c("book-suggestion", {
                      attrs: { "business-id": _vm.quick_look_drawer.id }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "md:flex md:flex-wrap md:items-center overflow-hidden"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "-mx-2 mb-10 lg:w-7/12 flex flex-row justify-start mb-6 lg:mb-0 md:mr-12 overflow-x-auto"
              },
              _vm._l(_vm.data.images, function(image, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "px-2 w-11/12 md:w-4/12 flex-shrink-0"
                  },
                  [
                    _c("img", {
                      staticClass: "md:w-full",
                      attrs: { src: image.url }
                    })
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-full lg:w-5/12" }, [
              _c("p", [_vm._v(_vm._s(_vm.data.content))])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "c-drawer-close",
        attrs: { "aria-label": "Close" },
        on: {
          click: function($event) {
            return _vm.mutateQuickLookDrawerVisibility(false)
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "c-drawer-close__icon", staticStyle: { top: "-2px" } },
          [_vm._v("×")]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "c-drawer-close__tooltip" }, [
          _vm._v("Close?")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }