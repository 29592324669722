<template>
    <div :key="render_key" class="c-hero-forms" :class="{'c-hero-forms--popup':isPopup}">
        <div class="flex flex-grow-0 flex-shrink-0">
            <span class="h3 c-hero-forms__tab" :class="search_type == 'service' ? 'c-hero-forms__tab--active' : null" role="button" @click="search_type = 'service'">Service</span>
            <span class="h3 c-hero-forms__tab" :class="search_type == 'salon' ? 'c-hero-forms__tab--active' : null" role="button" @click="search_type = 'salon'">Salon</span>
        </div>

        <!-- Service Search -->
        <div class="c-hero-forms__form c-form" v-show="search_type == 'service'">
            <div>
                <div class="c-form__auto-complete flex-col" :class="{'input-error': service_error}">
                    <div class="industries">
                        <span class="industry-block cursor-pointer" v-for="(industry, index) in sorted_industry_list" :key="`industry-option-${index}`" :class="{'selected': industry_id && industry.id === industry_id.id}" @click="setIndustry(industry)">
                            <span class="industry-name font-poynter" :class="{'firefox': userAgent.indexOf('Firefox') !== -1, 'iphone': userAgent.indexOf('iPhone') !== -1}">{{industry.name}}</span>
                        </span>
                        <span v-if="service_error" class="input-error__message">Oops, you haven't picked a category from list!</span>
                    </div>
                    <span class="underline hover:no-underline font-normal cursor-pointer text-black more-button" @click="show_all_industries = !show_all_industries">{{show_all_industries ? 'Less' : 'More'}}</span>
            </div>
        </div>

            <div>
                <div class="c-form__radio-as-toggle">
                    <template v-for="(option, index) in options.mobile">
                        <input type="radio" :id="option.label+index" :value="option.value" :key="'input_'+index" v-model="search_filters.mobile">
                        <label :for="option.label+index" :key="'label_'+index">{{ option.label }}</label>
                    </template>
                </div>
            </div>

            <div>
                <p class="label text-copper-400 mb-1">Location?</p>
                <div class="c-form__auto-complete c-form__location-group relative" :class="{'input-error': location_error}">
                    <input type="text" class="c-form__input w-full" tabindex="2" @blur="geoSearch();" @focus="focusLocation" @keyup.enter="geoSearch();" v-model="location_query" placeholder="E.g Leeds">
                    <i @click="location_query=null" class="fas fa-times location-clear"></i>
                    <!-- <span @click="getDeviceLocation" v-if="isMobileOnly" role="button" class="location-icon text-grey-1000"><i class="far fa-location"></i></span> -->
                    <span v-if="location_error" class="input-error__message">We can't find {{ location_query || 'that location' }}</span>
                </div>
            </div>
            <!-- DATE -->
            <div class="mb-4" v-on-clickaway="() => show_calendar = false">
                <p class="label text-copper-400 mb-1">Day?</p>
                <div class="-mx-1 -mt-2 relative">
                    <div class="c-form__label-as-btn mx-1" v-for="(date, index) in quick_pick_dates" :key="index">
                        <input type="checkbox" :id="'quick_pick'+index" :value="date.date" v-model="date_parsed">
                        <label :for="'quick_pick'+index">{{ dateAsWord(date.date, true) }}</label>
                    </div>
                </div>
                <div class="w-auto inline-block">
                    <div class="w-full flex flex-wrap relative -mx-1">
                        <div class="c-form__label-as-btn mx-1" v-if="other_dates">
                            <label class="checked-sim">+{{ other_dates }}</label>
                        </div>
                        <div class="c-form__label-as-btn block flex-shrink-0 mx-1" tabindex="3" @click="show_calendar = !show_calendar">
                            <label>Pick a date</label>
                        </div>
                        <div class="c-datepicker shadow-lg-allsides">
                            <v-date-picker mode="multiple" v-if="show_calendar" v-model="date_parsed" is-inline :min-date="new Date()"></v-date-picker>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TIME -->
            <div class="mb-4">
                <p class="label text-copper-400 mb-1">Time?</p>
                <div class="-mx-1 -mt-2">
                    <div class="c-form__label-as-btn mx-1" v-for="(option, index) in options.times" :key="index">
                        <input type="checkbox" tabindex="4" :id="option.label+index" :value="option.value" @input="console.log" v-model="search_filters.times">
                        <label :for="option.label+index">{{ option.label }}</label>
                    </div>
                </div>
            </div>
            <div class="filler">
                &nbsp;
            </div>

            <a class="srch-btn btn btn--teal btn--icon w-full text-white text-center submit flex items-center justify-center" role="button" tabindex="5" @click="doSearchIfValid">
                <i class="fas fa-search btn__icon btn__icon--sm"></i>
                Search
            </a>
        </div>

        <!-- Salon Search -->
        <div class="c-hero-forms__form c-hero-forms__form--salon c-form" v-show="search_type == 'salon'">
            <div class="middle">
                <p class="label text-copper-400 mb-1">Salon</p>
                <div class="c-form__auto-complete">
                    <vue-simple-suggest ref="salon_field" @suggestion-click="openSalon" placeholder="Start typing salon name" :list="autocomplete.salon_names" mode="select" display-attribute="name" value-attribute="id" :filter-by-query="true" :filter="customFilter" v-model="salon" :styles="autocomplete.styles"></vue-simple-suggest>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import VueGeolocation from 'vue-browser-geolocation';
import VueSimpleSuggest from 'vue-simple-suggest';
import { isMobileOnly } from 'mobile-device-detect';
import moment from 'moment';
import Fuse from 'fuse.js';
import { LIVE_SEARCH_SERVICES, LIVE_SEARCH_INDUSTRIES, LIVE_SEARCH_CITIES, LIVE_SEARCH_BUSINESS, LIVE_SEARCH_GEOLOCATION } from '@js/endpoints';

Vue.use(VueGeolocation);

export default {
    components: {
        VueSimpleSuggest
    },

    props: {
        endpoint: {
            type: String,
            default: '/results'
        },
        isPopup: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            search_filters: {
                location: null,
                service_id: null,
                industry_id: null,
                mobile: 0,
                dates: [],
                times: [],
                options: {
                    times: [
                        'test1',
                        'test2'
                    ]
                }
            },
            options: {
                mobile: [
                    {
                        label: "In Salon",
                        value: 0
                    },

                    {
                        label: "At Home",
                        value: 1
                    }
                ],

                times: [
                    {
                        label: "Morning",
                        value: "Morning"
                    },

                    {
                        label: "Afternoon",
                        value: "Afternoon"
                    },

                    {
                        label: "Evening",
                        value: "Evening"
                    }
                ]
            },
            isMobileOnly,
            location_query: '',
            location_focus: false,
            location_zero_results: false,
            search_type: "service",
            show_calendar: false,
            salon: null,
            attempted_search: false,
            render_key: "RENDER0",
            renders: 1,
            show_all_industries: false,

            autocomplete: {
                styles: {
                    defaultInput: "c-form__input w-full"
                },
                service_list: [],
                industry_list: [],
                salon_names: []
            },
            userAgent: window.navigator.userAgent,
            console: console,

        }
    },



    computed: {

        // ...mapState(['search_filter']),

        search_params_valid() {
            return( this.service_id || this.industry_id )&& this.location && this.location.lat && this.location.lng ? true : false;
        },

        service_id: {
            get() {
                return this.search_filters.service_id &&
                    this.autocomplete.service_list.length ?
                        this.autocomplete.service_list.find(service => service.id == this.search_filters.service_id) : null;
            },

            set(toSet) {

                if (toSet) {
                    this.search_filters.service_id = toSet.id;
                }
            }
        },

        industry_id: {
            get() {
                return this.search_filters.industry_id && this.autocomplete.industry_list.length ? this.autocomplete.industry_list.find(industry => industry.id == this.search_filters.industry_id) : null;
            },

            set(toSet) {

                if (toSet) {
                    this.search_filters.industry_id = toSet.id;
                }
            }
        },

        location: {

            get() {
                return this.search_filters.location
                ?
                    this.search_filters.location
                :
                    null;
            },

            set(toSet) {
                if (toSet) {
                    this.search_filters.location = toSet;
                }
            }
        },

        date_parsed: {
            get() {
                return Array.isArray(this.search_filters.dates)
                ?   this.search_filters.dates.map(date => {
                        return date instanceof Date ? date : new Date(date)
                    })
                :   null;
            },

            set(toSet) {
                this.search_filters.dates = toSet;
            }
        },

        other_dates() {
            return  this.search_filters.dates ? this.search_filters.dates.filter(date => moment(date).startOf('day').diff(moment().startOf('day'), 'days') > 3).length : null;
        },

        quick_pick_dates() {
            const days = 4;
            let toReturn = [];

            for(let i = 0; i < days; i++) {
                toReturn[i] = {
                    date: moment().startOf('day').add(i, 'days').toDate()
                }
            }

            return toReturn;
        },


        service_error() {
            return this.attempted_search && !this.industry_id;
        },

        industry_error() {
            return !this.service_id && (this.industry_id ? (this.industry_id.name == this.$refs.industry_field.text ? false : true) : (this.attempted_search ? true : false));
        },


        location_error() {
            return !this.location_focus && this.attempted_search && !this.location_valid;
        },

        location_research_required() {
            return !this.location_valid;
        //    return !this.location || (this.location && !this.location.name) || (this.location && this.location.name !== this.location_query);
        },

        location_valid() {
            return this.search_filters.location && this.search_filters.location.lat && this.search_filters.location.lng ? true : false;
            // return this.location && this.location.name == this.location_query && this.search_params_valid;
        },

        sorted_industry_list() {
            let default_list = [
                'Hair',
                'Nails',
                'Hands & Feet',
                'Lashes',
                'Brows',
                'Barbers',
                'Spa'
            ];
            let list = [];
            default_list.forEach(name => {
                let ind = this.autocomplete.industry_list.find(ind => ind.name === name);
                if (ind) list.push(ind);
            });
            
            if (this.show_all_industries) {
                list = [
                    ...list,
                    ...this.autocomplete.industry_list.filter(ind => (!default_list.includes(ind.name))).sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    })
                ];
                
            } else {
                let selected = this.industry_id ? this.autocomplete.industry_list.find(ind => ind.id=== this.industry_id.id) : null;
                if (selected && !list.includes(selected)) {
                    list.push(selected);
                }
            }
            return list;
        },


    },

    mounted() {
        this.fetchServiceList();
        this.fetchBusinessNamesList();

        // if(!this.location && isMobileOnly) {
        //     this.getDeviceLocation();
        // }

        if(this.location && this.location.name) {
            this.location_query = this.location.name;
        }
    },


    methods: {
        industryBlurred() {
            if (this.$refs.industry_field.textLength < 1) {
                this.industry_id = {id: null};
            }
            else {
                let top = this.$refs.industry_field.$data.suggestions[0];
                if (top) {
                    this.industry_id = top;
                    this.$refs.industry_field.setText(top.name);
                }
            }
        },


        openSalon(salon) {
            if (salon) {
               
                window.location = salon.link;
            }

        },
        focusLocation() {
            this.location_focus = true;

            if (this.location_query === "My Location") {
                this.location_query = null;
            }
        },

        doSearchIfValid() {
            this.attempted_search = true;
            if (this.location_valid) {
                let endpoint = this.endpoint;
                let location = new URLSearchParams(this.search_filters.location).toString();
                // let dates = new URLSearchParams(this.search_filters.dates.map(date => date.toString())).toString();
                let url = `/results?${location}&mobile=${this.search_filters.mobile ? 1 : 0}`;
                if (this.search_filters.service_id) url = `${url}&service_id=${this.search_filters.service_id}`;
                if (this.search_filters.industry_id) url = `${url}&industry_id=${this.search_filters.industry_id}`;
            
                this.search_filters.dates.forEach(date => url = `${url}&dates[]=${moment(date).format("YYYY-MM-DD")}`);
                this.search_filters.times.forEach(time => url = `${url}&times[]=${time}`);
                window.open(url, '_self');
            }

            if (this.location_research_required) {
                this.geoSearch().then(() => {
                    if (this.location_valid) {
                        window.location = this.endpoint
                    }
                });

            }
        },
        fetchServiceList() {
            let param = this.industry?.id ? `?industry_id=${this.industry_id?.id}` : '';
            axios.get(`${LIVE_SEARCH_SERVICES}${param}`).then(res => {
                this.autocomplete.service_list = res.data.data;
            });
            axios.get(`${LIVE_SEARCH_INDUSTRIES}`).then(res => {
                this.autocomplete.industry_list = res.data.data;
            });
        },

        fetchCityList() {
            axios.get(`${LIVE_SEARCH_CITIES}`).then(res => {
                this.autocomplete.city_list = res.data.data;
            });
        },

        fetchBusinessNamesList() {
            axios.get(`${LIVE_SEARCH_BUSINESS}`).then(res => {
                this.autocomplete.salon_names = res.data.data;
            });
        },

        dateAsWord(date, name_only = false) {
            switch (moment(date).startOf('day').diff(moment().startOf('day'), 'days')) {
                case 0:
                    return "Today";
                    break;

                case 1:
                    return "Tomorrow";
                    break;

                default:
                    return name_only ? moment(date).format('dddd') : moment(date).format('dddd Do MMMM')
            }
        },

        async geoSearch() {
            if (!(this.location_query && this.location_query.length)) return;
            await axios.get(LIVE_SEARCH_GEOLOCATION(this.location_query)).then(res => {

                if(res.data.status == "OK") {
                    this.search_filters.location = {
                        lat: res.data.candidates[0].geometry.location.lat,
                        lng: res.data.candidates[0].geometry.location.lng,
                        name: res.data.candidates[0].formatted_address
                    };

                    this.location_query = res.data.candidates[0].formatted_address;
                    this.location_zero_results = false;
                }

                else {
                    this.location_zero_results = true;
                }

                this.location_focus = false;
            });
        },

        getDeviceLocation() {

            this.$getLocation().then(coords => {
                this.location = {
                    id: null,
                    name: "My Location",
                    lat: coords.lat,
                    lng: coords.lng
                };

                this.location_query = "My Location";
            }).catch(res => {
                //alert("We can't access your device location!");
                console.log("Can't access location from device");
            });
        },

        warnParams() {
            this.service_id = 'error';
            this.location_focus = false;
            this.location_zero_results = true;
        },

        customFilter(list, query) {
            let options = {
                    shouldSort: true,
                    threshold: 0.4,
                    location: 0,
                    distance: 90,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name",
                    ]
                };

            let fuse = new Fuse([list], options);
            return fuse.search(query).length ? true : false;
        },

        setIndustry(industry) {
            if (this.industry_id?.id === industry.id) {
                this.industry_id = {
                    id: null, 
                    name: null,
                }
            } else {
                this.industry_id= industry

            }
        },



    },

    watch: {
        // industry_id(ind_id){
        //     this.service_id = {id: null};
        //     this.search_filters.service_id = null;
        //     // this.$refs.service_field.setText('');
        //     let param = ind_id?.id ? `?industry_id=${ind_id?.id}` : '';
        //     axios.get(`${LIVE_SEARCH_SERVICES}${param}`).then(res => {
        //         this.autocomplete.service_list = res.data.data;
        //     });
        // }
    }
}
</script>

<style lang="scss" scoped>

    .location-clear {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
    }

    .filler {
        flex: 1;
    }
    .c-datepicker {
        @apply absolute left-0;
        top: calc(100% + 0.5rem);
    }

    .c-form__label-as-btn .checked-sim {
        @apply bg-grey-400 text-white;
    }

    .c-hero-forms, .c-hero-forms__form, .c-hero-forms__form>div.middle {
        min-height: 100%;
    }

    .srch-btn {
        min-height: 40px;
    }

    .industries {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -5px;

        .industry-block {
            transition: color 0.2s, background-color 0.2s;
            cursor: pointer;
            font-weight: bold;
            font-family: poynter;
	        font-size: 16px;
            height: 34px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            margin: 5px;
            border:  1px solid #ababab;
            color: #3D3A3A;
            box-sizing: border-box;

            &.selected {
                color: #fff;
                background-color: #B56D22;
                border-color: #B56D22;
            }

            &:hover:not(.selected) {
                border-color: #B56D22;
                color: #B56D22;
            }

            .industry-name.firefox {
                // height: 18px;
            }
        }
    }

    .more-button {
        font-size: 0.9rem;
    }

</style>
