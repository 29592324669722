<template>
   <carousel class="story-carousel story-carousel--colors">
       <slide class="story-carousel__slide" v-for="(image, index) in images" :key="`image-${index}`">
           <img :src="image"/>
       </slide>
   </carousel>
</template>

<script>

export default {
    props: ['images']
}
</script>

<style lang="scss" scoped>
.story-carousel {
    height: 300px;

    &::v-deep .vs-carousel__wrapper {
        align-items: center;
        height: 300px;
        // justify-content: center
        li {
            height: 300px;
            img {
                max-height: 100%;
            }
        }
    }
    ::v-deep button {
        background-color: #ebebeb;
        width: 35px;
        height: 35px;
    }
}

</style>
