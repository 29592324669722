<template>
    <div>
        <div v-for="(option, i) in options" :key="i" class="c-simulated-checkbox">
            <input type="radio" :disabled="disabled !== null" :name="name" :id="option.value+name" v-model="the_model" :value="option.value" @click="preventSelect($event, i); $emit('updated', the_model)" class="c-simulated-checkbox__input">
            <label :for="option.value+name" class="c-simulated-checkbox__label">{{ option.label }}</label>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            the_model: 'false'
        }
    },

    props: {
        name: {
            type: String,
            required: true
        },

        currentValue: {
            type: String,
            default: 'false'
        },

        options: {
            type: Array,
            required: true
        },

        disabled: {
            default: null
        }
    },

    methods: {
        preventSelect(e, i) {
            if (this.options[i].uncheckable) {
                e.preventDefault();
                alert(this.options[i].uncheckable);
            }

        }
    },

    mounted() {
        this.the_model = this.currentValue;
    }
}
</script>
