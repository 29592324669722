var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ],
      staticClass: "c-popup"
    },
    [
      _c("div", [
        _c("h2", { staticClass: "c-popup__title" }, [
          _vm._v(_vm._s(_vm.params.title))
        ]),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.params.body) } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }