import Vue from 'vue';
import Vuex, { mapState, mapMutations, mapActions } from 'vuex';
import VueCookies from 'vue-cookies';
import { directive as onClickaway } from 'vue-clickaway2';
import StarRating from 'vue-star-rating';
import { RESERVE_AVAILABILITY, CAN_BOOK, BUSINESSES_CONTACT_PATH } from '@js/endpoints';
import moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps';
import { store } from '@js/store';
import LongPress from 'vue-directive-long-press';
import * as Sentry from '@sentry/vue';



require('./bootstrap');
// Sentry.init({
//     Vue: Vue,
//     dsn: 'https://7aeb7dd4279c4ae98cac281ef9e9f1c3@142.93.43.25:9000/3',
//     enableInDevelopment: true,
//     debug: true
// });


Vue.use(VueCookies);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GOOGLE_MAPS_API
    }
});

// Vue.directive('long-press', LongPress);
Vue.directive('init-tmp', {
    bind: function (el, binding, vnode) {
        vnode.context.$set(vnode.context.tmp, binding.arg, binding.value)
    }
});


Vue.directive('back-btn', {
    bind: (el, binding, vnode) => {
        el.addEventListener('click', function (event) {
            event.stopPropagation();
            event.preventDefault();
            history.back();
        });
        el.style.cursor = 'pointer';
    }
})
Vue.directive('onClickaway', onClickaway);
Vue.mixin({
    methods: {
        formatDuration(duration) {
            let regex = /(\d\d):(\d\d):(\d\d)/;
            let matches = duration.match(regex);
            // console.log(matches);
            let [all, hours, minutes, seconds] = matches;
            let output = "";
            if (parseInt(hours)) {
                if (parseInt(minutes) ) return `${parseInt(hours)}${hours > 1 ? 'hrs' : 'hr'} ${parseInt(minutes)}mins`
                else return `${parseInt(hours)}hrs`;
            } else if (parseInt(minutes)) {
                return `${parseInt(minutes)}mins`
            }
            else return "";
        },

        formatTime(date) {
            return moment.utc(date).format('HH:mm');
        },

        splitTime(timeToParse, nullVal = 'N/A') {
            let time = timeToParse.split(':');

            let output = '';

            if (time[0] !== '00') {
                output += parseInt(time[0]) + 'hr ';
            }

            if (time[1] !== '00') {
                output += parseInt(time[1]) + 'min ';
            }

            return output || nullVal;
        },

        // toCurrency(figure, symbol = '£') {
        //     return typeof figure == 'number' ? `${symbol}${figure.toFixed(2)}` : 'N/A';
        // },

        toCurrency(figure, symbol = '£') {
            if (figure === 0) return "FREE";
                return typeof figure == 'number' ? `${symbol}${figure.toFixed(2).replace('.00', '')}` : 'N/A';
        },
    },
});


//Automatic Component Import
const files = require.context('./', true, /^((?![\\/]admin[\\/]).)*\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
import { LIVE_SEARCH_SERVICES, LIVE_SEARCH_INDUSTRIES } from '@js/endpoints';


const app = new Vue({
    store,
    components: {
        StarRating
    },
    data: {
        submitting: false,
        tmp: {
            subscription: null,
            rating: 0
        }
    },
    methods: {
        ...mapMutations([
            'mutateOverlayModalContent',
            'mutateOverlayVisibility',
            'mutateBookerVisibility',
            'mutateBookerDetails',
            'mutateBookerSelectedAvailabilityId',
            'mutateBookerPreselectedService',
            'mutateComponentParamsLoginForm',
            'mutateMiscAuthenticated',
            'mutateBookerSelectedStage',
            'mutateAvailabilityDrawerVisibility',
            'mutateAvailabilityDrawerBusinessId',
            'mutateQuickLookDrawerVisibility',
            'mutateQuickLookDrawerId',
            'mutateSearchFilterFieldsServiceId',
            'mutateSearchVisibility',
            'mutateFilterDrawerVisibility',
            'mutateMenu'
        ]),

        ...mapActions([
            'showLoginForm',
            'initBooking',
            'showVimeoVideo',
            'showModalAlert',
            'showMobileFilters',
            'showAppointmentCancel',
            'showDemoRequestForm']),

        preventDoubleSubmit(e) {
            e.preventDefault();
            window.onbeforeunload = null;
            this.submitting == false ? this.submitting = true : console.log('double submission prevented');
            this.submitting ? e.target.submit() : null;
        },

        track(send_to, e) {
            let callback = () => {
                if (typeof (e.target.href) != 'undefined') {
                    window.location = e.target.href;
                }
            };
             gtag('event', 'conversion', {
                 'send_to': send_to,
                 'event_callback': callback
             });
            return false;
        },

        warnUnsaved() {
            window.onbeforeunload = event => {
                event.preventDefault();
                event.returnValue = '';
            };
        },
    },

    computed: {
        ...mapState([
            'booker',
            'availability_drawer',
            'service_drawer',
            'quick_look_drawer',
            'search_visibility',
            'search_filter',
            'misc',
            'overlay',
            'components',
            'filter_drawer',
            'menu'
        ]),

    },

    mounted() {
        this.mutateMiscAuthenticated(this.authenticated);

        axios.get(`${LIVE_SEARCH_SERVICES}`).then(res => {
            this.$store.commit('mutateServices', res.data.data);
        });
        axios.get(`${LIVE_SEARCH_INDUSTRIES}`).then(res => {
            this.$store.commit('mutateIndustries', res.data.data);
        });
    },

    watch: {
        'search_filter.fields': {
            deep: true,
            handler(new_filter, old_filter) {
                if (new_filter.industry_id !== old_filter.industry_id) {
                    this.search_filter.fields.service_id = null;
                }
                $cookies.set('search_filter', this.search_filter.fields, '10min');

            }
        },

        'tmp.subscription'() {
            switch (this.tmp.subscription) {
                case "custom":
                    this.showModalAlert({
                        title: "Apologies, but..",
                        body: `You'll need to <a href="${BUSINESSES_CONTACT_PATH}" class="underline">get in touch</a> so that we can tailor a package to your requirements.`
                    });
                    break;

                case "1":
                    this.showModalAlert({
                        title: "Just so you know..",
                        body: `ID may be required to verify your student status.`
                    });
                    break;

            }

        }

    },


    el: '#app',
});


// Temporary home page video resize
document.addEventListener("DOMContentLoaded", function () {
    const heroVideo = document.body.querySelector('[data-hero-video]');

    // Resize videos to fill area
    if (heroVideo) {
        window.addEventListener("resize", resizeHeroVideo(heroVideo));
        window.addEventListener("load", resizeHeroVideo(heroVideo));
    }
});

function resizeHeroVideo(heroVideo) {
    var parentHeight = heroVideo.parentNode.offsetHeight;
    var parentWidth = heroVideo.parentNode.offsetWidth;
    var scaleFactorWidth = 16;
    var scaleFactorHeight = 9;
    var scaleFactor = scaleFactorWidth / scaleFactorHeight;

    if (scaleFactor / (parentWidth / parentHeight) <= 1) {
        heroVideo.style.cssText = "height: " + parentWidth / scaleFactor + "px; width: " + parentWidth + "px;";
    } else {
        heroVideo.style.cssText = "height: " + parentHeight + "px; width: " + (parentHeight * scaleFactor) + "px;";
    }

    heroVideo.classList.add('c-video-hero__video--visible')
}
