<template>
    <div>
        <label v-if="label" class="c-form__label">{{ label }}</label>
        <select :name="name" v-model="current_value" class="c-form__select w-full" @change="$emit('updated')" :disabled="disabled !== null">
            <option disabled :value="null">Please select</option>
            <option v-for="(value, i) in values" :value="value.value" :key="i">{{ value.label }}</option>
        </select>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current_value: null
        }
    },

    props: {
        label: {
            type: String,
            required: false
        },

        name: {
            type: String,
            required: true
        },

        values: {
            type: Array,
            required: true
        },

        currentValue: {
            type: String,
        },

        disabled: {
            default: null
        }
    },

    mounted() {
        this.current_value = this.currentValue ? this.currentValue : this.values[0].value;
    }
}
</script>
