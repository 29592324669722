<template>
    <div class="relative flex items-center">
        <div class="c-filter-option">
            <div class="c-form__checkbox-as-toggle">
                <input type="checkbox" :id="selected" v-model="current_value" :true-value="1" :false-value="0">
                <label :for="selected">{{ selected_label }}</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        options: {
            type: String //refers to $store search_filter.options.XXXX
        },

        value: {

        }
    },
    data() {
        return {
            current_value: this.value || null
        }
    },

    watch: {
        value(newvalue) {
            this.current_value = newvalue;
        },
        current_value(new_value) {
            this.$emit('input', new_value);
        },
    },

    computed: {
        ...mapState(['search_filter']),
        selected_label() {
            return this.search_filter.options[this.options].find(item =>
            item.value == this.value
            ).label;
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-filter-option {
        @apply border-none;
    }
</style>
