<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)" style="padding:1rem;">
        <div class="c-iframe-video__wrapper">
            <iframe :src="params.iframeSrc" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen class="c-iframe-video__video"></iframe>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            params: state => state.component_params.vimeoVideo
        })
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
    },
}
</script>

<style lang="scss" scoped>
    .c-iframe-video {
        &__wrapper {
            @apply relative;
            padding-top: 56.25%;
        }

        &__video {
            @apply absolute top-0 bottom-0 left-0 right-0 h-full;
        }
    }
</style>