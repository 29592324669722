<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)">
        <h1 class="text-center text-copper-400">Filter</h1>

        <div class="c-mobile-filter-group">
            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Service</span>
            <filter-suggest label="Service" :endpoint="LIVE_SEARCH_SERVICES" selected="service_id" to-set-prop="id"></filter-suggest>
        </div>

        <div class="c-mobile-filter-group">
            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Location</span>
            <filter-location label="Location"></filter-location>
        </div>

        <div class="c-mobile-filter-group">
            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Date</span>
            <filter-date label="Date" selected="dates" :interacting-default="false"></filter-date>
        </div>

        <div class="c-mobile-filter-group">
            <span class="text-base font-bold text-grey-1000 block text-center mb-2">Time</span>
            <filter-checkbox label="When" options="times" selected="times"></filter-checkbox>
        </div>

        <div class="c-filter-update-msg">
            <i class="fas fa-check-circle"></i>
            <span class="px-2">Search Results Updated</span>
            <i class="fal fa-times"></i>
        </div>
    </div>
</template>

<script>
import { LIVE_SEARCH_SERVICES } from '@js/endpoints';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            LIVE_SEARCH_SERVICES
        }
    },
    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            // params: state => state.component_params.loginForm
        })
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
        ...mapActions(['showResetPassword']),
    }
}
</script>

<style lang="scss" scoped>
    .c-mobile-filter-group {
        @apply py-8 border-t border-b border-grey-300;
    }

    .c-filter-update-msg {
        @apply absolute py-2 px-4 border border-green-500 bg-teal-400 text-grey-1000 rounded;
        top: -2rem;
        left: 50%;
        transform: translate(-50%, -100%);
        white-space: nowrap;
    }
</style>
