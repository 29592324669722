<template>
    <div class="c-tooltip" v-on-clickaway="() => toolTipOpen = false" @click="toggleToolTip">
        <span class="c-tooltip__trigger" >
            <span v-if="triggerText" class="text-grey-1000 flex-grow-0 flex-shrink">{{ triggerText }} </span>
            <i class="fas fa-info" style="font-size:8px;"></i>
        </span>
        <div v-if="toolTipOpen" @click.stop="toolTipOpen=false" class="c-tooltip__content"   ref="tooltip" :style="tooltipContentStyle">
            <span>{{ content }}</span>
            <span role="button" aria-label="Close tooltip" class="c-tooltip__close" @click="toolTipOpen = false">
                <i data-v-45bb487d="" class="far fa-times"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        triggerText: {
            type: String,
            required: false
        },

        content: {
           type: String,
           required: true
       }
    },
    data() {
        return {
            toolTipOpen: false,
            tooltipTop: 0,
            tooltipLeft: 0
        }
    },

    methods: {
        toggleToolTip(event) {
            this.toolTipOpen = !this.toolTipOpen;

            // Set tooltip position:
            if (this.toolTipOpen) {
                let {left, top} = event.target.getBoundingClientRect();
                this.tooltipLeft = left;
                this.tooltipTop = top;
            }
        },

        isOutOfViewport(elem) {
            // Get element's bounding
            var bounding = elem.getBoundingClientRect();

            // Check if it's out of the viewport on each side
            var is_out = {};
            is_out.top = bounding.top < 0;
            is_out.left = bounding.left < 0;
            is_out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
            is_out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
            is_out.any = is_out.top || is_out.left || is_out.bottom || is_out.right;
            is_out.all = is_out.top && is_out.left && is_out.bottom && is_out.right;

            return is_out;
        },
    },

    computed: {
        tooltipContentStyle() {
            return {
                'left': `calc(${-this.tooltipLeft}px + 2rem)`,
                'top': `20px`
            }
        }
    },

    watch: {
        toolTipOpen(open) {
            if (open)  {
                // Scroll tooltip into view if neccessary.
                this.$nextTick(() => {
                    let elem = this.$refs.tooltip;
                    if (this.isOutOfViewport(elem).any) {
                        elem.scrollIntoView(false);
                    }
                });
            }
        }
    }

} 
</script>

<style lang="scss" scoped>
.c-tooltip {
    @apply relative inline-block cursor-pointer items-center;

    i {
        @apply bg-black text-white flex items-center justify-center rounded-full ml-1 flex-shrink-0 flex-grow-0 pointer-events-none;
        width: 15px;
        height: 15px;
    }

    

    &__close {
        @apply absolute top-0 right-0 bg-grey-1000 shadow-lg-allsides text-black text-lg rounded-full cursor-pointer block;
        transform: translate(50%, -50%);
        width: 25px;
        height: 25px;
        transition: 0.4s ease;

        @screen md {
            @apply hidden;
        }

        i {
            @apply absolute text-sm text-white ml-0; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            transform: translate(50%, -50%) scale(1.05);
        }
    }
}

.c-tooltip__trigger {
    @apply cursor-pointer flex flex-wrap items-center;

    i {
        @apply bg-black text-white flex items-center justify-center rounded-full ml-1 flex-shrink-0 flex-grow-0;
        width: 15px;
        height: 15px;
    }
}


.c-tooltip__content {
        @apply absolute w-screen bg-grey-1000 text-white text-center p-4 z-10 text-sm rounded-lg;
        width: calc(100vw - 4rem);
        transition: 0.4s ease;
        z-index: 9999999; //Has to be this high to overlap chatbot

        @screen lg {
            max-width: 350px;
        }

        &::before {
            @apply absolute bg-grey-1000 hidden;
            content: "";
            width: 10px;
            height: 10px;
            left: 6px;
            bottom: -9px;
            transform: rotate(45deg) translateX(-50%);
            
            // @screen md {
            //     @apply block;
            // }
        }

        &--visible {
            @apply opacity-100 pointer-events-auto;
        }
    }
</style>