<template>
    <div class="suggestion-wrapper" v-show="match_found && !Object.keys(appointment).length">
        <a role="button" :class="className" @click="initAvailabilityDrawer(businessId); mutateQuickLookDrawerVisibility(false)">
            Book {{ service ? service.name : null }}
            <span class="clear-btn" @click.stop="clearSuggestion">
                <i class="fal fa-times"></i>
            </span>
        </a>
        <!-- <span class="clear-btn hide" >
            <i class="fal fa-times"></i>
        </span> -->
    </div>
</template>

<script>
import { SERVICE, BUSINESS_AVAILABILITY } from '@js/endpoints';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    data() {
        return {
            service:null,
            match_found:null,
        }
    },

    props: {
        businessId: {
            required: true,
            type: Number
        },

        className: {
            default: 'btn btn--copper cta'
        }
    },

    computed: {
        ...mapState({
            service_id: state => state.search_filter.fields.service_id,
            appointment: state => state.booker.appointment,
        })
    },

    methods: {
        ...mapMutations(['mutateQuickLookDrawerVisibility']),
        ...mapActions(['initAvailabilityDrawer']),

        getService() {
            axios.get(SERVICE(this.service_id)).then(res => {
                this.service = res.data.data;
                this.getAvailabilityCount();
            });
        },

        getAvailabilityCount() {
            axios.get(`${BUSINESS_AVAILABILITY}/${this.businessId}`, { params: {
                service_id: this.service_id
            }}).then(res => {
                this.match_found = res.data.data.length;
            });
        },

        clearSuggestion() {
            this.match_found= null;
            this.$store.commit('setSearchFilters', {
                mobile: 0,
                times: [],
                service_id: null,
                // service_id: 99, //Catch up
                dates: [],
                location: {
                    id: null,
                    name: "All",
                    lat: null,
                    lng: null
                }});
        }
    },

    watch: {
        service_id() {
            this.getService();
        }
    },

    mounted() {
        this.service_id ? this.getService() : null;
    }
}
</script>

<style lang="scss" scoped>
    .suggestion-wrapper {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display :flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        opacity: 0;
        animation: fadeUp .4s ease 1 forwards;
    }

    .clear-btn {
        padding: 5px 0px;
        text-align: center;
        cursor: pointer;
        font-size: 25px;
        line-height: 25px;
        background-color: darken(#b16d00, 5%);
        margin-top: -4px;
        margin-right: -16px;
        margin-bottom: -8px;
        height: 35px;
        width: 35px;
        margin-left: 16px;
        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    .cta {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        overflow: hidden;
        @apply shadow-lg  text-center;

        &.btn {
            padding-top: 4px;
            padding-bottom: 8px;
        }
    }

    @keyframes fadeUp {
        100% { opacity: 1; transform: translate(0, -50%); }
    }
</style>
