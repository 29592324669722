<template>
    <div>
        <span v-if="results_message" class="mb-4 font-semibold mx-4 inline-block">{{results_message}}</span>

        <div class="flex flex-wrap md:-mx-3 -mt-6">
            <div class="md:px-3 w-6/12 mt-6" ref="scroll_area">

                <template v-if="!showSkeleton">
                    <search-result-map :ref="`result-business-${availability.business_id}`" 
                        v-for="(availability, index) in results" :key="`search-result-map-avail-${availability.id}`" 
                        :availability="availability"
                        @click="handleResultClick(index)"
                        :isLoggedIn="isLoggedIn"
                        :index="index"
                        :active_result_index="active_result_index"
                    />

                     <!-- INCLUDE UNAVAILABLE BUSINESSES -->
                    <search-result-map  :ref="`result-business-${business.id}`"
                        v-for="(business, index) in nearby" :key="`business-${business.id}`" 
                        :business="business"
                        @click="handleResultClick(index + results.length)"
                        v-on:toggle-favourite="$emit('toggle-favourite', $event)"
                        :isLoggedIn="isLoggedIn"
                        :index="results.length +index"
                        :active_result_index="active_result_index"
                    >
                        
                    </search-result-map>
                </template>

                <template v-if="showSkeleton">
                    <div class="w-full flex-wrap shadow-lg-allsides mb-5 hidden md:flex" v-for="i in 4" :key="i">
                        <div class="w-full lg:w-40 skeleton-image" style="min-height:125px;"></div>
                        <div class="flex flex-wrap items-center justify-between p-6 flex-grow">
                            <div class="pr-6 mb-4 xl:mb-0 w-full xl:w-9/12">
                                <span class="skeleton-text w-6/12"></span>
                                <span class="text-copper-400 font-poynter font-bold text-2xl block skeleton-text w-8/12"></span>
                                <p class="mb-0 text-base skeleton-text w-10/12"></p>
                            </div>
                            <div class="w-full xl:w-2/12 flex items-center text-center">
                                <span class="w-full skeleton-text"></span>
                            </div>
                        </div>
                    </div>
                </template>

            </div>

            <div class="w-screen flex flex-col" style="height:calc(100vh - 95px);">
                <gmap-map :center="centre" class="c-map w-full flex-grow flex-shrink" :zoom="7" ref="searchMap">
                    <gmap-marker v-for="(marker, index) in markers" :key="index" :position="{lat:parseFloat(marker.lat), lng:parseFloat(marker.lng)}" @click="handleMarkerClick(marker, index)" :icon="generateIconObj(index)"></gmap-marker>
                </gmap-map>
                <div class="container mx-auto flex-grow-0 flex-shrink-0 py-4 text-center text-sm uppercase text-grey-1000 font-semibold">
                    <span>Click to view more info or book</span>
                </div>
            </div>

            <!-- <div class="section-pt--md text-center w-full mb-12" v-if="Array.isArray(results) && !results.length && !showSkeleton">
                <div class="container mx-auto">
                    <img src="/images/headshots/no-results.png" aria-hidden="true" class="no-results-img">
                    <h2 class="mb-2">Oh no!</h2>
                    <p class="mb-0 text-black text-base w-full">
                        It looks like our businesses are busy at these times. Try making the search less specific, if you can
                    </p>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {

    data() {
        return {
            bounds: null,
            centre: { lat: 0, lng: 0 },
            map_height: '400px'
        }
    },

    props: {
        results: {
            type: Array,
            default: []

        },

        showSkeleton: {
            type: Boolean,
            default: false
        },

        isLoggedIn: {
            type: Boolean,
            default: false
        },

        search_filter: {},

        // If the API returned more 'relaxed' parameters than what were entered, in order to improve results:
        results_message: {
            type: String,
        },

        nearby: {},
    },

    computed: {
        markers() {
            if (this.results) {
                return [
                    ...this.results.map(item => {
                        return {
                            lat: item.business.lat,
                            lng: item.business.lng,
                            business_id: item.business.id,
                        }
                    }),
                    ...this.nearby.map(business => ({
                        lat: business.lat,
                        lng: business.lng,
                        business_id: business.id,
                    })),
                ]
            }

            return [];
        },



        ...mapState(['active_result_index'])
    },

    methods: {

        ...mapMutations(['mutateActiveResultIndex']),
        ...mapActions(['initBooking','initQuickLook', 'initAvailabilityDrawer']),
        autoCentreMap() {
            if (this.$refs.searchMap) {
                this.$refs.searchMap.$mapPromise.then((map) => {
                    this.bounds = new google.maps.LatLngBounds();
                    this.markers.forEach(marker => {
                        this.bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
                    });
                    map.fitBounds(this.bounds);
                    map.panToBounds(this.bounds);
                });
            }
        },

        setCentre(marker) {
            this.centre = { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) };
        },

        handleMarkerClick(marker, index, business_id) {
            this.mutateActiveResultIndex(index);
            let ref = `result-business-${marker.business_id}`
            this.scrollToIndex(ref);
            this.setCentre(marker);
        },

        handleResultClick(index) {
            this.mutateActiveResultIndex(index);
            this.setCentre(this.markers[index]);
        },

        generateIconObj(index) {
            return {
                url: `/images/icons/${this.active_result_index == index ? 'pin-active' : 'pin'}.png`,
                size: {
                    width: 240,
                    height: 240,
                },

                scaledSize: {
                    width: this.active_result_index == index ? 70 : 40,
                    height: this.active_result_index == index ? 70 : 40,
                },

                anchor: {
                    x: this.active_result_index == index ? 35 : 20,
                    y: this.active_result_index == index ? 70 : 40
                }
                // origin: new
            };
        },

        scrollToIndex(index) {
            let item = this.$refs[index][0];
            console.log("Scroll to Index:",index, item );
            item[0].scrollIntoView({behavior: "smooth"});
        }
    },

    watch: {
        results() {
            if (this.markers.length) {
                this.autoCentreMap();
            }
        }
    },

    mounted() {
        this.autoCentreMap();
        this.map_height = this.$refs.hasOwnProperty('scroll_area') ? (this.$refs.scroll_area.clientHeight ? this.$refs.scroll_area.clientHeight + 'px' : '100vh' ) : this.map_height;
        window.addEventListener('resize', () => {
            this.map_height = this.$refs.hasOwnProperty('scroll_area') ? (this.$refs.scroll_area.clientHeight ? this.$refs.scroll_area.clientHeight + 'px' : '100vh' ) : this.map_height;
        });
    }
}
</script>

<style lang="scss" scoped>
    .c-result--active {
        @apply fixed bg-white;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        width: calc(100% - 4rem);
    }

    .c-result--active p,
    .c-result--active a,
    .c-result--active span:not(.btn),
    .c-result--active i {

        @screen md {
            color: #ffffff!important;
        }
    }

    .c-map {
        height: 70vh;
        @screen lg {
            max-height: 100vh;

        }
        @screen md {
            position: sticky;
        }
        top: 0px;
    }
</style>
