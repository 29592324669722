<template>
    <div class="relative flex items-center ml-2">
        <div class="c-filter-option">
            <div class="c-form__checkbox-as-toggle no-color-toggle">
                <span :for="selected" class="switch-label" :class="{selected: !current_value}">
                    {{ labels.false }}
                </span>

                <input type="checkbox" :id="selected" v-model="current_value" :true-value="1" :false-value="0">
                <label class="mx-2 pl-40px" :for="selected"/>

                <span :for="selected" class="switch-label" :class="{selected: current_value}">
                    {{ labels.true }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        options: {
            type: String //refers to $store search_filter.options.XXXX
        },

        value: {

        },
        labels: {
            type: Object,
            default: () => ({
                true: 'My Home',
                false: 'In Salon'
            })
        }
    },
    data() {
        return {
            current_value: this.value || null
        }
    },

    watch: {
        value(newvalue) {
            this.current_value = newvalue;
        },
        current_value(new_value) {
            this.$emit('input', new_value);
        },
    },

    computed: {
        ...mapState(['search_filter']),
        selected_label() {
            return this.search_filter.options[this.options].find(item =>
            item.value == this.value
            ).label;
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-filter-option {
        @apply border-none;
    }

    .switch-label:not(.selected) {
        opacity: 0.75;
        transition: opacity 0.3s;
    }

    .pl-40px {
        padding-left: 40px;
        padding-right: 4px;
    }
</style>
