<template>
    <div class="booker-location">

        <gmap-map class="h-full w-full" :center="coords" map-type-id="terrain" style="height: 300px" :zoom="15" :options="{ disableDefaultUi: true, zoomControl: true, mapTypeControl: false, streetViewControl: true, fullscreenControl: false, controlSize: 30 }">
            <gmap-marker :position="coords" :draggable="true" @dragend="setNewPosition"></gmap-marker>
        </gmap-map>
        <div class="flex flex-col flex-wrap justify-between mb-4">
            <input id="business-address" v-model="address_search" @keydown.enter="geoSearch" placeholder="" name="address" class="c-input c-input--text flex-1">
            <span class="find-address flex-0 underline hover:no-underline text-black" @click.prevent="geoSearch" role="button">{{ searching ? 'Locating...' : 'Find address on map' }}</span>
        </div>
    </div>
</template>

<script>
import { LIVE_SEARCH_GEOLOCATION } from '@js/endpoints';
import { BUSINESS_ADMIN_DIARIES, BUSINESS_ADMIN_DIARY_SETTINGS, BUSINESS_ADMIN_DIARY_SERVICES, BUSINESS_ADMIN_DIARY_SERVICES_COPY_FROM_BUSINESS, BUSINESS_ADMIN_DIARY_SERVICE_DELETE } from '@js/endpoints';

export default {
    data() {
        return {
            // location_query: [],
            results: {},
            searching: false,
            address_search: this.address.name || "",
            errors: [],
            timeout: null,
            lat: this.address.lat,
            lng: this.address.lng,
        }
    },

    props: ['address'],

    computed: {
        coords() {
            if (this.lat && this.lng) {
                return  {lat: parseFloat(this.lat), lng: parseFloat(this.lng)}
            } else if (this.address) {
                return {lat: parseFloat(this.address.lat), lng: parseFloat(this.address.lng)}
            }
            else return { lat: 53.8007554, lng: -1.5490774 };
        },


        location_query() {
            return this.address_search || null;
        },
    },

    methods: {
        geoSearch() {
            this.searching = true;
            if (!this.lat || !this.lng || !this.address_search) return;
            axios.get(LIVE_SEARCH_GEOLOCATION(this.location_query)).then(res => {
                if (res.data.status == "OK") {
                    this.address_search=res.data.candidates[0].formatted_address;
                    this.lat=res.data.candidates[0].geometry.location.lat;
                    this.lng=res.data.candidates[0].geometry.location.lng;
                    this.update();
                }

                else {
                    alert(`Hmm.. we're struggling to find ${address}. Please try a different search term, or drag the dropper to your desired location`);
                }

                this.searching = false;

            });

        },

        update() {
            this.$emit('LocationUpdated', {
                address: this.address_search,
                lat: this.lat,
                lng: this.lng,
            })
        },


        setNewPosition(e) {
            this.lat =e.latLng.lat(e);
            this.lng =e.latLng.lng(e);
            axios.get(`api/live-search/getlocation?latlng=${this.lat},${this.lng}`).then(response=>{
                let results = response.data.results;
                if (results.length) {
                    this.address_search = results[0].formatted_address;
                    this.lat = results[0].geometry.location.lat;
                    this.lng = results[0].geometry.location.lng;
                }
                this.update();
            });
            this.update();
        },



        forceUppercase() {
            this.location_query = this.location_query.map(item => item.toUpperCase());
        },
    },


    mounted() {
        // this.display_address = this.provider?.diary?.display_address;
        // this.lat = this.provider?.diary?.lat;
        // this.lng = this.provider?.diary?.lng;
    }
}
</script>
<style lang="scss" scoped>
.booker-location {
    width: 100%
}
.display-address {
    label {
        margin-bottom: 0
    }
}

.sublabel {
    font-size: 0.9em
}

.find-address {
    width: auto!important
}


.c-form-group {
    @apply mb-16;

    @screen md {
        @apply mb-20;
    }

    &__row {
        @apply mb-10;
    }
}

label,
.label {
    @apply block mb-4 text-grey-400 capitalize;
}

label.required {
    @apply relative;
    padding-right: 10px;

    &::after {
        @apply absolute text-red-500;
        content: "*";
        top: 50%;
        transform: translateY(-50%);
    }
}

.c-input {
    @apply block w-full text-black bg-transparent rounded-none;
    transition: 0.2s ease;

    &:focus {
        @apply outline-none text-copper-400;

        &::placeholder {
            opacity: 0;
        }
    }

    &::placeholder {
        @apply text-grey-350 italic;
        transition: 0.4s ease;
    }

    &:required {
        + label {
            @apply relative;
            padding-right: 10px;

            &::after {
                @apply absolute text-red-500;
                content: "*";
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &--text,
    &--number {
        @apply border-b border-grey-350 pb-2 appearance-none;
        //If you change this padding, then you need to change the bottom property for .c-request-change-input and .c-price-input-holder and the margin-top of .input-feedback to match

        &:focus {
            @apply border-copper-400 text-copper-400;

            + label {
                @apply text-copper-400;
            }
        }
    }

    &--textarea {
        @apply border border-grey-350 p-2 appearance-none;
        min-height: 200px;
        resize: vertical;

        &:focus {
            @apply border-copper-400 text-copper-400;


            + label {
                @apply text-copper-400;
            }
        }

        &--small {
            min-height: 100px;
        }
    }

    //Disabled styling
    &--text,
    &--number,
    &--textarea {
        &:disabled {
            padding-left: 20px;
            background-position: 0 calc(0.5rem + -1px);
            background-size: 10px;
            background-repeat: no-repeat;
            background-image: url('/images/icons/lock-solid.svg');
        }
    }

    &--select {
        @apply border-b border-grey-350 pb-2 appearance-none;
    }

    &--price {
        padding-left: 20px;
        background-position: 0 calc(0.5rem + -1px);
        background-size: 8px;
        background-repeat: no-repeat;
        background-image: url('/images/icons/pound-sign-solid.svg');
    }
}








</style>

