<template>
    <header class="header">
        <section class="c-pre-nav">
            <div class="hidden md:flex items-center">
                <slot name="pre_nav_left" />
            </div>

            <div class="w-full md:w-auto flex flex-col md:inline-block">
                <slot name="pre_nav_right"/>
            </div>

        </section>

        <section class="hidden lg:flex justify-between flex-col lg:flex-row md:items-center py-4 px-8 relative z-10">
            <slot name="logo"/>

            <nav class="c-primary-nav hidden lg:block flex-grow-0 flex-shrink-0">
                <slot name="main_nav"/>
            </nav>

            <div class="header-cta">
                <slot name="header_cta"/>
            </div>
        </section>

        <div class="relative w-full lg:hidden flex items-center justify-between md:py-2 md:px-8" style="z-index:60;">
            <img src="/images/branding/logo.svg" alt="Eleventh Hour Beauty" class="mobile-header-logo">
            <div class="flex items-center menu-triggers" :class="{'menu-triggers--menu-open' : menu.visible }">

                <slot name="tablet_header_cta"/>

                <span class="flex items-center mr-4 md:hidden" role="button" @click="handleMenuToggle('account')" :class="{'active' : menu.visible && menu.type == 'account'}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 20.25" width="21" height="24" v-if="!menu.visible || menu.visible && menu.type != 'account'">
                        <g id="Layer_2" data-name="Layer 2"><g id="Labels"><path d="M16.31,20.25H1.19A1.19,1.19,0,0,1,0,19.06V17.27a4.91,4.91,0,0,1,4.9-4.9,4,4,0,0,1,1.19.25,7.87,7.87,0,0,0,2.66.44,7.8,7.8,0,0,0,2.65-.44,4.15,4.15,0,0,1,1.2-.25,4.91,4.91,0,0,1,4.9,4.9v1.79A1.19,1.19,0,0,1,16.31,20.25ZM4.9,13.37A3.91,3.91,0,0,0,1,17.27v1.79a.2.2,0,0,0,.19.19H16.31a.2.2,0,0,0,.19-.19V17.27a3.91,3.91,0,0,0-3.9-3.9,3.15,3.15,0,0,0-.9.21,9.34,9.34,0,0,1-5.91,0A3,3,0,0,0,4.9,13.37ZM8.75,9.25a4.63,4.63,0,1,1,4.62-4.63A4.64,4.64,0,0,1,8.75,9.25ZM8.75,1a3.63,3.63,0,1,0,3.62,3.62A3.63,3.63,0,0,0,8.75,1Z"/></g></g>
                    </svg>
                    <span class="c-close-icon" v-if="menu.visible && menu.type == 'account'"></span>
                </span>
                <span role="button" class="relative inline-block" @click="handleMenuToggle('main')" style="width:30px; height:25px;"><span class="c-burger-icon" :class="{'active' : menu.visible && menu.type == 'main'}"></span></span>
            </div>
        </div>


        <!-- Mobile Menu -->
        <transition name="slide-in" mode="out-in">
            <div class="c-mobile-menu" v-if="menu.visible">

                <transition name="fade-slide" mode="out-in">
                    <div v-if="menu.type == 'main'">
                        <div>
                            <div class="c-mobile-menu__primary-links">
                                <slot name="main_nav"/>
                            </div>

                            <div class="c-mobile-menu__secondary-links">
                                <slot name="main_extra_links"/>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="fade-slide" mode="out-in">
                    <div v-if="menu.type == 'account'">
                        <div>
                            <div class="c-mobile-menu__primary-links">
                                <slot name="pre_nav_right" />
                            </div>
                            <div class="c-mobile-menu__secondary-links">
                                <slot name="main_extra_links"/>
                            </div>
                        </div>
                    </div>
                </transition>

                <slot name="header_cta"/>

            </div>
        </transition>

    </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    data() {
       return {};
    },
    computed: {
        ...mapState(['menu']),
    },

    methods: {
        ...mapMutations(['mutateMenu']),
        handleMenuToggle(type) {
            this.menu.type == type ? this.mutateMenu({visible: !this.menu.visible, type : this.menu.type}) : this.mutateMenu({visible: true, type : type});
        },

        filterSlotNodes(array, attr = 'main_extra_links') {
            return array.filter(item => item.data && item.data.attrs && item.data.attrs[attr] === true);
        }
    },

    mounted() {
        this.$slots.main_extra_links = [...this.filterSlotNodes(this.$slots.pre_nav_left), ...this.filterSlotNodes(this.$slots.pre_nav_right)];

    }
}
</script>

<style lang="scss" scoped>

    ::v-deep {
        .tab-search-trigger {
            @apply hidden;
            transition: opacity 0.4s ease;

            @screen md {
                @apply flex;
            }
        }

        .search-trigger {
            @apply absolute inline-block capitalize;
            bottom: 100px;
            right: 30px;
            left: 30px;
        }

        @screen md {
            .search-trigger {
                @apply static;
            }
        }

        .menu-triggers .btn {
            @apply hidden;
            transition: opacity 0.4s ease;

            @screen md {
                @apply inline-block;
            }   
        }

        .menu-triggers--menu-open .btn {
            @apply opacity-0 pointer-events-none;
        }
    }
</style>
