<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)" style="padding:0;">
        <div class="c-blog-slider">
            <div class="w-full lg:w-7/12 p-4 md:p-8 lg:p-10 xl:p-12 relative c-blog-slider__image-holder">
                <div v-for="(slide, index) in blog_single_gallery.slider.slides" :key="index" class="lg:h-full">
                    <div
                        :class="{'c-blog-slider__slide--active' : currentSlide == index}"
                        :style="{ background: 'center / cover no-repeat url('+slide.image.url+')' }"
                        class="c-blog-slider__slide"
                    ></div>
                </div>

                <ul class="flex justify-center relative mb-0" style="z-index:1;">
                    <li
                        v-for="(slide, index) in blog_single_gallery.slider.slides" :key="index"
                        @click="currentSlide = index"
                        :class="{'bg-copper-400' : currentSlide == index}"
                        class="h-4 w-4 rounded-full m-1 bg-white hover:bg-copper-400 cursor-pointer shadow-lg-allsides"
                        style="transition:0.4s ease;"
                    ></li>
                </ul>
            </div>

            <div class="w-full lg:w-5/12 p-4 md:p-8 lg:p-10 xl:p-12" style="max-height:100%;">
                <div class="c-blog-slider__content">
                    <div>
                        <h2>{{ blog_single_gallery.slider.title }}</h2>

                        <div v-for="(slide, index) in blog_single_gallery.slider.slides" :key="index">
                            <div v-if="currentSlide == index">
                                <p>{{ slide.caption }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c-blog-slider__nav">
                    <span
                        v-if="currentSlide > 0"
                        @click="currentSlide = currentSlide-1"
                        role="button"
                        aria-label="Previous slide"
                        class="absolute left-0 bottom-0 underline hover:no-underline cursor-pointer"
                    >Previous</span>
                    <span
                        v-if="currentSlide+1 < blog_single_gallery.slider.slides.length"
                        @click="currentSlide = currentSlide+1"
                        role="button"
                        aria-label="Next slide"
                        class="absolute right-0 bottom-0 underline hover:no-underline cursor-pointer"
                    >Next</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        return {
            currentSlide: 0
        }
    },

    computed: {
        ...mapState(['blog_single_gallery'])
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
    },
}
</script>

<style lang="scss" scoped>
    .c-blog-slider {
        @apply flex flex-wrap text-grey-400;

        @screen lg {
            height: 100%;
        }

        &__image-holder {
            @apply flex items-end justify-center;
            min-height: 250px;
            max-height: 100%;

            @screen md {
                min-height: 325px;
            }
        }

        &__slide {
            @apply absolute top-0 left-0 h-full w-full opacity-0;
            transition: 0.4s ease;

            &--active {
                @apply opacity-100;
            }
        }

        &__content {
            @apply flex flex-col justify-center;
            height: calc(100% - 40px);

            > div {
                @apply overflow-y-auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }
        }

        &__nav {
            @apply relative w-full;
            height: 40px;
        }
    }
</style>
