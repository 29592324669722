var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.index,
      ref: "result" + _vm.index,
      staticClass:
        "flex-wrap lg:flex-no-wrap shadow-lg-allsides md:mb-5 md:flex",
      class:
        _vm.index == _vm.active_result_index
          ? "c-result--active flex absolute bottom-0 md:static z-50"
          : "hidden"
    },
    [
      _vm.availability
        ? [
            _c(
              "div",
              {
                staticClass:
                  "w-full lg:w-40 bg-center bg-cover bg-no-repeat relative flex-shrink-0 flex-grow-0 hidden md:block",
                style:
                  "background-image:url('" +
                  _vm.availability.business.image +
                  "');min-height:125px;"
              },
              [
                _vm.isLoggedIn
                  ? _c(
                      "button",
                      {
                        staticClass: "absolute top-0 left-0 p-1 text-white",
                        attrs: {
                          title:
                            (_vm.availability.favourite ? "Unmark" : "Mark") +
                            " as favourite"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "toggle-favourite",
                              _vm.availability.business.id
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa-heart",
                          class: _vm.availability.favourite ? "fas" : "fal"
                        })
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap items-center justify-between flex-shrink flex-grow pt-6 md:p-6"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pr-6 mb-4 xl:mb-0 px-6 md:px-0 flex items-center justify-between md:block w-full md:w-auto"
                  },
                  [
                    _c("div", [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.availability.day_word))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-copper-400 font-poynter font-bold text-2xl -mt-1 block"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatTime(
                                _vm.availability.availability_start
                              )
                            ) +
                              " - " +
                              _vm._s(
                                _vm.formatTime(
                                  _vm.availability.availability_end
                                )
                              )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0 text-base" }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-semibold text-grey-1000 mr-1",
                            attrs: { role: "button" }
                          },
                          [_vm._v(_vm._s(_vm.availability.business.name))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "inline-block mr-1" }, [
                          _vm._v(
                            _vm._s(_vm.availability.business.address_line_4)
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "md:hidden " }, [
                      _c(
                        "span",
                        { staticClass: "italic  text-sm pt-2 pb-1 mr-1" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.services_by_provider.length) +
                              " " +
                              _vm._s(
                                _vm.services_by_provider.length > 1
                                  ? "Services"
                                  : "Service"
                              ) +
                              " Available From \n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "font-poynter text-grey-1000 text-2xl" },
                        [_vm._v(" " + _vm._s(_vm.availability.cost_from))]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full xl:w-auto flex-grow-0 flex-shrink-0 flex md:justify-end justify-end flex-wrap text-center"
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "flex flex-row text-right justify-end" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "italic hidden md:inline-block text-sm pt-2 pb-1 mr-1"
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.services_by_provider.length) +
                                " " +
                                _vm._s(
                                  _vm.services_by_provider.length > 1
                                    ? "Services"
                                    : "Service"
                                ) +
                                " Available From\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-poynter mr-3 hidden md:inline-block"
                          },
                          [
                            _c("br", { staticClass: "block md:hidden" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-grey-1000 text-2xl" },
                              [_vm._v(_vm._s(_vm.availability.cost_from))]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center justify-center flex-grow flex-shrink bg-grey-400 md:hidden text-white uppercase font-bold"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "md:hidden",
                            on: {
                              click: function($event) {
                                return _vm.initQuickLook(
                                  _vm.availability.business.id
                                )
                              }
                            }
                          },
                          [_vm._v("Info")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-grow flex-shrink text-center justify-end bg-teal-400 md:bg-transparent"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "btn btn--teal",
                            on: {
                              click: function($event) {
                                return _vm.bookClicked(
                                  _vm.availability.availability_id
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.availability.more_times ||
                                  _vm.services_by_provider.length > 1
                                  ? "See " +
                                      (_vm.services_by_provider.length > 1
                                        ? "Services"
                                        : "Times") +
                                      " & Book"
                                  : "Book"
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        : _vm.business
        ? [
            _c(
              "div",
              {
                staticClass:
                  "w-full lg:w-40 bg-center bg-cover bg-no-repeat relative flex-shrink-0 flex-grow-0 hidden md:block",
                style:
                  "background-image:url('" +
                  _vm.business.image +
                  "');min-height:125px;"
              },
              [
                _vm.isLoggedIn
                  ? _c(
                      "button",
                      {
                        staticClass: "absolute top-0 left-0 p-1 text-white",
                        attrs: {
                          title:
                            (_vm.business.favourite ? "Unmark" : "Mark") +
                            " as favourite"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "toggle-favourite",
                              _vm.business.id
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa-heart",
                          class: _vm.business.favourite ? "fas" : "fal"
                        })
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "pl-6 pr-6 flex flex-col items-between justify-start flex-shrink flex-grow pt-6 md:p-6"
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      " text-copper-400 font-poynter font-bold text-2xl -mt-1 block"
                  },
                  [_vm._v("Recently Available")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-base mb-4 xl:mb-0 flex flex-col items-start justify-start md:block w-full md:w-auto"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "font-semibold text-grey-1000 mr-1",
                        attrs: { role: "button" }
                      },
                      [_vm._v(_vm._s(_vm.business.name))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline-block mr-1" }, [
                      _vm._v(_vm._s(_vm.business.address_line_4))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-row flex justify-between items-center mb-4"
                  },
                  [
                    _c("span", { staticClass: "italic  text-sm pb-1 mr-1" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.business.services.length) +
                          " " +
                          _vm._s(
                            _vm.business.services.length > 1
                              ? "Services"
                              : "Service"
                          ) +
                          " Offered\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-sm italic text-right flex justify-end items-center"
                      },
                      [
                        _vm._v(
                          "\n                    From \n                    "
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "ml-1 font-poynter text-grey-1000 text-2xl"
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.toCurrency(_vm.cheapest / 100))
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "w-full xl:w-auto flex-grow-0 flex-shrink-0 flex md:justify-end justify-end flex-wrap text-center"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow flex-shrink text-center md:text-right justify-end bg-teal-400 md:bg-transparent"
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "btn btn--teal",
                    on: {
                      click: function($event) {
                        return _vm.initQuickLook(_vm.business.id)
                      }
                    }
                  },
                  [_vm._v("\n                View Salon\n            ")]
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }