<template>
    <div>
        <section>
            <div class="lg:bg-grey-200">
                <div class="container mx-auto">
                    <ul class="c-wf-tab-nav">
                        <li v-for="(link, index) in tabs" :key="index" :class="{'c-wf-tab-nav__item--active' : activeTab == link.url}" class="c-wf-tab-nav__item"><a :href="'#'+link.url">{{ link.name }}</a></li>
                    </ul>
                    <div class="flex flex-col md:flex-row justify-center items-center lg:hidden py-3">
                        <span class="flex-shrink-0 flex-grow-0 text-grey-1000 block mb-2 md:mb-0 md:mr-4">Show me information on</span>
                        <div class="c-wf-dd-sim" :class="{ 'c-wf-dd-sim--open' : showDropDownOptions }">
                            <span class="c-wf-dd-sim__trigger" role="button" @click="showDropDownOptions = !showDropDownOptions">{{ (activeTab != null ? active_pretty_name.name : 'Barbers') }}</span>
                            <div class="c-wf-dd-sim__options">
                                <ul class="mb-0">
                                    <li v-for="(link, index) in tabs" :key="index">
                                        <a :href="'#'+link.url" v-if="activeTab != link.url" @click="showDropDownOptions = false">{{ link.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Barbers -->
        <transition name="fade" v-if="activeTab == 'barbers'">
            <section>
                <div class="c-wf-tab-content-hero">
                    <div v-for="(image, index) in tabs[active_tab_index].images" :key="index" :class="{ 'wf-fade-slide--active' : sliderActiveSlide == index}" class="wf-fade-slide" :style="{ background: 'center / cover no-repeat url('+image+')' }"></div>
                    <div class="c-wf-tab-content-hero__content container mx-auto">
                        <span class="uppercase font-bold text-white" style="letter-spacing:4px;">Eleventh Hour For</span>
                        <p class="text-center text-center text-white font-poynter text-3xl lg:text-5xl mb-0">Barbers</p>
                    </div>
                </div>
                <div class="section-py--sm">
                    <div class="container mx-auto">
                        <div class="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16">
                            <p class="h2 h2--copper text-center">Finally; a booking platform that doesn't take commission or charge a fortune.</p>
                        </div>
                        <div class="-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12">
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Perfect for your<br class="hidden md:block"> usual walk-ins</p>
                                <p class="mb-0">We're the <a class="underline" :href="whyLink">only platform</a> marketed specifically at last-minute appointments.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Say 'bye-bye' to <br class="hidden md:block">empty chairs</p>
                                <p class="mb-0">Use our push notifications to fill any no-shows or last-minute cancellations with customers who've favourited you.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">One fixed<br class="hidden md:block"> monthly cost</p>
                                <p class="mb-0">Forget commission, forget huge fees. <a class="underline" :href="packagesLink">Our scalable model</a> means you pay per 'diary', not per business.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">You process<br class="hidden md:block"> all payments</p>
                                <p class="mb-0">Cash, card... however you do things, you process the payment and keep the lot.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Optional<br class="hidden md:block"> approval process</p>
                                <p class="mb-0">Choose to automatically accept bookings or review them first. Our customer profiles will give you everything you need to know.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <!-- / Barbers -->

        <!-- Hair & Beauty Salons -->
        <transition name="fade" v-if="activeTab == 'salons'">
            <section>
                <div class="c-wf-tab-content-hero">
                    <div v-for="(image, index) in tabs[active_tab_index].images" :key="index" :class="{ 'wf-fade-slide--active' : sliderActiveSlide == index}" class="wf-fade-slide" :style="{ background: 'center / cover no-repeat url('+image+')' }"></div>
                    <div class="c-wf-tab-content-hero__content container mx-auto">
                        <span class="uppercase font-bold text-white" style="letter-spacing:4px;">Eleventh Hour For</span>
                        <p class="text-center text-white font-poynter text-3xl lg:text-5xl mb-0">Hair &amp; Beauty Salons</p>
                    </div>
                </div>
                <div class="section-py--sm">
                    <div class="container mx-auto">
                        <div class="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16">
                            <p class="h2 h2--copper text-center">Swap last minute cancellations &amp; no-shows for paying customers.</p>
                        </div>
                        <div class="-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12">
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Fill your last<br class="hidden md:block"> minute appointments</p>
                                <p class="mb-0">Advertise and fill last-minute cancellations and empty slots simply by listing them.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Hair, beauty,<br class="hidden md:block"> both?</p>
                                <p class="mb-0">List the different services each of your employee's offer, and the times they're available - then leave the rest to us.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Say NO<br class="hidden md:block"> to sneaky commission!</p>
                                <p class="mb-0">With us, you pay one fixed monthly cost and never ever any commission (even on new customers)!</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Set your prices<br class="hidden md:block"> and payment methods</p>
                                <p class="mb-0">More experienced? Don't want to discount? You set the price for each service and stylist and process this in the salon.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Use us alongside or instead<br class="hidden md:block"> of another platform</p>
                                <p class="mb-0">We're the only one specifically connecting you to customers looking for last-minute appointments, but you can use us for your other bookings too!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <!-- / Hair & Beauty Salons -->

        <!-- Aestheticians & Cosmetic -->
        <transition name="fade" v-if="activeTab == 'aestheticians'">
            <section>
                <div class="c-wf-tab-content-hero">
                    <div v-for="(image, index) in tabs[active_tab_index].images" :key="index" :class="{ 'wf-fade-slide--active' : sliderActiveSlide == index}" class="wf-fade-slide" :style="{ background: 'center / cover no-repeat url('+image+')' }"></div>
                    <div class="c-wf-tab-content-hero__content container mx-auto">
                        <span class="uppercase font-bold text-white" style="letter-spacing:4px;">Eleventh Hour For</span>
                        <p class="text-center text-white font-poynter text-3xl lg:text-5xl mb-0">Aestheticians &amp; Cosmetic</p>
                    </div>
                </div>
                <div class="section-py--sm">
                    <div class="container mx-auto">
                        <div class="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16">
                            <p class="h2 h2--copper text-center">Find new customers wherever you are</p>
                        </div>
                        <div class="-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12">
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Work from<br class="hidden md:block"> multiple salons?</p>
                                <p class="mb-0">Speak to us and let us set your business up to have multiple locations.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">List your accreditations<br class="hidden md:block"> &amp; certifications</p>
                                <p class="mb-0">Let customers know you're safe and qualified to practice.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Make it<br class="hidden md:block"> official</p>
                                <p class="mb-0">Choose to take a deposit from new clients (or all if you'd prefer). Simply add this as a requirement and process the payment outside of our platform.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Optional approval process<br class="hidden md:block"> &amp; 2-way ratings</p>
                                <p class="mb-0">Choose to automatically accept bookings or review them first. Our 2-way ratings help to give businesses and customers peace of mind.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">0% commission...<br class="hidden md:block"> ever!</p>
                                <p class="mb-0">Our flat monthly fee covers our cut. With us, you keep everything and process your own payments.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <!-- / Aestheticians & Cosmetic -->

        <!-- Freelance & Mobile Professionals -->
        <transition name="fade" v-if="activeTab == 'freelance'">
            <section>
                <div class="c-wf-tab-content-hero">
                    <div v-for="(image, index) in tabs[active_tab_index].images" :key="index" :class="{ 'wf-fade-slide--active' : sliderActiveSlide == index}" class="wf-fade-slide" :style="{ background: 'center / cover no-repeat url('+image+')' }"></div>
                    <div class="c-wf-tab-content-hero__content container mx-auto">
                        <span class="uppercase font-bold text-white" style="letter-spacing:4px;">Eleventh Hour For</span>
                        <p class="text-center text-white font-poynter text-3xl lg:text-5xl mb-0">Freelance &amp; Mobile Professionals</p>
                    </div>
                </div>
                <div class="section-py--sm">
                    <div class="container mx-auto">
                        <div class="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16">
                            <p class="h2 h2--copper text-center">Hair, beauty, makeup, aesthetics - run your business, your way</p>
                        </div>
                        <div class="-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12">
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Stay safe</p>
                                <p class="mb-0">Make use of our optional pre-booking approvals &amp; 2-way ratings to check customers before appointments are confirmed.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Make your money<br class="hidden md:block"> work for you</p>
                                <p class="mb-0">Our solo packages start at £15.99, and because you process all payments, we don't take anything else.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Operate from your<br class="hidden md:block"> own home?</p>
                                <p class="mb-0">Choose to hide your location from new customers until appointments are approved.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Work your diary<br class="hidden md:block"> around you</p>
                                <p class="mb-0">There's no minimum number of appointments to be fulfilled or set hours - choose how many appointments you list.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">List your accreditations<br class="hidden md:block"> &amp; certifications</p>
                                <p class="mb-0">Let customers know you're safe and qualified to practice.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <!-- / Freelance & Mobile Professionals -->

        <!-- Spas -->
        <transition name="fade" v-if="activeTab == 'spas'">
            <section>
                <div class="c-wf-tab-content-hero">
                    <div v-for="(image, index) in tabs[active_tab_index].images" :key="index" :class="{ 'wf-fade-slide--active' : sliderActiveSlide == index}" class="wf-fade-slide" :style="{ background: 'center / cover no-repeat url('+image+')' }"></div>
                    <div class="c-wf-tab-content-hero__content container mx-auto">
                        <span class="uppercase font-bold text-white" style="letter-spacing:4px;">Eleventh Hour For</span>
                        <p class="text-center text-white font-poynter text-3xl lg:text-5xl mb-0">Spas</p>
                    </div>
                </div>
                <div class="section-py--sm">
                    <div class="container mx-auto">
                        <div class="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mb-12 md:mb-16">
                            <p class="h2 h2--copper text-center">Find new customers without discounting your services</p>
                        </div>
                        <div class="-mx-4 flex flex-wrap justify-center -mt-10 md:-mt-12">
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Unlike other platforms,<br class="hidden md:block"> you set the price</p>
                                <p class="mb-0">What you do has value. That's why we'll never ever push you to discount or reduce your service cost unless you want to. </p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Perfect for those<br class="hidden md:block"> rainy days</p>
                                <p class="mb-0">Sometimes, your customers don't know they need you until the last minute. Use us to find new customers and fill last-minute availability.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Say NO to<br class="hidden md:block"> sneaky commission!</p>
                                <p class="mb-0">With us, you pay one fixed monthly cost and never ever any commission (even on new customers)!</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">Make it<br class="hidden md:block"> official</p>
                                <p class="mb-0">Choose to take a deposit from new clients (or all if you'd prefer). Simply add this as a requirement and process the payment outside of our platform.</p>
                            </div>
                            <div class="w-full md:w-6/12 lg:w-4/12 flex flex-col items-center text-center mt-10 md:mt-12 px-4">
                                <i class="fas fa-check-circle text-2xl text-teal-400 mb-2"></i>
                                <p class="font-poynter font-bold text-xl text-grey-1000 mb-4">A flexible platform<br class="hidden md:block"> perfect for spa days</p>
                                <p class="mb-0">Adjust and amend treatment times to allow clients to move between professionals if required, and to maximise each persons diary</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <!-- / Spas -->
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            tabs: [
                {
                    'url': 'barbers',
                    'name': 'Barbers',
                    'images': [
                        '/images/who-is-it-for/barber-1.jpg',
                        '/images/who-is-it-for/barber-2.jpg',
                        '/images/who-is-it-for/barber-3.jpg'
                    ]
                },
                {
                    'url': 'salons',
                    'name': 'Hair & Beauty Salons',
                    'images': [
                        '/images/who-is-it-for/salon-1.jpg',
                        '/images/who-is-it-for/salon-2.jpg',
                        '/images/who-is-it-for/salon-3.jpg',
                    ]
                },
                {
                    'url': 'aestheticians',
                    'name': 'Aestheticians & Cosmetic',
                    'images': [
                        '/images/who-is-it-for/aesthetician-1.jpg',
                        '/images/who-is-it-for/aesthetician-2.jpg',
                    ]
                },
                {
                    'url': 'freelance',
                    'name': 'Freelance & Mobile Professionals',
                    'images': [
                        '/images/who-is-it-for/freelance-1.jpg',
                        '/images/who-is-it-for/freelance-2.jpg',
                        '/images/who-is-it-for/freelance-3.jpg',
                    ]
                },
                {
                    'url': 'spas',
                    'name': 'Spas',
                    'images': [
                        '/images/who-is-it-for/spa-1.jpg',
                        '/images/who-is-it-for/spa-2.jpg',
                        '/images/who-is-it-for/spa-3.jpg',
                    ]
                },
            ],
            activeTab: null,
            showDropDownOptions: false,

            sliderActiveSlide: 0,
            slideChange: null
        }
    },

    beforeMount() {
        window.addEventListener("hashchange", () => { this.activeTab = window.location.hash.substr(1) }, false);
    },

    mounted() {
        this.activeTab = (window.location.hash.substr(1) ? window.location.hash.substr(1) : 'barbers');

        this.slideChange = setInterval(() => {
            ((this.tabs[this.active_tab_index].images.length - 1) > this.sliderActiveSlide ? this.sliderActiveSlide++ : this.sliderActiveSlide = 0);
        }, 3000);
    },

    computed: {
        active_pretty_name() { return this.tabs.find(item => item.url == this.activeTab); },

        active_tab_index() { return this.tabs.findIndex(item => item.url == this.activeTab); }
    },

    props: ['packagesLink', 'whyLink']
}
</script>

<style lang="scss" scoped>
    .c-wf-tab-nav {
        @apply hidden mb-0;

        @screen lg {
            @apply flex;
        }

        &__item {
            @apply relative flex items-center justify-center flex-shrink-0 text-base text-center text-grey-1000 font-normal;
            width: 20%;
            transition: 0.4s ease;

            @screen xl {
                @apply text-xl;
            }

            &:not(.c-wf-tab-nav__item--active):hover {
                @apply bg-grey-350;
            }

            &::after {
                @apply absolute opacity-0 bg-contain bg-no-repeat bg-center;
                content: "";
                bottom: 10px;
                left: 50%;
                height: 10px;
                width: 15px;
                background-image: url('/images/icons/arrow.svg');
                transform: translateX(-50%);
                transition: 0.4s ease;
            }

            &--active {
                @apply bg-teal-400 font-semibold cursor-default;

                &::after {
                    @apply opacity-100;
                }
            }

            a {
                @apply flex items-center justify-center h-full w-full p-6;

                @screen xl {
                    @apply px-8;
                }
            }
        }
    }

    .c-wf-dd-sim {
        @apply relative flex-shrink-0 flex-grow w-full bg-grey-200 text-grey-1000;
        border-radius: 7px;

        @screen md {
            @apply w-auto;
        }

        &--open {
            .c-wf-dd-sim__trigger::after {
                transform: translateY(-50%) rotate(180deg);
            }

            .c-wf-dd-sim__options {
                @apply opacity-100 pointer-events-auto;
            }
        }

        &__trigger {
            @apply relative block font-semibold py-2 px-4 cursor-pointer;
            padding-right: calc(2rem + 15px);

            &::after {
                @apply absolute bg-contain bg-center bg-no-repeat;
                content: "";
                top: 50%;
                right: 1rem;
                height: 10px;
                width: 15px;
                transform: translateY(-50%);
                background-image: url('/images/icons/arrow.svg');
                transition: 0.4s ease;
            }
        }

        &__options {
            @apply absolute left-0 h-auto w-full opacity-0 pointer-events-none bg-grey-200 pb-3 px-4 z-10;
            top: calc(100% - 6px);
            padding-top: 6px;
            transition: 0.4s ease;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;

            li:not(:last-of-type) {
                @apply mb-2;
            }
        }
    }

    .c-wf-tab-content-hero {
        @apply relative;
        padding-top: 60%;

        @screen md {
            padding-top: 35%;
        }

        @screen lg {
            padding-top: 25%;
        }

        @screen xl {
            padding-top: 20%;
        }

        &::before {
            @apply absolute top-0 left-0 h-full w-full;
            content: "";
            z-index: 2;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.8) 100%);
        }

        &__content {
            @apply absolute flex flex-col items-center justify-center;
            top: 50%;
            left: 50%;
            z-index: 3;
            transform:  translate(-50%, -50%);
        }
    }

    .wf-fade-slide {
        @apply absolute top-0 left-0 w-full h-full opacity-0;
        z-index: 1;
        transition: 2s ease;

        &--active {
            @apply opacity-100;
        }
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity .4s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
