var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative flex items-center" }, [
    _c("div", { staticClass: "c-filter-option" }, [
      _c("div", { staticClass: "c-form__checkbox-as-toggle" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.is_checked,
              expression: "is_checked"
            }
          ],
          attrs: { type: "hidden", name: _vm.name },
          domProps: { value: _vm.is_checked },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.is_checked = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.is_checked,
              expression: "is_checked"
            }
          ],
          attrs: { type: "checkbox", id: _vm.label },
          domProps: {
            checked: Array.isArray(_vm.is_checked)
              ? _vm._i(_vm.is_checked, null) > -1
              : _vm.is_checked
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.is_checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.is_checked = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.is_checked = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.is_checked = $$c
                }
              },
              function($event) {
                return _vm.$emit("updated")
              }
            ]
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.label } }, [_vm._v(_vm._s(_vm.label))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }