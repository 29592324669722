<template>
    <div class="tabs-wrapper">

        <div v-for="(option, index) in extracted_industries" :key="index" class="c-horizontal-tab">
            <div :class="{ 'active' : option.title == active_parent }" @click="setActive(option)" class="c-horizontal-tab__title" role="button">
                <span class='c-horizontal-tab__title-wrapper'>
                    <div class="c-rounded-icon c-rounded-icon--small mr-3">
                        <img :src="option.icon || '/images/icons/industries/other.svg'" class="c-rounded-icon__icon" style="max-width:70%" />

                    </div>
                    <p class="text-black mb-0 font-poynter font-bold">{{ option.title }}</p>
                </span>
                <i class="fas" :class="{'fa-chevron-down': !(option.title === active_parent), 'fa-chevron-up': option.title === active_parent}"></i>

            </div>

            <div v-if="option.title == active_parent" class="c-horizontal-tab__content">
                <ul class="mb-0 -mx-3 flex flex-wrap">
                    <li v-for="(service, i) in options.filter(option => option.industry == active_parent)"
                        :key="i"
                        class="w-full md:w-6/12 lg:w-4/12 font-normal text-grey-1000 px-3 mb-1"
                    >
                        {{ service.name }}
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import { BUSINESS_SERVICES } from '@js/endpoints';
export default {

    data() {
        return {
            active_parent: null,
            options: []
        }
    },

    props: {
        businessId: {
            required: true,
            type: Number
        }
    },


    mounted() {
        this.getServices();
    },

    watch: {
        extracted_industries() {
            this.active_parent = this.extracted_industries[0].title;
        }
    },

    computed: {
        extracted_industries() {
            let industries = [...new Set(this.options.map(item => { return JSON.stringify({ title: item.industry, icon: item.meta.industry_icon }) }))].map(item => JSON.parse(item));
            return industries;
        }
    },

    methods: {
        getServices() {
            axios.get(BUSINESS_SERVICES(this.businessId)).then(res => {
                this.options = res.data.data;
                // console.log(this.options);
            });
        },

        setActive(option) {
            if (this.active_parent === option.title) {
                this.active_parent = null;
            } else {
                this.active_parent = option.title;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs-wrapper {
    @apply bg-white;
    // border-radius: 1.50rem;
    box-shadow: 0 0 5px 5px rgba(169, 169, 169, 0.3);
}

.c-horizontal-tab {
    &:not(:last-of-type) {
        @apply mb-1;
    }





    &__title {
        @apply flex items-center bg-grey-200 py-4;
        transition: 0.4s ease;
        justify-content: space-between;

        &.active {
            @apply py-6 bg-white cursor-default;
        }

        &-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

        }
    }

    &__title,
    &__content {
        @apply px-8;
    }

    &__content {
        @apply py-6 pt-0 text-grey-600 text-base;
    }
}
</style>
