var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(option, i) {
      return _c("div", { key: i, staticClass: "c-simulated-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.the_model,
              expression: "the_model"
            }
          ],
          staticClass: "c-simulated-checkbox__input",
          attrs: {
            type: "radio",
            disabled: _vm.disabled !== null,
            name: _vm.name,
            id: option.value + _vm.name
          },
          domProps: {
            value: option.value,
            checked: _vm._q(_vm.the_model, option.value)
          },
          on: {
            click: function($event) {
              _vm.preventSelect($event, i)
              _vm.$emit("updated", _vm.the_model)
            },
            change: function($event) {
              _vm.the_model = option.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "c-simulated-checkbox__label",
            attrs: { for: option.value + _vm.name }
          },
          [_vm._v(_vm._s(option.label))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }