var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.cancelled
              ? _vm.reloadPage()
              : _vm.mutateOverlayVisibility(false)
          },
          expression:
            "() => cancelled ? reloadPage() : mutateOverlayVisibility(false)"
        }
      ],
      staticClass: "c-popup"
    },
    [
      !_vm.cancelled
        ? _c("div", [
            _c("h2", { staticClass: "c-popup__title" }, [
              _vm._v("Cancel Appointment")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("You're about to cancel your "),
              _c("strong", [_vm._v(_vm._s(_vm.params.service_name))]),
              _vm._v(" with "),
              _c("strong", [_vm._v(_vm._s(_vm.params.service_provider))]),
              _vm._v(" at "),
              _c("strong", [_vm._v(_vm._s(_vm.params.business_name))])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("label", { staticClass: "c-form__label block w-full" }, [
                _vm._v("Reason for cancelling")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reason,
                      expression: "reason"
                    }
                  ],
                  staticClass: "c-form__select block w-full",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reason = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Please select a reason")
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "I've found an appointment elsewhere" } },
                    [_vm._v("I've found an appointment elsewhere")]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    {
                      attrs: {
                        value:
                          "The appointment conflicts with my other commitments"
                      }
                    },
                    [
                      _vm._v(
                        "The appointment conflicts with my other commitments"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "I no longer require this service" } },
                    [_vm._v("I no longer require this service")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "other" } }, [_vm._v("Other")])
                ]
              )
            ]),
            _vm._v(" "),
            _vm.reason === "other"
              ? _c("div", [
                  _c("label", { staticClass: "c-form__label block w-full" }, [
                    _vm._v("Please specify")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reason_other,
                        expression: "reason_other"
                      }
                    ],
                    staticClass:
                      "c-form__input c-form__input--textarea block w-full",
                    domProps: { value: _vm.reason_other },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.reason_other = $event.target.value
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.cancellation_reason
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn--teal w-full text-center",
                    on: { click: _vm.cancelAppointment }
                  },
                  [_vm._v("Cancel Appointment")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.cancelled
        ? _c("div", [
            _c("h2", { staticClass: "c-popup__title" }, [
              _vm._v("Appointment Cancelled")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Your appointment has been cancelled. You should receive email confirmation of this shortly. "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Please note that excessive cancelations may affect your reputation with salons, and make future appointments harder to secure."
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }