const BASE_URL          = "/api";
const SITE_ROOT         =  process.env.MIX_APP_URL;
const BLOG_ROOT         =  process.env.MIX_BLOG_URL;
const ADMIN_DATATABLES  = `${BASE_URL}/admin/datatables`;
const LIVE_SEARCH_URL   = `${BASE_URL}/live-search`;
const BOOKING_URL       = `${BASE_URL}/booking`;
const AVAILABILITY_URL  = `${process.env.MIX_API_URL}/availability`;
const BLOG_URL          = `${BLOG_ROOT}/wp-json`;

//Live Search
export const LIVE_SEARCH_SERVICES   = `${LIVE_SEARCH_URL}/services`;
export const LIVE_SEARCH_INDUSTRIES   = `${LIVE_SEARCH_URL}/industries`;
export const LIVE_SEARCH_CITIES     = `${LIVE_SEARCH_URL}/cities`;
export const LIVE_SEARCH_BUSINESS   = `${LIVE_SEARCH_URL}/businesses`;
export const LIVE_SEARCH_GEOLOCATION= (query) => `${LIVE_SEARCH_URL}/geolocation/${query}`;

//Book a demo
export const BOOK_DEMO              = `${BASE_URL}/book-a-demo`;


//Services
export const SERVICE = (id) => `${BASE_URL}/service/${id}`;
export const BUSINESS_SERVICES = (id) => `${BASE_URL}/business/${id}/services`;


//Datatables
export const DATATABLES_BUSINESS_ALL        = `${ADMIN_DATATABLES}/business`;
export const DATATABLES_SERVICE_ALL         = `${ADMIN_DATATABLES}/service`;
export const DATATABLES_INDUSTRY_ALL        = `${ADMIN_DATATABLES}/industry`;
export const DATATABLES_USER_ALL            = `${ADMIN_DATATABLES}/user`;
export const DATATABLES_FAQ_ALL             = `${ADMIN_DATATABLES}/faq`; //'faq all' haha
export const DATATABLES_SUBSCRIPTION_ALL    = `${ADMIN_DATATABLES}/subscription`;
export const DATATABLES_RECOMMENDED_SALONS_ALL = `${ADMIN_DATATABLES}/recommended-salons`;
export const DATATABLES_BUSINESS_ADMIN_ADDRESS_BOOK  = (id) => `${ADMIN_DATATABLES}/business/${id}/address-book/`;

//Business
export const BUSINESS_SUSPEND_TOGGLE = (id) => `${BASE_URL}/admin/business/${id}/suspend`

//Booking
export const RESERVE_AVAILABILITY   = `${BOOKING_URL}/reserve`;
export const UNRESERVE_AVAILABILITY = `${BOOKING_URL}/unreserve`;
export const CAN_BOOK               = `${BOOKING_URL}/can-book`;
export const CONFIRM_BOOKING        = `${BOOKING_URL}/confirm`;


//Availability
export const BUSINESS_AVAILABILITY  = `${AVAILABILITY_URL}/business`;
export const AVAILABILITY_SEARCH    = `${AVAILABILITY_URL}/search`;
export const AVAILABILITY_SERVICES  = (id) => `${AVAILABILITY_URL}/services/${id}`;

//Auth
export const LOGIN_PATH             = process.env.MIX_LOGIN_PATH;
export const LOGOUT_PATH            = process.env.MIX_LOGOUT_PATH;
export const PASSWORD_RESET_PATH    = process.env.MIX_PASSWORD_RESET_PATH;
export const PASSWORD_REQUEST_PATH  = process.env.MIX_PASSWORD_REQUEST_PATH;
export const SIGNUP_PATH            = process.env.MIX_SIGNUP_PATH;

export const BUSINESSES_CONTACT_PATH= process.env.MIX_BUSINESSES_CONTACT_PATH;
//Customer Account Pages
export const ACCOUNT_BOOKINGS       = `${SITE_ROOT}/my-account/bookings`;
export const ACCOUNT_BOOKING_CANCEL = (id) => `${BASE_URL}/appointment/${id}/cancel`;




//Quick Look
export const QUICK_LOOK = `${BASE_URL}/quick-look`;

//Favourites
export const TOGGLE_FAVOURITE = `${BASE_URL}/favourite`;

//Newletter Signup
export const NEWSLETTER_SIGNUP = `${BASE_URL}/newsletter-signup`;

//Blog
export const FETCH_BLOGS            = (category = false, page = 1, postsPerPage = 9) => `${BLOG_URL}/custom/fetchBlogs?category=${category}&page=${page}&postsPerPage=${postsPerPage}`;
export const FETCH_OTHER_BLOGS      = (currentPostID = false, postsPerPage = 1) => `${BLOG_URL}/custom/fetchOtherBlogs?&currentPostID=${currentPostID}&postsPerPage=${postsPerPage}`;
export const FETCH_BLOG_CATEGORIES  = (perPage = 100) => `${BLOG_URL}/wp/v2/categories?per_page=${perPage}`;
export const FETCH_BLOG             = (path) => `${BLOG_URL}/custom/fetchBlogPostByPath?path=${path}`;
//Admin
export const ADMIN_CONTACT_METHODS                              = `${BASE_URL}/admin/contact-methods`;

//Business Admin
export const BUSINESS_ADMIN                                     = `${BASE_URL}/admin/business`;
export const BUSINESS_ADMIN_LIVE_SEARCH_SERVICES                = (id = null) => `${BASE_URL}/admin/business/${id}/services`;
export const BUSINESS_ADMIN_APPOINTMENTS                        = (id = null, month = 'all') => `${BASE_URL}/admin/business/${id}/appointments/${month}`;
export const BUSINESS_ADMIN_APPOINTMENT                         = (id, appointment) => `${BASE_URL}/admin/business/${id}/appointment/${appointment}`;
export const BUSINESS_ADMIN_APPOINTMENT_CANCEL                  = (id, appointment) => `${BASE_URL}/admin/business/${id}/appointment/${appointment}/cancel`;
export const BUSINESS_ADMIN_AVAILABILITY                        = (id = null) => `${BASE_URL}/admin/business/${id}/availability`;
export const BUSINESS_ADMIN_AVAILABILITY_DELETE                 = (id = null, availability = null) => `${BASE_URL}/admin/business/${id}/availability/${availability}`;
export const BUSINESS_ADMIN_SERVICE                             = (id = null) => `${BASE_URL}/admin/business/${id}/service`;
export const BUSINESS_ADMIN_SERVICE_UPDATE                      = (id = null, service_id = null) => `${BASE_URL}/admin/business/${id}/service/${service_id}`;
export const BUSINESS_ADMIN_SERVICE_DELETE                      = BUSINESS_ADMIN_SERVICE_UPDATE;
export const BUSINESS_ADMIN_DIARIES                             = (id = null) => `${BASE_URL}/admin/business/${id}/diary/all`;
export const BUSINESS_ADMIN_DIARY_SERVICES                      = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/services/true`;
export const BUSINESS_ADMIN_DIARY_SETTINGS                      = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/settings`;
export const BUSINESS_ADMIN_DIARY_SERVICES_COPY_FROM_BUSINESS   = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/services/copy`;
export const BUSINESS_ADMIN_DIARY_SERVICE                       = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/service`;
export const BUSINESS_ADMIN_DIARY_SERVICE_UPDATE                = (id = null, diary_id = null, service_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/service/${service_id}`;
export const BUSINESS_ADMIN_DIARY_SERVICE_DELETE                = BUSINESS_ADMIN_DIARY_SERVICE_UPDATE;
export const BUSINESS_ADMIN_DIARY_AVAILABILITY_CLASHES          = (id = null, diary_id = null, except = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/availability/clashes/${except || ''}`;
export const BUSINESS_ADMIN_DIARY_AVAILABILITY                  = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/availability`;
export const BUSINESS_ADMIN_DIARY_AVAILABILITY_UPDATE           = (id = null, diary_id = null, avail_id = null) => `${BASE_URL}/admin/business/${id}/diary/${diary_id}/availability/${avail_id}`;
export const BUSINESS_ADMIN_CONTACT_METHOD                      = (id) => `${BASE_URL}/admin/business/${id}/contact-method`;
export const BUSINESS_ADMIN_CONTACT_METHOD_UPDATE               = (id, contact_method) => `${BASE_URL}/admin/business/${id}/contact-method/${contact_method}`;
export const BUSINESS_ADMIN_CONTACT_METHOD_DELETE               = BUSINESS_ADMIN_CONTACT_METHOD_UPDATE;

export const BUSINESS_ADMIN_SCHEDULE                            = (id, date) => `${BASE_URL}/admin/business/${id}/schedule/${date}`;
export const BUSINESS_ADMIN_OVERVIEW_MONTH                      = (id, date) => `${BASE_URL}/admin/business/${id}/schedule/overview/${date}`;


export const BUSINESS_GALLERY                                   = (id) => `${BASE_URL}/admin/business/${id}/gallery`;
export const BUSINESS_GALLERY_DELETE                            = (id, media_id) => `${BASE_URL}/admin/business/${id}/gallery/${media_id}`;

export const BUSINESS_ADMIN_ADDRESS_BOOK_SEARCH                 = (id, q) => `${BASE_URL}/admin/business/${id}/address-book/${q}`;
export const BUSINESS_ADMIN_ADDRESS_BOOK_CREATE                 = (id) => `${BASE_URL}/admin/business/${id}/address-book`;
export const BUSINESS_ADMIN_ADDRESS_BOOK_DELETE                 = (id, customer_id) => `${BASE_URL}/admin/business/${id}/address-book/${customer_id}`;

//Ancillary Appointments
export const BUSINESS_ADMIN_ANCILLARY_APPOINTMENT_CREATE        = (id) => `${BASE_URL}/admin/business/${id}/ancillary-appointment`;
export const BUSINESS_ADMIN_ANCILLARY_APPOINTMENT_UPDATE        = (id, appointmentId) => `${BASE_URL}/admin/business/${id}/ancillary-appointment/${appointmentId}`;
export const BUSINESS_ADMIN_ANCILLARY_APPOINTMENT_CANCEL        = (id, appointmentId) => `${BASE_URL}/admin/business/${id}/ancillary-appointment/${appointmentId}/cancel`;

//Business Bookings Alerts
export const BUSINESS_ADMIN_BOOKING_ALERTS                      = (id)  =>  `${BASE_URL}/admin/business/${id}/alerts`;

//Business Holidays
export const BUSINESS_ADMIN_HOLIDAYS_MONTH                      = (id, date) => `${BASE_URL}/admin/business/${id}/holidays/date/${date}`;
export const BUSINESS_ADMIN_HOLIDAY_CREATE                      = (id) => `${BASE_URL}/admin/business/${id}/holidays`;
export const BUSINESS_ADMIN_HOLIDAY_UPDATE                      = (id,holiday) => `${BASE_URL}/admin/business/${id}/holidays/${holiday}`;
export const BUSINESS_ADMIN_HOLIDAY_GET                         = BUSINESS_ADMIN_HOLIDAY_UPDATE;
export const BUSINESS_ADMIN_HOLIDAY_DELETE                      = BUSINESS_ADMIN_HOLIDAY_UPDATE;
export const BUSINESS_ADMIN_HOLIDAY_CLASHES                     = (id = null, diary_id = null) => `${BASE_URL}/admin/business/${id}/holidays/clashes/${diary_id}`;


//Business PEOPLE (Like Diaries, but all users)
export const BUSINESS_ADMIN_PEOPLE                              = (id) => `${BASE_URL}/admin/business/${id}/people`;
export const BUSINESS_ADMIN_PERSON_DELETE                       = (id, person) => `${BASE_URL}/admin/business/${id}/people/${person}`;





