<template>
     <div class="c-simulated-checkbox">
        <input type="hidden" v-model="is_checked" :name="name">
        <input type="checkbox" v-model="is_checked" @click="preventClick" :id="label" class="c-simulated-checkbox__input">
        <label class="c-simulated-checkbox__label" :for="label">{{ label }}</label>
    </div>
</template>

<script>
export default {

    data() {
        return {
            is_checked: 'false'
        }
    },

    props: {
        name: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        checked: {
            type: Boolean
        },

        uncheckable: {
            type: String,
            default: null
        }
    },

    methods: {
        preventClick(e) {
            if (this.uncheckable) {
                e.preventDefault();
                alert(this.uncheckable);
            }

        }
    },

    mounted() {
        this.is_checked = this.checked ? true : false;
    }
}
</script>