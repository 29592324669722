<template>

    <div class="relative" v-on-clickaway="() => { interacting = false }" ref="datePos">

        <div class="c-filter-option" :class="selected_date && selected_date.length ? ' c-filter-option--active' : null" @click="interacting = !interacting" >
            <span class="c-filter-option__text" role="button" offset-track>{{ selected_label }}</span>
            <span v-if="selected_date && selected_date.length" @click="clearSelected" class="c-filter-option__clear" role="button" aria-label="Remove this filter option">
                <i class="fal fa-times hidden md:inline-block"></i>
                <span class="inline-block md:hidden uppercase text-sm font-semibold">Edit</span>
            </span>
        </div>

        <div v-if="interacting" class="c-filter-option__drop-down" v-bind:class="{dockRight: dateBelow }">
            <div>
                <div class="flex flex-wrap" style="margin:-1px -1px 0 -1px;">
                    <div class="w-6/12" style="padding:1px;"  v-for="(date, index) in quick_pick_dates" :key="index">
                        <div class="c-form__label-as-btn">
                            <input type="checkbox" :id="'quick_pick' + index" class="absolute" v-model="date_parsed" :value="date.date" :false-value="null">
                            <label class="block w-full" :for="'quick_pick' + index">{{ dateAsWord(date.date, true) }}</label>
                        </div>
                    </div>
                </div>
                <div class="c-datepicker">
                    <v-date-picker  mode="multiple" v-model="date_parsed" is-inline :min-date="new Date()"></v-date-picker>
                </div>
                <span @click="clearSelected" class="text-sm text-center block" role="button" :class="selected_date.length ? ' text-grey-1000 underline hover:no-underline' : null">Clear Dates</span>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VCalendar from 'v-calendar';
import moment from 'moment';

Vue.use(VCalendar);

export default {

    data() {
        return {
            interacting: false,
            // datePosition: {},
            dateBelow: false,
            // selected_date: this.value
        }
    },

    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        label: {
            type: String
        },

        interactingDefault: {
            default: false
        },
        value: {}
    },

    computed: {
        ...mapState(['search_filter']),

        selected_date() {
            return this.value;
        },

        date_parsed: {
            get() {
                return this.selected_date
            },

            set(toSet) {
                this.$emit('input', toSet);
            }
        },

        quick_pick_dates() {
            const days = 4;
            let toReturn = [];

            for(let i = 0; i < days; i++) {
                toReturn[i] = {
                    date: moment().startOf('day').add(i, 'days').toDate()
                }
            }

            return toReturn;
        },

        selected_label() {

            let selected = this.search_filter.fields[this.selected];


            if (Array.isArray(selected)) {

                selected = selected.sort((a, b) => a - b);

                switch(selected.length) {
                    case 0:
                        return this.label;
                        break;

                    case 1:
                        return this.dateAsWord(selected[0]);
                        break;

                    case 2:
                        return `${this.dateAsWord(selected[0])} & ${this.dateAsWord(selected[1])}`;
                        break;

                    default:
                        let selected_ = selected;
                        // return selected_.map(item => this.dateAsWord(item)).slice(0,-1).join(', ') + ` & ${this.dateAsWord(selected[selected.length-1])}`;
                        return selected_.map(item => this.dateAsWord(item)).slice(0,2).join(', ') + ` +${parseInt(selected.length - 2)}`;
                }
            }

            return this.label;
        },

    },
    watch: {
        'selected_label': function (newVal, oldVal) {
            setTimeout(() => this.updateDateLocation(), 10)
        }, deep: true
    },
    methods: {
        clearSelected() {
            this.search_filter.fields[this.selected] = [];
        },

        dateAsWord(date, name_only = false) {

            switch (moment(date).startOf('day').diff(moment().startOf('day'), 'days')) {
                case 0:
                    return "Today";
                    break;

                case 1:
                    return "Tomorrow";
                    break;

                default:
                    return name_only ? moment(date).format('dddd') : moment(date).format('ddd Do MMM')
            }
        },
        updateDateLocation(){
            let datePosition = this.getDateLocation(this.$refs.datePos.getBoundingClientRect());
            if(datePosition.left < 50 & datePosition.top > 170){
                this.dateBelow = true
            }
            else {
                this.dateBelow = false
            }

        },
        getDateLocation(rect) {
            return {
                top: rect.top,
                left: rect.left,
            }
        }

    },

    mounted() {
        this.interacting = this.interactingDefault;
        this.updateDateLocation();
    },

}
</script>

<style lang="scss" scoped>
    .c-form__label-as-btn  {
        @apply bg-grey-300 border-none w-full text-center rounded;
    }

    .c-form__label-as-btn label {
        @apply mt-0 border-none uppercase font-semibold py-2 rounded;
    }

    .c-form__label-as-btn input:checked + label {
        @apply bg-teal-400 text-grey-1000;
    }


    .c-filter-option__drop-down {
        width: 310px;
        transition: none!important;

    }

    .dockRight{
        left:0;
        // transition: left 100ms!important;

    }

    ::v-deep .c-datepicker {
        @apply mt-3;
    }
</style>
