var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { staticClass: "c-form__label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.current_value,
            expression: "current_value"
          }
        ],
        staticClass: "c-form__select w-full",
        attrs: { name: _vm.name, disabled: _vm.disabled !== null },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.current_value = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function($event) {
              return _vm.$emit("updated")
            }
          ]
        }
      },
      [
        _c("option", { attrs: { disabled: "" }, domProps: { value: null } }, [
          _vm._v("Please select")
        ]),
        _vm._v(" "),
        _vm._l(_vm.values, function(value, i) {
          return _c("option", { key: i, domProps: { value: value.value } }, [
            _vm._v(_vm._s(value.label))
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }