var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "50vh" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.featuredPosts.length
          ? _c(
              "div",
              { staticClass: "mb-16" },
              _vm._l(_vm.featuredPosts, function(post, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "c-featured-post block text-white",
                    class: post.post_theme_colour,
                    attrs: { href: _vm.blogBaseUrl + "/" + post.post_name }
                  },
                  [
                    _c("div", {
                      staticClass:
                        "lg:h-full lg:w-6/12 lg:absolute lg:top-0 lg:left-0 px-8",
                      staticStyle: { "min-height": "275px" },
                      style: {
                        background:
                          "center / cover no-repeat url(" +
                          (!post.post_featured_image
                            ? "/images/blog-fallback.svg"
                            : post.post_featured_image) +
                          ")"
                      },
                      attrs: { role: "image" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "container mx-auto" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "lg:flex lg:justify-end lg:items-center -mx-8"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full md:w-10/12 lg:w-6/12 px-8 mx-auto lg:mx-0"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full xl:w-10/12 lg:px-8 flex flex-col items-center text-center py-8 mx-auto"
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "flex flex-wrap items-center justify-center -mx-2 mb-2"
                                    },
                                    _vm._l(post.post_categories, function(
                                      category,
                                      catIndex
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: catIndex,
                                          staticClass:
                                            "uppercase font-semibold px-2"
                                        },
                                        [_vm._v(_vm._s(category.cat_name))]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("h1", {
                                    staticClass: "text-white",
                                    staticStyle: { "margin-bottom": "0" },
                                    domProps: {
                                      innerHTML: _vm._s(post.post_title)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "block text-base font-normal italic mb-6"
                                    },
                                    [
                                      _vm._v(
                                        "by " +
                                          _vm._s(post.post_author.fname) +
                                          " " +
                                          _vm._s(post.post_author.sname) +
                                          ", " +
                                          _vm._s(post.post_author.job_role)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "block text-base font-normal"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .moment(post.post_date)
                                            .format("Do MMMM YYYY")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container mx-auto" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.nonFeaturedPosts.length
              ? _c(
                  "div",
                  {
                    staticClass: "c-blog-grid",
                    attrs: { "data-blog-masonry-grid": "" }
                  },
                  _vm._l(_vm.nonFeaturedPosts, function(post, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "c-blog-grid__item",
                        attrs: { "data-blog-masonry-item": "" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "shadow-lg-allsides block h-full",
                            attrs: {
                              href: _vm.blogBaseUrl + "/" + post.post_name,
                              "data-blog-masonry-item-content": ""
                            }
                          },
                          [
                            post.post_featured_image
                              ? _c("div", {
                                  staticStyle: { "padding-top": "65%" },
                                  style: {
                                    background:
                                      "center / cover no-repeat url(" +
                                      post.post_featured_image +
                                      "), #f2f2f2"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "p-6 text-center" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "flex flex-wrap items-center justify-center -mx-2 mb-2"
                                },
                                _vm._l(post.post_categories, function(
                                  category,
                                  catIndex
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: catIndex,
                                      staticClass:
                                        "uppercase text-copper-400 font-semibold px-2"
                                    },
                                    [_vm._v(_vm._s(category.cat_name))]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("p", {
                                staticClass:
                                  "text-xl font-bold font-poynter text-black mb-0",
                                domProps: { innerHTML: _vm._s(post.post_title) }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "block text-base text-grey-400 font-normal italic mb-4"
                                },
                                [
                                  _vm._v(
                                    "by " +
                                      _vm._s(post.post_author.fname) +
                                      " " +
                                      _vm._s(post.post_author.sname) +
                                      ", " +
                                      _vm._s(post.post_author.job_role)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "block text-base text-black font-normal"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(post.post_date)
                                        .format("Do MMMM YYYY")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.postsPerPage * _vm.page < _vm.numberOfPosts
            ? _c("div", { staticClass: "mt-16 flex justify-center" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "btn btn--outline btn--copper w-full md:w-auto",
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        _vm.page = _vm.page + 1
                      }
                    }
                  },
                  [_vm._v("\n                Load more\n            ")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.noResults ? _c("div", [_vm._m(0)]) : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "text-center mt-16" }, [
      _vm._v("Sorry, there are currently no blogs that match your search."),
      _c("br"),
      _vm._v("Try another category or check back soon!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }