<template>
    <gmap-map :center="centre" class="w-full" style="height:600px" map-type-id="terrain" :zoom="7" :options="{ disableDefaultUi: true, zoomControl: true, mapTypeControl: false, streetViewControl: false, fullscreenControl: false }" ref="searchMap">
        <gmap-marker v-for="(marker, index) in markers" :key="index" :position="{lat:parseFloat(marker.lat), lng:parseFloat(marker.lng)}" @click="setCentre(marker)"></gmap-marker>
    </gmap-map>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {

    data() {
        return {
            bounds: null,
            centre: { lat: 0, lng: 0 }
        }
    },


    props: {
        markers: {
            type: Array
        }
    },

    computed: {
        google: gmapApi
    },

    mounted() {
        this.autoCentreMap();
    },

    methods: {
        autoCentreMap() {

            this.$refs.searchMap.$mapPromise.then((map) => {
                this.bounds = new google.maps.LatLngBounds();
                this.markers.forEach(marker => {
                    this.bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
                });
                map.fitBounds(this.bounds);
                map.panToBounds(this.bounds);
            });
        },

        setCentre(marker) {
            this.centre = { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) };
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
