var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.overlay.visibility
    ? _c("div", { staticClass: "c-overlay fixed inset-0" }, [
        _c(
          "div",
          {
            staticClass: "c-overlay__modal",
            class: _vm.overlay.modal.modifierClass
          },
          [
            _c(_vm.overlay.modal.content, {
              tag: "component",
              staticClass: "pt-10 pt-4 p-4"
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "c-overlay__close",
                attrs: { role: "button", "aria-label": "Close popup" },
                on: {
                  click: function($event) {
                    _vm.mutateOverlayModalModifierClass(null) &&
                      _vm.mutateOverlayVisibility(false)
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "relative", staticStyle: { top: "-2px" } },
                  [_vm._v("×")]
                )
              ]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }