var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c("section", { staticClass: "c-pre-nav" }, [
        _c(
          "div",
          { staticClass: "hidden md:flex items-center" },
          [_vm._t("pre_nav_left")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full md:w-auto flex flex-col md:inline-block" },
          [_vm._t("pre_nav_right")],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "hidden lg:flex justify-between flex-col lg:flex-row md:items-center py-4 px-8 relative z-10"
        },
        [
          _vm._t("logo"),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass:
                "c-primary-nav hidden lg:block flex-grow-0 flex-shrink-0"
            },
            [_vm._t("main_nav")],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "header-cta" }, [_vm._t("header_cta")], 2)
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative w-full lg:hidden flex items-center justify-between md:py-2 md:px-8",
          staticStyle: { "z-index": "60" }
        },
        [
          _c("img", {
            staticClass: "mobile-header-logo",
            attrs: {
              src: "/images/branding/logo.svg",
              alt: "Eleventh Hour Beauty"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex items-center menu-triggers",
              class: { "menu-triggers--menu-open": _vm.menu.visible }
            },
            [
              _vm._t("tablet_header_cta"),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "flex items-center mr-4 md:hidden",
                  class: {
                    active: _vm.menu.visible && _vm.menu.type == "account"
                  },
                  attrs: { role: "button" },
                  on: {
                    click: function($event) {
                      return _vm.handleMenuToggle("account")
                    }
                  }
                },
                [
                  !_vm.menu.visible ||
                  (_vm.menu.visible && _vm.menu.type != "account")
                    ? _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 17.5 20.25",
                            width: "21",
                            height: "24"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: { id: "Layer_2", "data-name": "Layer 2" }
                            },
                            [
                              _c("g", { attrs: { id: "Labels" } }, [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M16.31,20.25H1.19A1.19,1.19,0,0,1,0,19.06V17.27a4.91,4.91,0,0,1,4.9-4.9,4,4,0,0,1,1.19.25,7.87,7.87,0,0,0,2.66.44,7.8,7.8,0,0,0,2.65-.44,4.15,4.15,0,0,1,1.2-.25,4.91,4.91,0,0,1,4.9,4.9v1.79A1.19,1.19,0,0,1,16.31,20.25ZM4.9,13.37A3.91,3.91,0,0,0,1,17.27v1.79a.2.2,0,0,0,.19.19H16.31a.2.2,0,0,0,.19-.19V17.27a3.91,3.91,0,0,0-3.9-3.9,3.15,3.15,0,0,0-.9.21,9.34,9.34,0,0,1-5.91,0A3,3,0,0,0,4.9,13.37ZM8.75,9.25a4.63,4.63,0,1,1,4.62-4.63A4.64,4.64,0,0,1,8.75,9.25ZM8.75,1a3.63,3.63,0,1,0,3.62,3.62A3.63,3.63,0,0,0,8.75,1Z"
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.menu.visible && _vm.menu.type == "account"
                    ? _c("span", { staticClass: "c-close-icon" })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "relative inline-block",
                  staticStyle: { width: "30px", height: "25px" },
                  attrs: { role: "button" },
                  on: {
                    click: function($event) {
                      return _vm.handleMenuToggle("main")
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "c-burger-icon",
                    class: {
                      active: _vm.menu.visible && _vm.menu.type == "main"
                    }
                  })
                ]
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-in", mode: "out-in" } }, [
        _vm.menu.visible
          ? _c(
              "div",
              { staticClass: "c-mobile-menu" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade-slide", mode: "out-in" } },
                  [
                    _vm.menu.type == "main"
                      ? _c("div", [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "c-mobile-menu__primary-links" },
                              [_vm._t("main_nav")],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-mobile-menu__secondary-links" },
                              [_vm._t("main_extra_links")],
                              2
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade-slide", mode: "out-in" } },
                  [
                    _vm.menu.type == "account"
                      ? _c("div", [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "c-mobile-menu__primary-links" },
                              [_vm._t("pre_nav_right")],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-mobile-menu__secondary-links" },
                              [_vm._t("main_extra_links")],
                              2
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._t("header_cta")
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }