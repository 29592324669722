var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.headerSize == "h2"
      ? _c("h2", { staticClass: "mb-4" }, [_vm._v("Stay in touch")])
      : _vm._e(),
    _vm._v(" "),
    _vm.headerSize == "h3"
      ? _c("h3", { staticClass: "mb-4" }, [_vm._v("Stay in touch")])
      : _vm._e(),
    _vm._v(" "),
    !_vm.signed_up
      ? _c(
          "form",
          {
            staticClass: "flex flex-wrap",
            attrs: { action: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.newletterSignup($event)
              }
            }
          },
          [
            _c("div", { staticClass: "signup-col w-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.details.name,
                    expression: "details.name"
                  }
                ],
                staticClass: "c-form__input w-full mb-2",
                attrs: { type: "text", placeholder: "Name", required: "" },
                domProps: { value: _vm.details.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.details, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full flex signup-col" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.details.email,
                    expression: "details.email"
                  }
                ],
                staticClass: "c-form__input flex-grow",
                staticStyle: { "margin-bottom": "0" },
                attrs: {
                  type: "email",
                  placeholder: "Email address",
                  required: ""
                },
                domProps: { value: _vm.details.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.details, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]
        )
      : _c("p", [
          _vm._v(
            "Thank you. We'll keep you updated. Keep an eye on your inbox."
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "flex items-center justify-center flex-shrink-0 flex-grow-0 rounded-r bg-grey-1000 text-white p-3",
        attrs: { "aria-label": "Submit" }
      },
      [_c("i", { staticClass: "far fa-check" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }