var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ]
    },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Reset Password")]),
      _vm._v(" "),
      _vm.success
        ? _c("p", { staticClass: "px-4" }, [
            _vm._v(
              "If your email is registered on our system, you should receive a link to reset your password within the next 5 minutes."
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.success
        ? _c(
            "form",
            {
              staticClass: "mb-4 px-4",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.doReset($event)
                }
              }
            },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v("Please enter your email address below.")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "c-form__input w-full",
                attrs: {
                  type: "email",
                  name: "email",
                  placeholder: "Email Address"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn--teal text-grey-1000 w-full" },
                [_vm._v("Reset Password")]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }