<template>
    <div class="relative">
        <div class="c-filter-option c-form__auto-complete"
            :class="!focused ? ' c-filter-option--active' : null"
        >
            <vue-simple-suggest
                :placeholder="label"
                :list="autocomplete.list"
                mode="select"
                display-attribute="name"
                value-attribute="id"
                :filter-by-query="true"
                :filter="customFilter"
                v-model="the_model"
                :styles="autocomplete.styles"
                ref="suggest"
                @focus="focused=true"
                @blur="pickTopSuggestion"
            />
            <span v-if="!focused && !disableClear"
                @click="clearSelected"
                class="c-filter-option__clear"
                role="button"
                aria-label="Remove this filter option"
            >
                <i class="fal fa-times hidden md:inline-block"></i>
                <span class="inline-block md:hidden uppercase text-sm font-semibold">Edit</span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest';
import Fuse from 'fuse.js';
export default {
    components: {
        VueSimpleSuggest
    },

    data() {
        return {
            interacting: false,
            autocomplete: {
                styles: {
                    defaultInput: "c-form__input w-full"
                },
                list: []
            },
            focused:  false,
        }
    },

    props: {
        value: {
            type: Number,
        },
        endpoint: {
            type: String,
            required: true
        },

        selected: {
            type: Number,
            required: false
        },

        label: {
            type: String
        },

        toSetProp: {
            type: String,
            required: false,
            default: null
        },

        defaultModel: {
            default: null
        },

        disableClear: {
            type: Boolean,
            default: false
        },

        endpoint_params: {
            type: Object,
            default: () => ({})
        },
    },

    computed: {
        the_model: {
            get() {
                return this.value && this.autocomplete.list.length ? this.autocomplete.list.find(item => item.id == this.value) : this.defaultModel;
            },

            set(toSet) {
                if (toSet) {
                    this.$emit('input', toSet.id);
                }
            }
        },
    },

    methods: {
        pickTopSuggestion() {
            this.focused=false;
            let top = this.$refs.suggest.$data.suggestions[0];
            if (top) {
                this.the_model = top;
                this.$refs.suggest.setText(top.name);
            }
        },
        clearSelected() {
            this.$emit('input', null);
            this.$refs.suggest.setText(null);
        },

        fetchEndpoint() {
            let params = "";
            let delimiter = "?";
            Object.keys(this.endpoint_params).forEach(key => {
                let value = this.endpoint_params[key];;
                if (value) {
                    params= `${delimiter}${key}=${value}`
                    delimiter = '&';
                }
            })
            axios.get(`${this.endpoint}${params}`).then(res => {
                this.autocomplete.list = res.data.data;
            });
        },

        customFilter(list, query) {
            let options = {
                    shouldSort: true,
                    threshold: 0.4,
                    location: 0,
                    distance: 90,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name",
                    ]
                };

            let fuse = new Fuse([list], options);
            return fuse.search(query).length ? true : false;
        }
    },

    mounted() {
        this.fetchEndpoint();
    },

    watch: {
        endpoint_params() {
            this.fetchEndpoint();
            this.$refs.suggest.setText('');

        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .c-form__auto-complete {
        @apply mb-0;
    }
    ::v-deep .c-filter-option--active input {
        @apply bg-grey-400 text-white;

        @screen md {
            @apply bg-teal-400 text-grey-1000;
        }
    }
    ::v-deep input {
        @apply py-1 px-2 bg-white;
    }

    ::v-deep .suggestions {
        min-width: 175px;
        max-width: 310px;
    }
</style>
