<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)" class="c-popup">
        <div>
            <h2 class="c-popup__title">{{ params.title }}</h2>
            <p v-html="params.body"></p>
        </div>

    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
    data() {
        return {
            currentStep: 0
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            params: state => state.component_params.genericModal,
        })
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
    }
}
</script>
