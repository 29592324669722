var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ],
      staticStyle: { padding: "1rem" }
    },
    [
      _c("div", { staticClass: "c-iframe-video__wrapper" }, [
        _c("iframe", {
          staticClass: "c-iframe-video__video",
          attrs: {
            src: _vm.params.iframeSrc,
            width: "100%",
            frameborder: "0",
            allow: "autoplay; fullscreen",
            allowfullscreen: ""
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }