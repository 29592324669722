var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.suspendedPopup.visibility
    ? _c("div", { staticClass: "c-overlay fixed inset-0" }, [
        _c("div", { staticClass: "c-overlay__modal" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "c-overlay__close",
              attrs: { role: "button", "aria-label": "Close popup" },
              on: {
                click: function($event) {
                  return _vm.mutateSuspendedPopupVisibility(false)
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "relative", staticStyle: { top: "-2px" } },
                [_vm._v("×")]
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center", staticStyle: { padding: "40px 32px" } },
      [
        _c("h1", [_vm._v("Business Suspended")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Your business has been suspended, please contact\n        "
          ),
          _c(
            "a",
            {
              staticClass: "underline hover:no-underline",
              attrs: { href: "mailto:hello@eleventhhourbeauty.com" }
            },
            [_vm._v("hello@eleventhhourbeauty.com")]
          ),
          _vm._v("\n        for assistance.\n      ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }