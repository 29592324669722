<template>
    <div class="flex flex-col col-reverse">
        <textarea v-model="current_value" ref="textarea" class="textarea--with-wordcount" :class="prop_class || 'c-input c-input--textarea'" :name="name" :maxlength="max_length"></textarea>
        <span class="block self-end mt-2" :class="{'text-red-400' : current_length == 2000}">
            {{ current_length }} <template v-if="max_length">/ {{max_length}}</template>
        </span>
    </div>
</template>
<script>
export default {
    props: ['value', 'name', 'max_length', 'minLength', 'prop_class'],
    data() {
        return {
            current_value: this.value ? this.value : this.getDefaultValue(),
        }
    },
    computed: {
        current_length() {
            return this.current_value.length || 0;
        }
    },
    methods: {
        getDefaultValue() {
            if (this.$slots.default && this.$slots.default.length) {
               return this.$slots.default[0].text
            } return '';
        }
    },
    watch: {
        current_value(value) {
            this.$emit('input', value);
        }
    }
}
</script>
<style lang="scss" scoped>
.textarea--with-wordcount {
    margin-bottom: 0px !important;
}
</style>