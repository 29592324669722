var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "c-filter-option c-form__auto-complete",
        class: {
          "c-filter-option--active": _vm.location_query == _vm.value.name
        }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.location_query,
              expression: "location_query"
            }
          ],
          ref: "field",
          staticClass: "c-form__input w-full",
          attrs: { type: "text" },
          domProps: { value: _vm.location_query },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.geoSearch($event)
            },
            blur: _vm.researchIfRequired,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.location_query = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.location_query.length
          ? _c(
              "span",
              {
                staticClass: "c-filter-option__clear",
                attrs: {
                  role: "button",
                  "aria-label": "Remove this filter option"
                },
                on: { click: _vm.clearSelected }
              },
              [
                _c("i", { staticClass: "fal fa-times hidden md:inline-block" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "inline-block md:hidden uppercase text-sm font-semibold"
                  },
                  [_vm._v("Edit")]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }