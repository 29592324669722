var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _c(
      "thead",
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.permissions, function(permission, index) {
          return _c("tr", { key: index, staticClass: "border-b" }, [
            _c("td", { staticClass: "border-r" }, [
              _vm._v(_vm._s(permission.friendly_name) + " "),
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "permission[" + permission.id + "][permission]",
                  value: "true"
                }
              })
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "border-r", attrs: { align: "center" } }, [
              _c("div", { staticClass: "c-simulated-checkbox" }, [
                _c("input", {
                  staticClass: "c-simulated-checkbox__input",
                  attrs: {
                    type: "checkbox",
                    name: "permission[" + permission.id + "][C]",
                    id: "permission[" + permission.id + "][C]"
                  },
                  domProps: { checked: _vm.hasPermission(permission.id, "C") }
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "c-simulated-checkbox__label",
                  attrs: { for: "permission[" + permission.id + "][C]" }
                })
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "border-r", attrs: { align: "center" } }, [
              _c("div", { staticClass: "c-simulated-checkbox" }, [
                _c("input", {
                  staticClass: "c-simulated-checkbox__input",
                  attrs: {
                    type: "checkbox",
                    name: "permission[" + permission.id + "][R]",
                    id: "permission[" + permission.id + "][R]"
                  },
                  domProps: { checked: _vm.hasPermission(permission.id, "R") }
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "c-simulated-checkbox__label",
                  attrs: { for: "permission[" + permission.id + "][R]" }
                })
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "border-r", attrs: { align: "center" } }, [
              _c("div", { staticClass: "c-simulated-checkbox" }, [
                _c("input", {
                  staticClass: "c-simulated-checkbox__input",
                  attrs: {
                    type: "checkbox",
                    name: "permission[" + permission.id + "][U]",
                    id: "permission[" + permission.id + "][U]"
                  },
                  domProps: { checked: _vm.hasPermission(permission.id, "U") }
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "c-simulated-checkbox__label",
                  attrs: { for: "permission[" + permission.id + "][U]" }
                })
              ])
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _c("div", { staticClass: "c-simulated-checkbox" }, [
                _c("input", {
                  staticClass: "c-simulated-checkbox__input",
                  attrs: {
                    type: "checkbox",
                    name: "permission[" + permission.id + "][D]",
                    id: "permission[" + permission.id + "][D]"
                  },
                  domProps: { checked: _vm.hasPermission(permission.id, "D") }
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "c-simulated-checkbox__label",
                  attrs: { for: "permission[" + permission.id + "][D]" }
                })
              ])
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Create")]),
      _vm._v(" "),
      _c("th", [_vm._v("Read")]),
      _vm._v(" "),
      _c("th", [_vm._v("Update")]),
      _vm._v(" "),
      _c("th", [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }