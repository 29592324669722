<template>
    <div class="relative flex items-center">
        <div class="c-filter-option">
            <div class="c-form__checkbox-as-toggle">
                <input type="hidden" v-model="is_checked" :name="name">
                <input type="checkbox" :id="label" v-model="is_checked" @change="$emit('updated')">
                <label :for="label">{{ label }}</label>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            is_checked: 'false'
        }
    },

    props: {
        checked: {
            type: Boolean
        },

        name: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

    },

    computed: {


    },

    mounted() {
        this.is_checked = this.checked ? true : false;
    }
}
</script>

<style lang="scss" scoped>
    .c-filter-option {
        @apply border-none;
    }
</style>
