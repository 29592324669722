var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "col flex flex-shrink-0 whitespace-normal w-full md:w-6/12 lg:w-4/12 xl:w-3/12 3xl:w-2/12 mt-6 c-salon-preview-card"
    },
    [
      _vm.availability
        ? _c(
            "div",
            { staticClass: "flex flex-col w-full shadow-lg-allsides" },
            [
              _vm.availability.more_times
                ? _c(
                    "span",
                    {
                      staticClass:
                        "uppercase text-black text-center text-sm font-semibold p-1 bg-teal-400"
                    },
                    [_vm._v("\n            More times available\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center flex-shrink-0 flex-grow p-4"
                },
                [
                  _c("div", { staticClass: "flex flex-col w-8/12" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.availability.day_word))
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-copper-400 font-poynter font-bold text-2xl -mt-1"
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.formatTime(
                                _vm.availability.availability_start
                              )
                            ) +
                            " - " +
                            _vm._s(
                              _vm.formatTime(_vm.availability.availability_end)
                            ) +
                            "\n                "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-4/12 md:pl-2 flex flex-col text-right" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "block md:inline-block font-semibold text-sm"
                        },
                        [_vm._v("From")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-grey-1000 font-poynter font-bold text-2xl -mt-1 "
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.availability.cost_from) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-0 flex-shrink" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex relative bg-center bg-no-repeat bg-cover c-image-overlay-gradient",
                    style: _vm.business_image_style
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full absolute bottom-0 left-0 flex items-center justify-between px-4 py-2 text-white c-image-overlay-gradient__inner"
                      },
                      [
                        _c("div", { staticClass: "w-9/12 pr:4" }, [
                          _c(
                            "p",
                            { staticClass: "font-semibold text-base mb-0" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "cursor-pointer md:underline hover:no-underline text-white",
                                  on: {
                                    click: function($event) {
                                      return _vm.initQuickLook(
                                        _vm.availability.business.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.availability.business.name) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "hidden md:inline-block font-normal text-base text-white "
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.availability.business.address_line_3
                                  ) +
                                  " (" +
                                  _vm._s(_vm.availability.business.distance) +
                                  " miles)\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isLoggedIn
                      ? _c(
                          "button",
                          {
                            staticClass: "favourite-btn",
                            class: _vm.availability.favourite
                              ? "favourite-btn--active"
                              : null,
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "toggle-favourite",
                                  _vm.availability.business.id
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa-heart",
                              class: _vm.availability.favourite ? "fas" : "fal"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.services_by_provider.length
                ? _c(
                    "div",
                    {
                      staticClass: "flex flex-col px-4 pb-1  text-base",
                      class: {
                        "services-gradient": _vm.services_by_provider.length > 3
                      }
                    },
                    [
                      _c("span", { staticClass: "italic text-sm pt-2 pb-1" }, [
                        _vm._v(
                          _vm._s(_vm.services_by_provider.length) +
                            " " +
                            _vm._s(
                              _vm.services_by_provider.length > 1
                                ? "Services"
                                : "Service"
                            ) +
                            " Available"
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.services_by_provider.slice(0, 3), function(
                        service
                      ) {
                        return _c(
                          "div",
                          {
                            key:
                              "availability-service-" +
                              _vm.availability.id +
                              "-" +
                              service.name,
                            staticClass: "flex flex-row py-1 border-bottom"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "service-name text-copper-400 font-bold mr-2"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(service.name) +
                                    "\n                    "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-duration mr-1 font-normal text-102"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.getDuration(service)) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            service.cost
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-cost font-bold text-black flex-1 text-right"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-normal text-102 " },
                                      [_vm._v("From")]
                                    ),
                                    _vm._v(
                                      " £" +
                                        _vm._s(service.cost / 100) +
                                        "\n                "
                                    )
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-cost font-bold text-black flex-1 text-right"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    FREE\n                "
                                    )
                                  ]
                                )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex uppercase font-bold text-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center flex-grow flex-shrink md:hidden bg-grey-400"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-white",
                          attrs: { role: "button" },
                          on: {
                            click: function($event) {
                              return _vm.initQuickLook(
                                _vm.availability.business.id
                              )
                            }
                          }
                        },
                        [_vm._v("\n                    Info\n                ")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow flex-shrink bg-teal-400 md:bg-grey-400 py-2 cursor-pointer md:hover:bg-green-500 text-grey-1000 md:text-white",
                      on: { click: _vm.bookClicked }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.availability.more_times
                              ? "See Times & Book"
                              : "Book Now"
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm.business
        ? _c(
            "div",
            { staticClass: "flex flex-col w-full shadow-lg-allsides" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "uppercase text-black text-center text-sm font-semibold p-1 bg-grey-400 text-white"
                },
                [_vm._v("\n            Recently Available\n        ")]
              ),
              _vm._v(" "),
              _vm.cheapest
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center flex-shrink-0 flex-grow-0 p-4",
                      class: { "availability-spacer": false }
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "w-4/12 md:pl-2 flex flex-col text-right"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "block md:inline-block font-semibold text-sm"
                            },
                            [_vm._v("From")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-grey-1000 font-poynter font-bold text-2xl -mt-1 "
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.toCurrency(_vm.cheapest / 100)) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-0 flex-shrink" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex relative bg-center bg-no-repeat bg-cover c-image-overlay-gradient",
                    style: _vm.business_image_style
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full absolute bottom-0 left-0 flex items-center justify-between px-4 py-2 text-white c-image-overlay-gradient__inner"
                      },
                      [
                        _c("div", { staticClass: "w-9/12 pr:4" }, [
                          _c(
                            "p",
                            { staticClass: "font-semibold text-base mb-0" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "cursor-pointer md:underline hover:no-underline text-white",
                                  on: {
                                    click: function($event) {
                                      return _vm.initQuickLook(_vm.business.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.business.name) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "hidden md:inline-block font-normal text-base text-white "
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.business.address_line_3) +
                                  " (" +
                                  _vm._s(_vm.business.distance) +
                                  " miles)\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isLoggedIn
                      ? _c(
                          "button",
                          {
                            staticClass: "favourite-btn",
                            class: _vm.business.favourite
                              ? "favourite-btn--active"
                              : null,
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "toggle-favourite",
                                  _vm.business.id
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa-heart",
                              class: _vm.business.favourite ? "fas" : "fal"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.business.services.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col flex-grow px-4 pb-1 text-base",
                      class: {
                        "services-gradient": _vm.business.services.length > 3
                      }
                    },
                    [
                      _c("span", { staticClass: "italic text-sm pt-2 pb-1" }, [
                        _vm._v("Services Offered")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.business.services.slice(0, 3), function(
                        service
                      ) {
                        return _c(
                          "div",
                          {
                            key:
                              "availability-business-service-" +
                              _vm.business.id +
                              "-" +
                              service.name,
                            staticClass: "flex flex-row py-1 border-bottom"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "service-name text-copper-400 font-bold mr-2"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(service.name) +
                                    "\n                    "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-duration mr-1 font-normal text-102"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.getDuration(service)) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            service.cheapest
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-cost font-bold text-black flex-1 text-right"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-normal text-102 " },
                                      [_vm._v("From")]
                                    ),
                                    _vm._v(
                                      " £" +
                                        _vm._s(service.cheapest / 100) +
                                        "\n                "
                                    )
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "service-cost font-bold text-black flex-1 text-right"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.toCurrency(service.cost)) +
                                        "\n                "
                                    )
                                  ]
                                )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex uppercase font-bold text-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow flex-shrink bg-teal-400 md:bg-grey-400 py-2 cursor-pointer md:hover:bg-green-500 text-grey-1000 md:text-white",
                      on: {
                        click: function($event) {
                          return _vm.initQuickLook(_vm.business.id)
                        }
                      }
                    },
                    [_vm._v("\n                INFO\n            ")]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-col w-8/12" }, [
      _c("span", { staticClass: "label" }),
      _vm._v(" "),
      _c("span", {
        staticClass: "text-copper-400 font-poynter font-bold text-2xl -mt-1"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }