<template>
    <div class="wrapper md:w-auto px-8 mx-0 md:mx-auto pt-6">
        
        <div class="flex flex-col-reverse sm:flex-col md:flex-row md:justify-between flex-wrap md:items-start">
            <h2 v-if="selected_view_mode === 'all'" class="mb-0 mt-1 hidden sm:block">
                All Availability
            </h2>
             <div v-else-if="selected_provider"
                class="flex flex-row provider w-full lg:w-1/2 mb-12"
            >
                <div class="bg-grey-300 rounded-full overflow-hidden hidden sm:block mr-6 image-wrapper">
                    <img :src="selected_provider.profile_image" rrsrc="https://via.placeholder.com/150" class="rounded-full" />
                </div>
                <div class="flex flex-col justify-center mt-6 sm:mt-0">
                    <span v-if="isProviderAvailable(selected_provider)" class="text-copper-400 font-semibold">I'M AVAILABLE</span>
                    <span v-else class="text-grey-350 font-semibold">NO AVAILABILITY</span>

                    <h2 class="mb-0">{{selected_provider.full_name}}</h2>
                    <div class="categories flex flex-row flex-wrap">
                        <span v-for="category in provider_categories" :key="`category-${category.id}`" @click="selectCategory(category)" 
                            class="category-badge mr-2 mb-2 bg-grey-400 hover:bg-grey-600 font-medium text-sm text-white px-2 py-1 cursor-pointer"
                            :class="{'selected': !selected_industry_id || selected_industry_id === category.id}"
                        >
                            {{category.name}}
                        </span>
                    </div>
                </div>
            </div>
            <h2 v-else class="mt-1 hidden sm:block">
                Select By Professional
            </h2>
            <div class="h-full sm:ml-auto" v-if="!preselected_provider">
                <select v-model="view_mode" class="c-form__select mb-4 w-full sm:w-auto">
                    <option value="all" >Showing All Availability</option>
                    <option value="by_professional" default>Showing Availability by Professional</option>
                </select>
            </div>
        </div>
        
        <div v-if="selected_view_mode==='all' || selected_provider" class="row -mt-12">
            <div v-for="(availability) in category_availabilities" :key="`availability-info-${availability.id}`" class="col w-full lg:w-6/12 mt-12">
                <div>
                    <p class="label mb-0">{{ availability.day_word }}</p>
                    <p class="text-lg font-poynter font-bold text-copper-400 mb-0">{{ getFormattedTime(availability.availability_start) }} - {{ getFormattedTime(availability.availability_end) }}</p>
                    <p v-if="!selected_provider_id" class="font-poynter font-bold text-base mb-6">With <a class="underline hover:no-underline" :href="getUrl(availability)">{{ availability.user.full_name }}</a></p>

                    <table class="c-stylised-table w-full">
                        <tr v-for="service in availability.services.slice(0, show_more[`availability-service-${availability.id}-${selected_provider_id}`] ? availability.services.length : 5)" :key="`availability-service-${availability.id}-${service.id}`">
                            <td>
                                <strong class="mr-2">{{ service.name }}</strong>
                                <span>{{ service.time_required_for_humans }}</span>
                            </td>
                            <td class="text-right">
                                {{service.pivot.cost ? toCurrency(service.pivot.cost/100) : 'Free'}}
                                <!-- @if($service->pivot->cost)
                                    @money($service->pivot->cost)
                                @else
                                    Free
                                @endif -->

                            </td>
                            <td class="text-right"><a @click="onClick(availability, service)" class="underline cursor-pointer">Book</a></td>
                        </tr>
                    </table>
                    <a v-if="availability.services.length > 5" @click="toggleShowMore(availability)" class="pl-4 text-grey-800 text-base font-normal underline hover:no-underline mt-2 cursor-pointer"> {{show_more[`availability-service-${availability.id}-${selected_provider_id}`] ? 'Show Less' : 'Show More'}}</a>
                </div>
            </div>
        
            <div v-if="!category_availabilities.length" class="col w-full text-center">
                <img src="/images/headshots/no-results.png" aria-hidden="true" class="no-results-img mx-auto">
                <h2 class="mb-2">Oh no!</h2>
                <p class="mb-0 text-black text-xl">
                    It looks like {{selected_provider ? `${selected_provider.full_name} is` : 'our businesses are'}} busy at these times.<br>
                </p>
            </div>
        </div>

        <!-- SELECT PROVIDER -->
        <div v-if="selected_view_mode === 'by_professional' && !selected_provider" class="flex flex-col items-center mx-auto justify-start md:items-start content-start md:justify-start md:flex-row flex-wrap">
            <div v-for="provider in providers" :key="`provider-${provider.id}`"
                class="flex flex-col items-center mr-auto sm:flex-row provider in-list mt-12 w-full xl:w-1/3 md:w-1/2"
            >
                <div class="bg-grey-300 rounded-full overflow-hidden mr-0 sm:mr-6 image-wrapper">
                    <img :src="provider.profile_image" rrsrc="https://via.placeholder.com/150" class="rounded-full" />
                </div>
                <div class="flex flex-col justify-center text-center sm:text-left ">
                    <span v-if="isProviderAvailable(provider)" class="text-copper-400 font-semibold">I'M AVAILABLE</span>
                    <span v-else class="text-grey-350 font-semibold">NO AVAILABILITY</span>

                    <span class="text-black font-bold text-xl font-poynter">{{provider.full_name}}</span>
                    <span class="text-grey-800 font-semibold">{{provider.services_count}} services</span>
                    <a class="text-grey-800 font-normal underline hover:no-underline mt-2 cursor-pointer" :hreeef="getUrl({user: provider})"
                        @click="selectProvider(provider)"
                    >
                        Click to see more
                    </a>
                </div>
            </div>
        </div>
        <div ref="more_btn" class="w-full mt-6 mb-6 flex-row flex justify-center">
            <div v-if="(view_mode === 'all' || selected_provider) && can_load_more" 
                @click="getAvailability(current_page +1)"
                class="px-8 mx-auto py-2 bg-teal-400 hover:bg-teal-600 cursor-pointer rounded-md text-black uppercase font-bold inline-block whitespace-nowrap"
            >
                Show More Availability
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapMutations } from 'vuex';
import {cloneDeep} from 'lodash';
import { LIVE_SEARCH_SERVICES, LIVE_SEARCH_INDUSTRIES, LIVE_SEARCH_CITIES, LIVE_SEARCH_BUSINESS, LIVE_SEARCH_GEOLOCATION } from '@js/endpoints';
// const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);
export default {
    props: ['all_availabilities', 'business', 'providers', 'preselected_provider', 'preselected_viewmode'],
    data() {
        return {
            view_mode: 'by_professional',
            provider_id: null,
            industry_id: null,
            categories: [],
            show_more: {},
            new_availabilities: [],
            can_load_more: false,
            current_page: null
        }
    },
    mounted(){ 
        axios.get(`${LIVE_SEARCH_INDUSTRIES}`).then(res => {
            this.categories = res.data.data;
        });
        let url = `api/v1/availability?business_id=${this.business.id}`;
        // if (this.)
    },
    computed: {
        selected_provider_id() {
            return this.preselected_provider || this.provider_id
        },
        selected_industry_id() {
            return this.industry_id
        },

        selected_view_mode() {
            return this.preselected_viewmode || this.view_mode;
        },
        provider_availabilities() {
            let availabilities = this.new_availabilities;
            if (this.selected_provider_id) {
               availabilities = availabilities.filter(avail => avail.user.id === this.selected_provider_id);
            }
            return availabilities;
        },

        category_availabilities() {
            if (!this.selected_industry_id)  return this.provider_availabilities;
            let availabilities = cloneDeep(this.provider_availabilities);
            console.log(availabilities);
            if (this.selected_industry_id) {
                availabilities = availabilities.filter(avail => avail.services.find(service => service.industry_id === this.selected_industry_id))
                    .map(avail => {
                        avail.services = avail.services.filter(service => service.industry_id === this.selected_industry_id);
                        return avail;
                    });
            }

            return availabilities;
        },

        selected_provider() {
            return this.providers.find(provider => provider.id === this.selected_provider_id)
        },

        provider_categories() {
            if (!this.provider_availabilities) return [];
            return this.categories.filter(category => {
                return this.provider_availabilities.find(avail => {
                    return avail.services.find(service => service.industry_id === category.id) ? true : false;
                }) ? true : false;
            })
        }
    },
    methods: {
        ...mapActions([
            'initBooking'
        ]),
        ...mapMutations([
            'mutateSearchFilterFieldsServiceId'
        ]),
        getUrl(item) {
            let slug = this.business.slug;
            let provider = item.user.id;
            return `/business/${slug}/seat-${provider}/availability`;
        },

        toggleShowMore(availability) {
            let value = this.show_more[`availability-service-${availability.id}-${this.selected_provider_id}`];
            this.$set(this.show_more, `availability-service-${availability.id}-${this.selected_provider_id}`, !value);
        },

        getFormattedTime(time) {
            return moment.utc(time).format("h:mmA");
        },

        isProviderAvailable(provider) {
            return this.new_availabilities.filter(availability => availability.user.id === provider.id).length;
            // return provider.id % 2 === 0 ? true : false;
        },

        selectProvider(provider) {
            window.location.href = `/business/${this.business.slug}/seat-${provider.id}/availability`
            // this.provider_id = provider.id;
            // this.industry_id = null;
        },


        // EVENTS
        onClick(availability, service) {
            this.mutateSearchFilterFieldsServiceId(service.id); 
            this.initBooking({availability_id: availability.id})
        },

        selectCategory(category) {
            if (this.industry_id === category.id) this.industry_id = null;
            else this.industry_id = category.id;
        },

        getAvailability(page = 1) {
            let url =  '';
            if (this.selected_provider_id) {
                url = `/api/v1/availability?page=${page}&business_id=${this.business.id}&provider_id=${this.selected_provider_id}`;

            } else {
                url = `/api/v1/availability?page=${page}&business_id=${this.business.id}`;
            }
            axios.get(url).then(response => {
                if (page > 1) {
                    this.new_availabilities = [...this.new_availabilities, ...response.data.data];
                    this.$nextTick(() => {
                        this.$refs.more_btn.scrollIntoView();
                    })
                }
                else {
                    this.new_availabilities = response.data.data;
                }
                this.current_page = response.data.meta.current_page;
                this.can_load_more = response.data.meta.last_page > this.current_page;
            });
        }
    },

    watch: {
        selected_view_mode(new_value) {
            if (new_value === 'all') {
                this.provider_id = null;
                this.industry_id = null;
            }
        },

        selected_provider_id: {
            handler: function (new_id) {
                this.getAvailability();
            },
            immediate: true,


        }
    }
}
</script>
<style lang="scss" scoped>

.provider > .image-wrapper, .provider > .image-wrapper > img  {
    width: 150px;
    min-width: 150px;
    height: 150px;
}

.provider.in-list {
    max-width: 400px;
}

.category-badge {
    height: 30px;
    border-radius: 15px;

    &.selected {
        @apply bg-teal-400 text-black;

        &:hover {
            @apply bg-teal-600;
        }
    }
}

select {
    width: 100%;
    // overflow: hidden;
    // white-space: pre;
    text-overflow: ellipsis;
}

.wrapper {
    @screen sm {
        max-width: 640px;
    }
    @screen md {
        max-width: 788px;
    }
    @screen lg {
        max-width: 1024px;
    }
    @screen xl {
        max-width: 1280px;
    }
    @screen 2xl {
        max-width: 1536px;
    }
}

</style>